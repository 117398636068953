export interface AssetUnitResponse {
  id: string;
  name: string;
  code: string;
  scRatio: number;
}

export interface AddAssetUpdateRequestRequest {
  assetId: number;
  enabled: 0 | 1;
}

export interface ReservedAssetResponse {
  batches: ReservedAsset[];
  reservedQty: number;
}

export type ReservedAsset = {
  id: number;
  sourceAction: string;
  sourceId: number;
  assetId: number;
  batchId: number;
  quantity: number;
};

export enum AssetDepositProvider {
  XConnect = 'xconnect',
  B3 = 'b3',
}
