import { Text, TextColor, TypographyVariant, styled } from '@aircarbon/ui';

export const SDGGoals: React.FC<{
  goals: Array<{
    label: string;
    value: string;
  }>;
}> = ({ goals }) => {
  return (
    <StyledSDGGoals>
      <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
        SDG Goals:
      </Text>
      {goals.map((goal) => (
        <Goal>
          <img src={`/sdg_icons/E-WEB-Goal-${goal.value}.png`} alt="" />
        </Goal>
      ))}
    </StyledSDGGoals>
  );
};

const StyledSDGGoals = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Goal = styled.div`
  display: flex;
  overflow: hidden;
  width: 48px;
  heigh: 48px;
  border-radius: 6px;
`;
