import {
  Button,
  ButtonVariant,
  Icon,
  IconName,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toLayerBackground,
  useSpacing,
} from '@aircarbon/ui';

const StyledSuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
`;

const Check = styled.div`
  width: 87px;
  height: 87px;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  background-color: ${({ theme }) => toLayerBackground(theme)('layerInverted')};
`;

export const SuccessMessage: React.FC<{
  onPressViewProject(): void;
}> = ({ onPressViewProject }) => {
  const { spacing } = useSpacing();
  return (
    <StyledSuccessMessage>
      <Check>
        <Icon size="5.375rem" name={IconName.Check} color={TextColor.inverse} />
      </Check>
      <Text variant={TypographyVariant.h5Title} marginTop={spacing(16)}>
        Your Project is Submitted Successfully!
      </Text>
      <Text color={TextColor.secondary} marginTop={spacing(4)}>
        Our support team will get in touch with you as soon as possible
      </Text>
      <Button marginTop={spacing(16)} variant={ButtonVariant.secondary} onPress={onPressViewProject}>
        View Project
      </Button>
    </StyledSuccessMessage>
  );
};
