import { Text, styled, toLayerBackground } from '@aircarbon/ui';

export const MarketsTable: React.FC<{
  markets: Array<{ label: string; value: string }>;
}> = ({ markets }) => {
  return (
    <StyledMarketsTable>
      {markets.map((market) => (
        <Row label={market.label} value={market.value} />
      ))}
    </StyledMarketsTable>
  );
};

const Row: React.FC<{
  label: string;
  value: string;
}> = (props) => {
  return (
    <StyledRow>
      <Text>{props.label}</Text>
      <Text>{props.value}</Text>
    </StyledRow>
  );
};

const StyledMarketsTable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 0 0;
  background-color: ${({ theme }) => toLayerBackground(theme)('field')};
  min-height: 142px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 24px;
`;
