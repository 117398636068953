import { Stepper, StepperColor, StepperOrientation, StepperState } from '@aircarbon/ui';

export enum StepStatus {
  Current = 0,
  Completed = 1,
  Next = 2,
  Active = 3,
}

const stepSatusToStepperState: Record<StepStatus, StepperState> = {
  [StepStatus.Current]: StepperState.Current,
  [StepStatus.Completed]: StepperState.Completed,
  [StepStatus.Next]: StepperState.Current,
  [StepStatus.Active]: StepperState.Selected,
};

export const Step: React.FC<{
  status: StepStatus;
  children: string;
  hasEndLine: boolean;
  hasStartLine: boolean;
  onPress?(): void;
}> = (props) => {
  const { status = StepStatus.Current, onPress, hasEndLine, hasStartLine, children } = props;

  return (
    <div onClick={onPress}>
      <Stepper
        hasEndLine={hasEndLine}
        hasStartLine={hasStartLine}
        color={status !== StepStatus.Next ? StepperColor.Primary : StepperColor.Default}
        state={stepSatusToStepperState[status]}
        orientation={StepperOrientation.Horizontal}
        label={children}
      />
    </div>
  );
};
