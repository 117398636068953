import { userAuthenticatedApi } from 'data-mutation/mutator';

import { Dto } from '@aircarbon/utils-common';

export const rejectDepositRequest = async (props: { requestId: string; comment: string }) => {
  const { requestId, comment } = props;

  const endpoint = `/user/assets/deposits/${requestId}`;
  const userService = await userAuthenticatedApi();

  return userService
    .url(endpoint)
    .body(
      JSON.stringify({
        status: Dto.AssetDepositStatus.RejectedByUser,
        comment,
      }),
    )
    .headers({
      'Content-Type': 'application/json',
    })
    .put()
    .json();
};
