import { UploadFile } from 'refreshed-component/molecules/UploadFile';

import { Text, TextColor, TypographyVariant } from '@aircarbon/ui';

import { FieldWrapper } from 'components/styled/Styled';

import { useFileUpload } from '../../hooks/useFileUpload';

export interface UploadDocumentValue {
  name: string;
  size: number;
  url: string;
}

export const UploadDocument: React.FC<{
  label: string;
  value?: UploadDocumentValue | null;
  error?: string;
  onChange(value: UploadDocumentValue | null): void;
}> = (props) => {
  const { label, value, error, onChange } = props;
  const { file, isUploading, uploadProgress, upload, cancelUpload, remove } = useFileUpload({
    initialFile: value || null,
    onUploadFinished: (file) => {
      onChange(file);
    },
    onRemoveFinished: () => onChange(null),
  });

  return (
    <FieldWrapper>
      <Text variant={TypographyVariant.body2}>{label}</Text>
      {!isUploading && !!file ? (
        <UploadFile.FileCard
          name={file.name}
          description={`File size: ${new Intl.NumberFormat('en-US', {
            notation: 'compact',
            style: 'unit',
            unit: 'byte',
            unitDisplay: 'narrow',
          }).format(file.size)}`}
          onRemove={remove}
        />
      ) : isUploading && !!file ? (
        <UploadFile.UploadProgress
          fileName={file ? file.name : ''}
          percentage={uploadProgress}
          onPressCancel={cancelUpload}
        />
      ) : (
        <>
          <UploadFile
            error={error}
            onChange={upload}
            description={'Max. File Size: 30MB'}
            accepts={['application/pdf']}
          />
          {!!error && (
            <Text color={TextColor.error} variant={TypographyVariant.caption}>
              {error}
            </Text>
          )}
        </>
      )}
    </FieldWrapper>
  );
};
