import { TextColor, styled, toTextColor } from '@aircarbon/ui';

import type { ThemeColors } from '../Themes';
import { GREEN, RED } from '../theme';
import type { Theme } from '../types';

export const Wrapper = styled.div<{ position?: 'absolute' | 'realative' }>`
  position: ${(props) => `${props.position || 'absolute'}`};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #fff;
  gap: 10px;
  .spinner {
    width: 25px;
    height: 25px;
    top: 35%;
    left: 25%;
    &:after {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Span = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;

export const Line = styled.div<{ bgColor?: 'red' | 'green' }>`
  position: relative;
  width: calc(100%);
  background-color: ${(props) => (props.bgColor === 'red' ? RED.bg.default : GREEN.bg.default)};
  flex: 0 0 1px;
`;

export const Hint = styled.div<{ bgColor?: 'red' | 'green' }>`
  position: relative;
  color: #999999;
  text-align: right;
`;

export const Label = styled.div<{ color?: string }>`
  color: ${(props) => props.color || '#bbb'};
  display: contents;
  color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
`;

export const TipLabel = styled.span`
  display: contents;
  color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
`;
export const TipValue = styled.span`
  display: contents;
  color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
`;
export const NoMargin = styled.div`
  margin: -16px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SearchElem = styled.form<{ themeColors: ThemeColors }>`
  display: flex;
  position: relative;
  padding: 10px;

  input,
  button {
    padding: 15px 15px;
  }

  input {
    width: 75px;
  }

  .searchInput {
    background-color: ${({ themeColors }) => themeColors.dashboard.background};
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border: 1px solid ${({ themeColors }) => themeColors.dashboard.block.border};
    width: 100%;
    font-size: 14;
    padding: 10px;
    &:focus {
      outline: none !important;
      border-color: ${(props) => props.theme.brandColor};
    }
  }

  button:not(.submitButton) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-color: ${({ themeColors }) => themeColors.dashboard.deepBackground};
    border-right: 0;
    padding: 0;

    input {
      padding: 0 0 0 15px;
    }
  }

  .selectEventHolder {
    button {
      width: 300px;

      input {
        width: 100%;
        padding: 10px 15px;
        font-weight: normal;
      }
    }
  }

  .submitButton {
    height: auto;
    line-height: 1.75;
    margin: 0;
    border: 1px solid ${(props) => `rgba(${props.theme.brandRbgColor},0.8)`};
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 10px 2rem;
    display: inline-flex;
    align-items: center;
    background: ${(props) => `rgba(${props.theme.brandRbgColor},0.8)`};
    transition: all 0.4s;
    font-weight: 600;
    color: white;
    border-left: 0;

    &:hover {
      background: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    }
  }

  button {
    outline: none !important;
    box-shadow: none !important;
  }

  .connectionStatus {
    position: absolute;
  }
`;

export const Item = styled.div<{ themeColors: ThemeColors }>`
  width: 100%;
  background: ${({ themeColors }) => themeColors.dashboard.background};
  color: ${({ themeColors }) => themeColors.dashboard.color};
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 16px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid ${({ themeColors }) => themeColors.dashboard.block.lightBorder};
  font-size: 12px;
  text-align: left;
  &:hover {
    background: ${({ themeColors }) => themeColors.dashboard.block.background};
  }
  &.selected {
    background: ${({ themeColors }) => themeColors.dashboard.block.background};
    border: 1px solid ${({ themeColors }) => themeColors.dashboard.block.border};
    font-weight: bold;
  }
  > .icon {
    width: 32px;
    position: relative;
    path {
      stroke: ${({ themeColors }) => themeColors.dashboard.color};
    }
    > .read {
      width: 6px;
      height: 6px;
      position: absolute;
      background: #3fc68f;
      top: -5px;
      left: -5px;
      border-radius: 10px;
    }
  }
`;

export const Filters = styled.div<{ themeColors: ThemeColors }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 16px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 12px;
  height: 46px;
  > div {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 26px;
    color: ${({ themeColors }) => themeColors.dashboard.color};
    border-radius: 6px;
    &:hover {
      background: ${({ themeColors }) => themeColors.dashboard.background};
    }
    &.selected {
      background-color: #3fc68f;
      color: #fff;
      font-weight: bold;
    }
  }
`;

export const Holder = styled.div<{
  theme?: Theme;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

export const Content = styled.div<{ themeColors: ThemeColors }>`
  background: ${({ themeColors }) => themeColors.dashboard.block.background};
  color: ${({ themeColors }) => themeColors.dashboard.color};
  svg {
    stroke: ${({ themeColors }) => themeColors.dashboard.color};
  }
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .date {
    font-size: 12px;
  }
  .attachment {
    width: min-content;
    border: 1px solid ${({ themeColors }) => themeColors.dashboard.block.lightBorder};
    height: 34px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    background-color: ${({ themeColors }) => themeColors.dashboard.background};
    box-shadow: 0px 4px 9px 4px #00000008;

    &:hover {
      border: 1px solid ${({ themeColors }) => themeColors.dashboard.block.border};
      box-shadow: 0px 4px 9px 4px #00000009;
      background-color: ${({ themeColors }) => themeColors.dashboard.block.background};
    }
    > .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg > path {
        stroke: transparent;
        fill: ${({ themeColors }) => themeColors.dashboard.color};
      }
    }
    > .name {
      white-space: pre;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;
