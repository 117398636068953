import queryString from 'query-string';

import type { AssetCategoryCode } from '@aircarbon/utils-common';
import type { Asset, Pair } from '@aircarbon/utils-common/src/dto';

import type { TimeInForce } from 'pages/account/trading/hooks';

import { userAuthenticatedApi } from '../fetcher';

export type OrderBy = 'id ASC' | 'id DESC' | 'uiOrder ASC' | 'uiOrder DESC';

export type TradeSettingParams = {
  filterBy?: Record<string, unknown>;
  projectId?: string;
  orderBy: {
    timeInForceOrderBy: OrderBy;
  };
};

interface TradeSettingsResponse {
  pairs: Pair[];
  timeInForces: TimeInForce[];
  ccyAssets: Record<string, Asset>;
}

export const fetchTradeSettings = async (params: TradeSettingParams, assetCategory: AssetCategoryCode = 'token') => {
  const { orderBy, projectId } = params;
  const endpoint = '/oms/trade/settings';
  const userService = await userAuthenticatedApi();

  if (assetCategory === 'fct' && !projectId) {
    throw new Error('projectId is required for fetching FCT trade settings');
  }

  const qs = queryString.stringify(
    { assetCategory, timeInForceOrderBy: orderBy.timeInForceOrderBy, projectId },
    {
      arrayFormat: 'bracket',
      skipEmptyString: true,
      skipNull: true,
    },
  );

  const res = userService.url(endpoint + (qs ? `?${qs}` : '')).get();
  return res.json<TradeSettingsResponse>();
};
