import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Badge, Button, ButtonVariant, IconName } from '@aircarbon/ui';

export const Batch: React.FC<{
  label: string;
  assets: Array<{
    label: string;
    value: string;
  }>;
  quantity: string;
  onRetire?(): void;
}> = (props) => {
  const { label, assets, quantity, onRetire } = props;
  return (
    <StyledBatch>
      <Assets>
        <Asset>
          <Badge value={label} />
        </Asset>
        {assets.map((asset, key) => {
          return (
            <Asset key={key}>
              <Text color={Colors.gray_500} size={FontSize.small}>
                {asset.label}
              </Text>
              <Text size={FontSize.large} weight={FontWeight.semibold}>
                {asset.value}
              </Text>
            </Asset>
          );
        })}
      </Assets>
      <Quantity>
        <Text color={Colors.gray_500}>Quantity (tCO2e)</Text>
        <Text size={FontSize._2Xl} weight={FontWeight.semibold}>
          {quantity}
        </Text>
      </Quantity>
      {!!onRetire && (
        <Actions>
          <Button variant={ButtonVariant.secondary} endIcon={IconName.Retire} onPress={onRetire}>
            Retire
          </Button>
        </Actions>
      )}
    </StyledBatch>
  );
};

const StyledBatch = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid var(${Colors.gray_200});
  background: var(${Colors.gray_50});

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const Assets = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 24px;
  gap: 16px;

  @media (max-width: 1367px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1023px) {
    display: flex;
  }

  @media (max-width: 719px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Asset = styled.div`
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 33.333%;
`;

const Quantity = styled.div`
  border-inline-start: 1px solid var(${Colors.gray_200});
  border-inline-end: 1px solid var(${Colors.gray_200});
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1023px) {
    border-inline-start: none;
    border-inline-end: none;
    border-top: 1px solid var(${Colors.gray_200});
    border-bottom: 1px solid var(${Colors.gray_200});
  }
`;

const Actions = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
`;
