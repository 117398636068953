import type SystemType from '../systemType';
import type { Entity } from './entity';
import type { UserOpenCurrencyBalancesAsset } from './pair';

export interface UserTcAccepted {
  id: number;
  acceptedFilename: string;
  acceptedUtc: Date;
  userId: number;
}

export interface UserBalanceResponse {
  balances: {
    userId: number;
    amount: number;
    tokens: Token[];
  }[];
  openRFQRequests: OpenRFQRequest[];
  openOrders: OpenOrder[];
  pendingTransactionAmount?: {
    user_id: number;
    amount: number;
  }[];
  pendingTransactionTokens?: {
    user_id: number;
    token: number;
    symbol: string;
  }[];
}

export interface BalanceResponse {
  balance: number;
  availableAmount: number;
  openRFQAmount: number;
  openOrderAmount: number;
  accountAddress: string;
  openRFQRequests: OpenRFQRequest[];
  openOrders: OpenOrder[];
  tokens: Token[];
  reservedTokens: Token[];
  pendingTransactionTokens?: pendingTransactionToken[];
  pendingTransactionAmount: number;
  reservedBalance: number;
}

export interface pendingTransactionToken {
  symbol: string;
  token: number;
}

export interface OpenOrder {
  order_id: string;
  user_id: number;
  date: string;
  side: string;
  sideId: number;
  symbol: string;
  price: string;
  quantity: string;
  state: string;
  fill_quantity: string;
  fill_price: string;
  error: null;
  update_time: string;
  tradeFee: string;
  tokenTypeId: number;
}

export interface OpenRFQRequest {
  id: number;
  status: string;
  quantity: number;
  price: number;
  type: string;
  fillType: string;
  filled: number;
  createdAt: string;
  updatedAt: string;
  statusMessage: null;
  description: null;
  tokenTypeId: number;
  ccyTypeId: number;
  userId: number;
  fill_quantity: number;
}

export interface Token {
  exists: boolean;
  stId: HexValue;
  tokTypeId: HexValue;
  tokTypeName: string;
  batchId: HexValue;
  mintedQty: HexValue;
  currentQty: HexValue;
  ft_price: HexValue;
  ft_ledgerOwner: string;
  ft_lastMarkPrice: HexValue;
  ft_PL: HexValue;
}

export interface HexValue {
  _hex: string;
}

export interface FiatHistoryResponse {
  total: number;
  totalPage: number;
  items: {
    id: number;
    ccy_type_id: string;
    amount: number;
    status_code: string;
    created_utc: string;
    amount_received?: number;
    amount_sent?: number;
  }[];
}

export interface UserInbox {
  id: number;
  action?: string;
  userId: number;
  description?: string;
  emailTrackingId?: number;
  category: string;
  subject?: string;
  isRead: number;
  createdAt?: string;
}

export interface UserOpenCurrencyBalance {
  openOrderAmount: number;
  openRFQAmount: number;
  pendingTransactionAmount: number;
}

export interface UserOpenCurrencyBalances {
  openOrderAmounts: UserOpenCurrencyBalancesAsset[];
  openRFQAmounts: UserOpenCurrencyBalancesAsset[];
  pendingTransactionAmounts: UserOpenCurrencyBalancesAsset[];
}

export interface UserOpenTokenBalance {
  openOrderToken: { pair_id: number; token: number }[];
  pendingTransactionToken: { pair_id: number; token: number }[];
}

// TODO use enum to access the values instead of plain string
export enum UserStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
  REJECTED = 'REJECTED',
}

export enum KycStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  SKIP = 'SKIP',
  ACCEPTED = 'ACCEPTED',
}

export enum BankVerificationStatus {
  NEW = 'NEW',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface UserProfile {
  userId: number;
  cynopsisFullName: string | null;
  hubspotId: number | null;
  cynopsisCustomerId: number | null;
  cognitoId: string | null;
  companyId: number | null;
  sector: string | null;
  subSector: string | null;
  idxSrnRegistry: string | null;
  idxKseiSid: string | null;
  idxKseiCashAccount: string | null;
  updatedUtc: Date | null;
  createdUtc: Date | null;
  tradingName: string | null;
  statusCode: UserStatus;
  nationality: string;
  countryOfResidence: string;
  kycStatus: KycStatus;
  investorVerificationStatus: string;
  bankVerificationStatus: BankVerificationStatus;
  hasSignedErpa: number | null;
  hasLightKyc: number | null;
  fatca: string | null;
  customerRelatedPersons: string | null;
  memberKycStatus: string | null;
  address: string | null;
  identificationNumber: string | null;
  gstType: any;
  taxRegistrationNumber: string | null;
  fullAddress: string | null;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  parent: User | null;
  entityId: number;
  entity: Entity | null;
  mfaSecret?: string;
  isOffMarketSeller: number;
  allowMultiAccess: number;
  isMarketMaker: number;
  isMember: number;
  parentId?: number;
  systemId: SystemType;
}

export interface UserFullProfileResponse {
  user: Record<string, any>;
  customer?: Record<string, any>;
  profile: Record<string, any>;
  account?: Record<string, any>;
  permissions?: Array<string>;
  bankAccount?: Record<string, any>;
  rootUser: Record<string, any>;
}

export enum UserExternalAccountType {
  CarbonRegistryAccount = 'carbon_registry',
  ExternalAccount = 'external_account',
}

export enum UserExternalAccountProvider {
  XConnect = 'xconnect',
  B3 = 'b3',
}
