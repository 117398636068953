import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import styled from 'styled-components';

import { Button, ButtonVariant, IconName } from '@aircarbon/ui';

import { PackUnpackGERForm } from '../PackUnpackGERForm';
import { useGERUnpacker } from './hooks/useGERUnpacker';

export const UnpackGER: React.FC<{
  isVisible: boolean;
  onClose(): void;
}> = (props) => {
  const { isVisible, onClose } = props;
  const {
    quantity,
    assets,
    resultingBalance,
    isUnpackingDisabled,
    validationErrorsMap,
    isUnpackingInProgress,
    resetUserInput,
    changeQuantity,
    unpack,
  } = useGERUnpacker({
    onUnpackingFinished() {
      resetUserInput();
      onClose();
    },
  });

  const onCloseModal = () => {
    if (isUnpackingInProgress) {
      return;
    }
    resetUserInput();
    onClose();
  };

  const onPressConfirm = () => {
    unpack();
  };

  return (
    <Modal isOpen={isVisible} title="Unpack GER" onClose={onCloseModal}>
      {() => {
        return (
          <>
            <ModalContent>
              <PackUnpackGERForm
                quantity={quantity}
                assets={assets}
                resultingBalance={resultingBalance}
                onChangeQuantity={changeQuantity}
                balanceTitle="GER Balance After Unpacking"
                errors={validationErrorsMap}
              />
            </ModalContent>
            <ModalFooter>
              <StyledButton
                endIcon={IconName.ArrowEnd}
                variant={ButtonVariant.secondary}
                isDisabled={isUnpackingDisabled}
                isLoading={isUnpackingInProgress}
                onPress={onPressConfirm}
              >
                Confirm
              </StyledButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;
