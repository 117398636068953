import type * as React from 'react';

import { Button, ButtonVariant, IconName } from '@aircarbon/ui';

import Modal, { ModalContent, ModalFooter } from './Modal';

interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  cancel?: {
    label?: string;
    icon?: IconName | 'no-icon';
    callback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
  };
  accept?: {
    label?: string;
    callback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
    icon?: IconName | 'no-icon';
  };
  footer?: React.ReactNode;
  action?: React.ReactElement;
}

export const ConfirmModal = ({
  title,
  children,
  action,
  isOpen,
  isLoading,
  cancel,
  accept,
  footer,
  ...props
}: Props) => {
  return (
    <Modal isOpen={isOpen} isLoading={isLoading} title={title} action={action} onClose={props.onClose}>
      {({ onClose, onLoading }) => {
        return (
          <>
            <ModalContent>{children}</ModalContent>
            {(cancel || accept || footer) && (
              <ModalFooter>
                <div className="flex flex-row flex-1 gap-base">
                  {cancel && (
                    <Button
                      className="flex-1"
                      variant={ButtonVariant.outlined}
                      onPress={() => {
                        const value = cancel?.callback?.({
                          onClose,
                          onLoading,
                        });
                        if (value !== false) {
                          onClose();
                        }
                      }}
                      endIcon={cancel.icon === 'no-icon' ? undefined : cancel.icon || IconName.XCircle}
                    >
                      {cancel.label || 'Cancel'}
                    </Button>
                  )}
                  {accept && (
                    <Button
                      className="flex-1"
                      onPress={() => {
                        const value = accept?.callback?.({
                          onClose,
                          onLoading,
                        });
                        if (value !== false) {
                          onClose();
                        }
                      }}
                      variant={ButtonVariant.secondary}
                      endIcon={accept.icon === 'no-icon' ? undefined : accept.icon || IconName.CheckCircle}
                    >
                      {accept.label || 'Accept'}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            )}
          </>
        );
      }}
    </Modal>
  );
};
