import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';

import { Icon, IconName } from '@aircarbon/ui';

import { useIncomingRequest } from '../hooks/incomingTradeRequest';

export const Summery = () => {
  const allTradeRequest = useIncomingRequest();
  const executedTradeRequest = useIncomingRequest({ status: 'EXECUTED' });
  const rejectedTradeRequest = useIncomingRequest({ status: 'REJECTED' });

  return (
    <SummaryHolder>
      <SummaryCard
        icon={<Icon size="1.75rem" name={IconName.Database} />}
        title="Total Requests"
        value={allTradeRequest.response?.count?.toString() ?? '-'}
      />
      <SummaryCard
        icon={<Icon size="1.75rem" name={IconName.CheckCircle} />}
        title="Approved Requests"
        value={executedTradeRequest.response?.count?.toString() ?? '-'}
      />
      <SummaryCard
        icon={<Icon size="1.75rem" name={IconName.Ban} />}
        title="Rejected Requests"
        value={rejectedTradeRequest.response?.count?.toString() ?? '-'}
      />
    </SummaryHolder>
  );
};
