type ToStartYearProps = {
  startYears: Array<{
    value: string;
    title: string;
  }>;
  startYear: string;
};

export const toStartYear = (props: ToStartYearProps) => {
  if (!props.startYears.length) {
    return '';
  }

  if (props.startYears.map((v) => v.value).includes(props.startYear)) {
    return props.startYear;
  }

  return '';
};
