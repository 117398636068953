import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  InputDate,
  InputText,
  Select,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toLayerBackground,
} from '@aircarbon/ui';

import { FieldWrapper } from 'components/styled/Styled';

import { VintagesTable } from './Milestones/components/VintagesTable';
import { useIssuanceYears } from './Milestones/hooks/useIssuanceYears';
import type { Vintage } from './Milestones/utils/Vintage';
import { toVintages } from './Milestones/utils/toVintages';

export const Milestones: React.FC<{
  value: {
    implementationDate: string;
    vintages: Array<Vintage>;
  };
  error?: Record<string, string>;
  onChange(value: { implementationDate: string; vintages: Array<Vintage> }): void;
}> = (props) => {
  const { value, error, onChange } = props;
  const { implementationDate, vintages: vintagesValue } = value;
  const { startYear, startYears, endYears, endYear, vintagesCount, onChangeStartYear, onChangeEndYear } =
    useIssuanceYears({
      implementationDate,
      prevVintages: vintagesValue,
    });
  const [vintages, setVintages] = useState(
    toVintages({
      prevVintages: vintagesValue,
      startYear,
      endYear,
    }),
  );

  useEffect(() => {
    const newVintages = toVintages({
      prevVintages: vintages,
      startYear,
      endYear,
    });
    setVintages(newVintages);

    onChange({
      implementationDate,
      vintages: newVintages,
    });
  }, [startYear, endYear]);

  const onChangeVintages = (vintages: Array<Vintage>) => {
    setVintages(vintages);
    onChange({ implementationDate, vintages });
  };

  const onChangeImplementationDate = ({ value }: { value: string }) => {
    onChange({
      implementationDate: new Date(value).toISOString(),
      vintages,
    });
  };

  console.log(error);

  return (
    <>
      <Text variant={TypographyVariant.subtitle1}>Crediting Period Term</Text>
      <div className="flex w-full mt-large">
        <div className="pr-2 w-2/5">
          <FieldWrapper>
            <InputDate
              label="Expected Implementation Date*"
              value={implementationDate ? formatISO(new Date(implementationDate), { representation: 'date' }) : ''}
              error={error?.implementationDate}
              onChange={onChangeImplementationDate}
            />
          </FieldWrapper>
        </div>
        <div className="flex pl-2 w-2/3">
          <div className="flex pr-2 w-2/3">
            <FieldWrapper>
              <Select
                label="Vintage Start Year*"
                placeholder="Select"
                value={startYear}
                items={startYears}
                onChange={({ value }) => onChangeStartYear(value)}
              />
            </FieldWrapper>
          </div>
          <div className="flex pr-2 pl-2 w-2/3">
            <FieldWrapper>
              <Select
                label="Vintage End Year*"
                placeholder="Select"
                value={endYear}
                items={endYears}
                onChange={({ value }) => onChangeEndYear(value)}
              />
            </FieldWrapper>
          </div>
          <div className="flex pl-2 w-1/3">
            <FieldWrapper>
              <InputText isDisabled label="# Vintages" value={vintagesCount} />
            </FieldWrapper>
          </div>
        </div>
      </div>

      {!error?.implementationDate && !!error?.vintages && (
        <VintagesError>
          <Text variant={TypographyVariant.caption} color={TextColor.error}>
            {error.vintages}
          </Text>
        </VintagesError>
      )}
      {!!startYear && !!endYear && (
        <VintagesTable onChange={onChangeVintages} maximumOfferedQuantity={20} vintages={vintages} />
      )}
    </>
  );
};

const VintagesError = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => toLayerBackground(theme)('layerDanger')};
  padding: 16px;
`;
