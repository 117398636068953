/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTradeRequestResponse } from '../models/CreateTradeRequestResponse';
import type { GetDownloadAuctionDocumentResponse } from '../models/GetDownloadAuctionDocumentResponse';
import type { GetGetCriteriaOptionsResponse } from '../models/GetGetCriteriaOptionsResponse';
import type { GetReportsResponse } from '../models/GetReportsResponse';
import type { GetTradeRequestsResponse } from '../models/GetTradeRequestsResponse';
import type { GetTradesResponse } from '../models/GetTradesResponse';
import type { PostCreateCounterOfferResponse } from '../models/PostCreateCounterOfferResponse';
import type { PostOrderResponse } from '../models/PostOrderResponse';
import type { UpdateTradeRequestResponse } from '../models/UpdateTradeRequestResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PostCreateCounterOfferResponse Successful operation
     * @throws ApiError
     */
    public postApiOtcCounterOffer({
        xEntityDomain,
        tradeRequestId,
        quantity,
        price,
        userId,
        comments,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        tradeRequestId: string,
        quantity: number,
        price: number,
        userId?: number,
        comments?: string,
    }): CancelablePromise<PostCreateCounterOfferResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/otc/counter-offer',
            path: {
                'tradeRequestId': tradeRequestId,
                'userId': userId,
                'quantity': quantity,
                'price': price,
                'comments': comments,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Otc was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns PostOrderResponse Successful operation
     * @throws ApiError
     */
    public getApiOtcOrder({
        xEntityDomain,
        assetCategoryId,
        sideId,
        qty,
        timeInForceTypeId,
        isAuction = false,
        price,
        baseAssetId,
        startDate,
        endDate,
        minQty,
        maxQty,
        qtyMultipleOf,
        isPrefunded = false,
        userId,
        projectId,
        criteria,
        showBidVolume = false,
        showBestBid = false,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        assetCategoryId: 1 | 5 | 4,
        sideId: 1 | 2 | 3 | 4,
        qty: number,
        timeInForceTypeId: number,
        isAuction?: boolean,
        price?: number,
        baseAssetId?: number,
        startDate?: string,
        endDate?: string,
        minQty?: number,
        maxQty?: number,
        qtyMultipleOf?: number,
        isPrefunded?: boolean,
        userId?: number,
        projectId?: number,
        criteria?: Array<{
            key: 'DESCRIPTION' | 'REGISTRY_PROJECT_URL' | 'VINTAGE_YEAR' | 'BSI_CODE' | 'VERIFIED_REFERENCE_NUMBER' | 'PROJECT_IMG_URL' | 'WORLD_MAP_LINK' | 'INITIAL_YEAR_OF_COMPLIANCE' | 'CARBON_STANDARD' | 'SDG_GOAL' | 'COUNTRY_CODE' | 'EU_UNIT_TYPE' | 'TYPE_OF_CARBON_UNIT' | 'STATE_OR_PROVINCE_ID' | 'PROJECT_DEVELOPER' | 'CPX_PHASE' | 'PROJECT_TYPE' | 'OTHER_CERTIFICATION' | 'ADDRESS' | 'COMMISSIONING_DATE' | 'REGISTRATION_DATE' | 'EXPIRY_DATE' | 'PRIMARY_FUEL' | 'FUEL_CODE' | 'TECHNOLOGY' | 'CAPACITY' | 'IS_SUPPORTED_BY_GOV_SUBSIDIES' | 'LATITUDE' | 'LONGITUDE' | 'VOLUME_MWH' | 'UN_SECTORAL_SCOPE' | 'CLASSIFICATION' | 'SDG_VERIFICATION_LEVEL' | 'CCB_STANDARD' | 'METHODOLOGY' | 'EU_ETS_PHASE' | 'SECTOR' | 'SUB_SECTOR' | 'REGISTRY_PROJECT_ID' | 'REGISTRY_ID' | 'PROJECT_ID';
            valueFrom: string;
            valueTo?: string;
            operator: string;
        }>,
        showBidVolume?: boolean,
        showBestBid?: boolean,
    }): CancelablePromise<PostOrderResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/otc/order',
            path: {
                'assetCategoryId': assetCategoryId,
                'sideId': sideId,
                'isAuction': isAuction,
                'qty': qty,
                'price': price,
                'baseAssetId': baseAssetId,
                'startDate': startDate,
                'endDate': endDate,
                'minQty': minQty,
                'maxQty': maxQty,
                'qtyMultipleOf': qtyMultipleOf,
                'isPrefunded': isPrefunded,
                'userId': userId,
                'timeInForceTypeId': timeInForceTypeId,
                'projectId': projectId,
                'criteria': criteria,
                'showBidVolume': showBidVolume,
                'showBestBid': showBestBid,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns CreateTradeRequestResponse Successful operation
     * @throws ApiError
     */
    public getApiOtcTradeRequest({
        xEntityDomain,
        otcOrderId,
        quantity,
        price,
        timeInForceId,
        senderUserId,
        isPrefunded = false,
        baseAssetId,
        vintageYear,
        projectId,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        otcOrderId: number,
        quantity: number,
        price: number,
        timeInForceId: number,
        senderUserId?: number,
        isPrefunded?: boolean,
        baseAssetId?: number | null,
        vintageYear?: number,
        projectId?: number,
    }): CancelablePromise<CreateTradeRequestResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/otc/trade-request',
            path: {
                'otcOrderId': otcOrderId,
                'quantity': quantity,
                'price': price,
                'senderUserId': senderUserId,
                'isPrefunded': isPrefunded,
                'baseAssetId': baseAssetId,
                'vintageYear': vintageYear,
                'projectId': projectId,
                'timeInForceId': timeInForceId,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns UpdateTradeRequestResponse Successful operation
     * @throws ApiError
     */
    public putApiOtcTradeRequest({
        xEntityDomain,
        tradeRequestId,
        status,
        rejectionReason,
        internalRemark,
        onBehalfOfUserId,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        tradeRequestId: string,
        status: 'ACCEPTED' | 'CANCELLED',
        rejectionReason?: 'Credits no longer available' | 'Unsuitable Quantity' | 'Unsuitable Price' | 'Unsuitable Project',
        internalRemark?: string,
        onBehalfOfUserId?: number,
    }): CancelablePromise<UpdateTradeRequestResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/otc/trade-request',
            path: {
                'tradeRequestId': tradeRequestId,
                'rejectionReason': rejectionReason,
                'internalRemark': internalRemark,
                'onBehalfOfUserId': onBehalfOfUserId,
                'status': status,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Otc was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns GetDownloadAuctionDocumentResponse Successful operation
     * @throws ApiError
     */
    public getApiMb2DownloadAuctionDocument({
        xEntityDomain,
        id,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        id: string,
    }): CancelablePromise<GetDownloadAuctionDocumentResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/mb2/downloadAuctionDocument',
            path: {
                'id': id,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Mb2 was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns GetGetCriteriaOptionsResponse Successful operation
     * @throws ApiError
     */
    public getOtcCriteriaOption({
        xEntityDomain,
        assetCategoryId,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        assetCategoryId: 1 | 5 | 4,
    }): CancelablePromise<GetGetCriteriaOptionsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/otc/criteria-option',
            path: {
                'assetCategoryId': assetCategoryId,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Otc was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns GetTradeRequestsResponse Successful operation
     * @throws ApiError
     */
    public getApiOtcTradeRequests({
        xEntityDomain,
        assetCategoryId,
        otcOrderId,
        status,
        projectName,
        registryProjectId,
        page = 1,
        limit = 10,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        assetCategoryId?: 1 | 5 | 4,
        otcOrderId?: string,
        status?: Array<'APPROVED' | 'ACCEPTED' | 'CANCELLED' | 'EXECUTED' | 'NEW' | 'PENDING' | 'PROCESSING' | 'REJECTED' | 'OTC_SETTLED'>,
        projectName?: string,
        registryProjectId?: string,
        page?: number | null,
        limit?: number | null,
    }): CancelablePromise<GetTradeRequestsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/otc/trade-requests',
            path: {
                'assetCategoryId': assetCategoryId,
                'otcOrderId': otcOrderId,
                'status': status,
                'projectName': projectName,
                'registryProjectId': registryProjectId,
                'page': page,
                'limit': limit,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Trade requests were not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns GetTradesResponse Successful operation
     * @throws ApiError
     */
    public getApiOtcTrade({
        xEntityDomain,
        sideId,
        orderId,
        tradeRequestId,
        page = 1,
        limit = 10,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        sideId?: 1 | 2 | 3 | 4,
        orderId?: number | null,
        tradeRequestId?: number | null,
        page?: number | null,
        limit?: number | null,
    }): CancelablePromise<GetTradesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/otc/trade',
            path: {
                'sideId': sideId,
                'orderId': orderId,
                'tradeRequestId': tradeRequestId,
                'page': page,
                'limit': limit,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * @returns GetReportsResponse Successful operation
     * @throws ApiError
     */
    public getApiOtcReportOrder({
        xEntityDomain,
        assetCategoryId,
        userId,
        startDate,
        endDate,
        page = 1,
        limit = 10,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        assetCategoryId?: 1 | 5 | 4,
        userId?: number | null,
        startDate?: string,
        endDate?: string,
        page?: number | null,
        limit?: number | null,
    }): CancelablePromise<GetReportsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/otc/report/order',
            path: {
                'assetCategoryId': assetCategoryId,
                'userId': userId,
                'startDate': startDate,
                'endDate': endDate,
                'page': page,
                'limit': limit,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Trade requests were not found`,
                500: `Internal server error`,
            },
        });
    }
}
