import * as React from 'react';
import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';

import { Button, ButtonSize, ButtonVariant } from '@aircarbon/ui';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  action?: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export type DialogProps = {
  title: string;
  message: string | React.ReactNode;
  cancel: Props;
  accept: Props;
};

const Dialog: React.FC<DialogProps> = ({ title, message, cancel, accept }) => {
  return (
    <Modal title={title} isOpen={true} onClose={() => cancel.onClick?.()}>
      {() => {
        return (
          <>
            <ModalContent>{message}</ModalContent>
            <ModalFooter>
              <div className="flex flex-row w-full gap-base">
                <Button
                  className="flex-1"
                  size={ButtonSize.s}
                  variant={ButtonVariant.outlined}
                  onPress={() => cancel.onClick?.()}
                >
                  CANCEL
                </Button>
                <Button
                  className="flex-1"
                  variant={ButtonVariant.primary}
                  size={ButtonSize.s}
                  onPress={() => accept.onClick?.()}
                >
                  CONFIRM
                </Button>
              </div>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
};

export type DialogDefaultContext = {
  props?: DialogProps;
  showDialog?: (props: DialogProps) => void;
  removeDialog?: () => void;
};

export const DialogContext = React.createContext({} as DialogDefaultContext);

export default Dialog;
