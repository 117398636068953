import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOtcCriteria } from 'refreshed-pages/market-board-v2/hooks/useOtcCriteria';
import { toTuple } from 'refreshed-pages/market-board-v2/utils/toTuple';
import { toVintageYears } from 'refreshed-pages/market-board-v2/utils/toVintageYears';

import { IconName, Modal, ModalStack, Select, SelectCountry, styled, toSpacing } from '@aircarbon/ui';

import { FieldsGroup } from '../../../../../FieldsGroup';
import type { Criteria } from '../../utils/Criteria';

const AddCriteriaModalContent: React.FunctionComponent<{
  criteria: Array<Criteria>;
  onChange(props: { criteria: Array<Criteria> }): void;
}> = (props) => {
  const { criteria, onChange } = props;
  const { meta, isFetching } = useOtcCriteria();

  const criteriaByKey = useMemo(() => {
    const criteriaMap = new Map<string, { key: string; value: string }>();

    criteria.forEach((item) => criteriaMap.set(item.key, item));

    return criteriaMap;
  }, [criteria]);

  const metaTupples = useMemo(() => {
    if (isFetching) {
      return null;
    }

    return toTuple(meta);
  }, [isFetching, meta]);

  const onChangeCriteria = (criteria: Criteria) => {
    if (!criteria.value) {
      criteriaByKey.delete(criteria.key);
    } else {
      criteriaByKey.set(criteria.key, criteria);
    }

    onChange({
      criteria: Array.from(criteriaByKey.values()),
    });
  };

  return (
    <Content>
      {metaTupples?.map((tupple) => (
        <FieldsGroup columnMinWidth="14rem">
          {tupple.map((item) =>
            !item ? (
              false
            ) : item?.value === 'COUNTRY_CODE' ? (
              <SelectCountry
                label={item.label}
                isMultiple
                placeholder="Any"
                value={criteriaByKey.get(item.value)?.value}
                onChange={({ value }) =>
                  onChangeCriteria({
                    key: item.value,
                    value,
                  })
                }
              />
            ) : item?.value === 'VINTAGE_YEAR' ? (
              <Select
                label={item?.label}
                placeholder="Any"
                isMultiple
                value={criteriaByKey.get(item.value)?.value}
                items={toVintageYears()}
                onChange={({ value }) =>
                  onChangeCriteria({
                    key: item.value,
                    value,
                  })
                }
              />
            ) : item.value === 'PROJECT_ID' ? null : (
              <Select
                label={item?.label}
                placeholder="Any"
                isMultiple
                value={criteriaByKey.get(item.value)?.value}
                items={item?.options?.map((option) => ({ title: option.label, value: option.value })) || []}
                onChange={({ value }) =>
                  onChangeCriteria({
                    key: item.value,
                    value,
                  })
                }
              />
            ),
          )}
        </FieldsGroup>
      ))}
    </Content>
  );
};

export const AddCriteriaModal: React.FunctionComponent<{
  isVisible: boolean;
  criteria: Array<Criteria>;
  onAdd: (props: { criteria: Array<Criteria> }) => void;
  onClose: () => void;
}> = (props) => {
  const { isVisible, criteria: criteriaProp, onClose, onAdd } = props;
  const [criteria, setCriteria] = useState<Array<Criteria>>(criteriaProp);

  useEffect(() => {
    setCriteria(criteriaProp);
  }, [criteriaProp]);

  const onPressPositiveButton = useCallback(() => {
    onAdd({
      criteria,
    });
  }, [criteria, onAdd]);

  return (
    <ModalStack>
      <Modal
        title="Add Project Criteria"
        isVisible={isVisible}
        onClose={onClose}
        positiveButtonIcon={IconName.ArrowEnd}
        positiveButtonText="Add Criteria"
        onPressPositiveButton={onPressPositiveButton}
      >
        <AddCriteriaModalContent
          criteria={criteria}
          onChange={(value) => {
            setCriteria(value.criteria);
          }}
        />
      </Modal>
    </ModalStack>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
