import { format, getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import React, { useState } from 'react';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Colors, FontSize } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Icon, IconName, TextColor } from '@aircarbon/ui';

import { Dropdown } from './Dropdown';
import { Text } from './Text';

const CalendarWrapper = styled.div`
  > div {
    width: 100%;
    padding-right: 0 !important;
    display: flex;
    div.react-datepicker {
      background-color: var(${Colors.gray_0});
      color: var(${Colors.gray_900});
      border: 1px solid var(${Colors.gray_200});
      position: relative;
      height: max-content;
      display: flex;
      flex-direction: row;
    }
    div.react-datepicker__header {
      background-color: var(${Colors.gray_0});
      border-bottom: 1px solid var(${Colors.gray_200});
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: var(${Colors.gray_200});
      color: var(${Colors.gray_900});
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: var(${Colors.gray_200});
      color: var(${Colors.gray_900});
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: var(${Colors.gray_900});
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: var(${Colors.gray_900});
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      color: var(${Colors.gray_0});
      background-color: var(${Colors.gray_900});
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: var(${Colors.gray_900});
    }
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
      border-top-color: var(${Colors.gray_200});
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
      border-bottom-color: var(${Colors.gray_200});
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      color: var(${Colors.gray_500});
    }
    .react-datepicker__time-container {
      border-left: 1px solid var(${Colors.gray_200});
    }
    .react-datepicker__time-container .react-datepicker__time {
      background-color: var(${Colors.gray_0});
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      color: var(${Colors.gray_900});
      background-color: var(${Colors.gray_200});
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover {
      color: var(${Colors.gray_0});
      background-color: var(${Colors.gray_900});
    }
    .react-datepicker__time-container {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      // height: 100%;
    }
    .react-datepicker__triangle {
      display: none;
    }
  }
`;

const Select = styled.select`
  outline: none;
  appearance: none;
  text-align: center;
  background-color: transparent;
`;

const DatePickerFn = <CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange> & {
    size?: 'base' | 'xs' | 'sm' | 'l';
    onClear?: () => void;
    onShowChange?: (value: boolean) => void;
  } & {
    ref?: React.RefObject<HTMLButtonElement> | React.ForwardedRef<HTMLButtonElement>;
  },
  ref?: React.RefObject<HTMLButtonElement> | React.ForwardedRef<HTMLButtonElement>,
) => {
  const [isShowing, setShow] = useState(false);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <Dropdown
      ref={ref}
      type="button"
      disabled={props.disabled}
      onShowChange={(value) => {
        setShow(value);
        props.onShowChange?.(value);
      }}
      config={{
        color: 'gray',
        size: props.size ?? 'sm',
        icon: {
          left: <Icon name={IconName.Calendar} size={'1.25rem'} />,
          right:
            isShowing && props.showTimeSelect
              ? (props.startDate || props.endDate || props.selected) && <Icon name={IconName.Check} size={'0.875rem'} />
              : (props.startDate || props.endDate || props.selected) && (
                  <div
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.onClear?.();
                    }}
                  >
                    <Icon name={IconName.X} size={'0.875rem'} />
                  </div>
                ),
        },
      }}
      style={{
        minWidth: '100%',
        maxWidth: '100%',
      }}
      content={{
        style: {
          minWidth: 'auto',
          minHeight: 'auto',
          padding: '0px',
          background: 'transparent',
          border: '0px',
        },
        before: (onClose) => (
          <CalendarWrapper>
            <ReactDatePicker
              {...(props as Omit<typeof props, 'ref'>)}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                prevYearButtonDisabled,
                nextYearButtonDisabled,
                decreaseYear,
                increaseYear,
              }) => (
                <div className="flex flex-row justify-center items-center w-full gap-xs px-xs">
                  <div className="flex flex-row flex-1 justify-center items-center w-full gap-xs">
                    <div onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}>
                      <Icon
                        className="cursor-pointer"
                        color={prevMonthButtonDisabled ? TextColor.disabled : TextColor.primary}
                        name={IconName.ChevronStart}
                      />
                    </div>
                    <Text size={FontSize.small}>
                      <Select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </Text>
                    <div onClick={nextMonthButtonDisabled ? undefined : increaseMonth}>
                      <Icon
                        className="cursor-pointer"
                        color={nextMonthButtonDisabled ? TextColor.disabled : TextColor.primary}
                        name={IconName.ChevronEnd}
                      />
                    </div>
                    <div onClick={prevYearButtonDisabled ? undefined : decreaseYear}>
                      <Icon
                        className="cursor-pointer"
                        color={prevYearButtonDisabled ? TextColor.disabled : TextColor.primary}
                        name={IconName.ChevronStart}
                      />
                    </div>

                    <Text size={FontSize.small}>
                      <Select value={getYear(date)} onChange={({ target: { value } }) => changeYear(Number(value))}>
                        {range(getYear(date || new Date()) - 10, getYear(date || new Date()) + 10, 1).map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </Text>
                    <div onClick={nextYearButtonDisabled ? undefined : increaseYear}>
                      <Icon
                        className="cursor-pointer"
                        color={nextYearButtonDisabled ? TextColor.disabled : TextColor.primary}
                        name={IconName.ChevronEnd}
                      />
                    </div>
                  </div>
                </div>
              )}
              onChange={(...args) => {
                props.onChange?.(...args);
                if (props.showTimeSelect) return;
                const [value] = args;
                if (value && Array.isArray(value)) {
                  if (value[0] && value[1]) onClose();
                } else if (value) {
                  onClose();
                }
              }}
              inline
            />
          </CalendarWrapper>
        ),
      }}
    >
      {props.selectsRange ? (
        <>
          {props.startDate || props.endDate ? (
            <>
              <Text display="block" className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">
                {props.startDate ? format(props.startDate, 'MMM d yyyy') : 'Start Date'} -{' '}
                {props.endDate ? format(props.endDate, 'MMM d yyyy') : 'End Date'}
              </Text>
            </>
          ) : (
            <div className="w-full font-normal text-left text-gray_500">
              {props.placeholderText ?? 'Select Start Date - End Date'}
            </div>
          )}
        </>
      ) : (
        <>
          {props.selected ? (
            <>
              <Text display="block" className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">
                {props.showTimeSelect
                  ? format(props.selected, 'MMM d yyyy hh:mm a')
                  : format(props.selected, 'MMM d yyyy')}
              </Text>
            </>
          ) : (
            <div className="w-full font-normal text-left text-gray-500">{props.placeholderText ?? 'Select Date'}</div>
          )}
        </>
      )}
    </Dropdown>
  );
};

export const DatePicker = React.forwardRef(DatePickerFn) as typeof DatePickerFn;
