import { isBefore } from 'date-fns';
import { useCallback } from 'react';
import { useDateCountdown } from 'refreshed-pages/market-board-v2/hooks/useDateCountdown';

import { CMBOrderType, CardCMB, type CardCMBProps } from '@aircarbon/ui';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { User } from 'state/user';

import { toFormattedDate } from './utils/toFormattedDate';

export const ListingCard: React.FunctionComponent<{
  item: CardCMBProps & {
    startsAt?: string;
    isClosed?: boolean;
  };
  onPressCTA(item: CardCMBProps): void;
}> = ({ item, onPressCTA }) => {
  const { marketSettings } = useMarketSettings({});
  const isFutureAuction = item.startsAt ? isBefore(new Date(), new Date(item.startsAt)) : false;

  const { isFinished } = useDateCountdown({
    date: isFutureAuction ? new Date(item.startsAt as string) : item.endsAt ? new Date(item.endsAt) : undefined,
  });
  const {
    status: { canBidAuctionV2: checkCanBidAuction, canBidCmbAskV2, canManageCmbBidV2 },
  } = User.useContainer();

  const isAuctionBidDisabled = item.type === CMBOrderType.Auction && !checkCanBidAuction();
  const isCmbBidDisabled = item.type !== CMBOrderType.Auction && !canBidCmbAskV2();

  const isCTADisabled =
    !item.isMine && (isFutureAuction || isFinished || item.isClosed || !marketSettings?.otcMatchingEnabled);
  const isCTAHidden = item.isMine ? !canManageCmbBidV2() : isAuctionBidDisabled || isCmbBidDisabled;

  const endsAt = item.isClosed
    ? 'Closed'
    : isFutureAuction
      ? toFormattedDate({
          date: item.startsAt,
          prefix: 'Starts',
        })
      : toFormattedDate({
          date: item.endsAt,
          prefix: 'Ends',
        });

  const onPressCardCta = useCallback(() => {
    onPressCTA(item);
  }, [item, onPressCTA]);

  return (
    <CardCMB
      {...(item as CardCMBProps)}
      endsAt={endsAt}
      onPressCTA={onPressCardCta}
      isCTAHidden={isCTAHidden}
      isCTADisabled={isCTADisabled}
    />
  );
};
