import {
  BorderColor,
  Card,
  CardVariant,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
} from '@aircarbon/ui';

import { InformationItem } from './components/InformationItem';

type ProjectCardLayoutProps = {
  title: string;
  information: Array<{
    label: string;
    value?: string;
  }>;
  subtitle?: string;
  description?: string;
  endSection?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

export const ProjectCardLayout: React.FC<ProjectCardLayoutProps> = (props) => {
  const { title, subtitle, description, information, endSection, header, footer } = props;

  return (
    <Card variant={CardVariant.Bordered}>
      <CardContent>
        <StartSection>
          {header}
          <Content>
            {!!subtitle && <StyledDescriptionText>{subtitle}</StyledDescriptionText>}
            <StyledTitleText variant={TypographyVariant.h5Title}>{title}</StyledTitleText>
            {!!description && <StyledDescriptionText>{description}</StyledDescriptionText>}
          </Content>
          <Information>
            {information.map((informationItem, index) => (
              <InformationItem key={index} label={informationItem.label} value={informationItem.value} />
            ))}
          </Information>
          {footer}
        </StartSection>
        <EndSection>{endSection}</EndSection>
      </CardContent>
    </Card>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardContent = styled.div`
  gap: 24px;
  display: flex;

  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const StartSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 24px;
`;

const StyledTitleText = styled(Text)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDescriptionText = styled(Text).attrs({
  variant: TypographyVariant.body2,
  color: TextColor.secondary,
})`
  display: inline;
  white-space: pre-wrap;
`;

const Information = styled.div`
  display: flex;
  gap: 12px 48px;
  flex-wrap: wrap;
`;

const EndSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-shrink: 0;
  align-self: stretch;
  border-inline-start: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  @media (max-width: 1367px) {
    width: 320px;
  }

  @media (max-width: 820px) {
    width: 100%;
    border-inline-start: 0px;
    border-block-start: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }
`;
