import { toast as ReactToast, type ToastContent, type ToastOptions } from 'react-toastify';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Icon, IconName } from '@aircarbon/ui';

export const ToastWrapper = styled.div`
  display: contents;
  > div > div.toast > div {
    background-color: var(${Colors.gray_900});
    border-radius: var(${Radius.medium});
    padding: var(${Spacing.base});
    > .body {
      padding: 0px;
      margin: 0px;
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center;
        gap: var(${Spacing.small});
        > .icon {
          min-width: 32px;
          min-height: 32px;
          color: var(${Colors.gray_900});
          border-radius: var(${Radius.medium});
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          &.success {
            background-color: var(${Colors.success_400});
          }
          &.error {
            background-color: var(${Colors.danger_400});
          }
          &.warn {
            background-color: var(${Colors.warning_400});
          }
          &.info {
            background-color: var(${Colors.primaryNormal});
          }
        }
        > .content {
          color: var(${Colors.gray_0});
          font-size: var(${FontSize.small});
          font-weight: var(${FontWeight.semibold});
        }
      }
    }
    > .progressbar {
      position: absolute;
      bottom: 4px;
      left: 0;
      width: calc(100% - 4%);
      height: 4px;
      opacity: 0.7;
      transform-origin: left;
      left: 2%;
      border-radius: 100px;
      &.Toastify__progress-bar--success {
        background-color: var(${Colors.success_400});
      }
      &.Toastify__progress-bar--error {
        background-color: var(${Colors.danger_400});
      }
      &.Toastify__progress-bar--warn {
        background-color: var(${Colors.warning_400});
      }
      &.Toastify__progress-bar--info {
        background-color: var(${Colors.primaryNormal});
      }

      @keyframes Toastify__trackProgress {
        0% {
          width: 96%;
        }
        100% {
          width: 0%;
        }
      }
    }
  }
`;

export class toast {
  static default: typeof ReactToast.info = (
    content: ToastContent,
    options?: ToastOptions<{}> | undefined,
  ): React.ReactText => {
    return ReactToast.info(
      <>
        <div className="content">{content}</div>
      </>,
      options,
    );
  };
  static success: typeof ReactToast.success = (
    content: ToastContent,
    options?: ToastOptions<{}> | undefined,
  ): React.ReactText => {
    return ReactToast.success(
      <>
        <div className="icon success">
          <Icon size="0.875rem" name={IconName.Check} />
        </div>
        <div className="content">{content}</div>
      </>,
      options,
    );
  };
  static error: typeof ReactToast.error = (
    content: ToastContent,
    options?: ToastOptions<{}> | undefined,
  ): React.ReactText => {
    return ReactToast.error(
      <>
        <div className="icon error">
          <Icon size="0.875rem" name={IconName.X} />
        </div>
        <div className="content">{content}</div>
      </>,
      options,
    );
  };
  static warn: typeof ReactToast.warn = (
    content: ToastContent,
    options?: ToastOptions<{}> | undefined,
  ): React.ReactText => {
    return ReactToast.warn(
      <>
        <div className="icon warn">
          <Icon size="0.875rem" name={IconName.ExclamationTriangle} />
        </div>
        <div className="content">{content}</div>
      </>,
      options,
    );
  };
  static info: typeof ReactToast.info = (
    content: ToastContent,
    options?: ToastOptions<{}> | undefined,
  ): React.ReactText => {
    return ReactToast.info(
      <>
        <div className="icon info">
          <Icon size="1.25rem" name={IconName.ExclamationCircle} />
        </div>
        <div className="content">{content}</div>
      </>,
      options,
    );
  };
}
