import { User } from 'state/user';

export const useSettingsLink = () => {
  const {
    status: {
      canDeposit,
      canWithdraw,
      canSeeExternalBrokerClientForBlockTrade,
      canSeeExternalBrokerClientForBiofuel,
      canManageUser,
    },
  } = User.useContainer();

  if (
    canSeeExternalBrokerClientForBlockTrade() ||
    canSeeExternalBrokerClientForBiofuel() ||
    canManageUser() ||
    canDeposit() ||
    canWithdraw()
  ) {
    return '/account/company-settings/manage-user';
  }

  return false;
};
