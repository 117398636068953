import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import { toast } from 'refreshed-component/molecules/toast';
import * as Yup from 'yup';

import { Button, ButtonType, ButtonVariant } from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';

import { User } from 'state/user';

interface FormData {
  accountName: string;
  accountId: string;
}

interface Props {
  registryName: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const RegistryAccountForm: React.FC<Props> = ({ registryName, onSubmit, onCancel }) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(
      Yup.object().shape({
        accountName: Yup.string().required('Account name is required. The field cannot be left blank.'),
        accountId: Yup.string().required('Account ID is required. The field cannot be left blank.'),
      }),
    ),
    defaultValues: {
      accountName: '',
      accountId: '',
    },
  });

  const [addRegistry] = useMutation(async (formData: FormData) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    return fetch('/api/user/user/registry-account', {
      method: 'POST',
      body: JSON.stringify({
        details: `accountId: ${formData.accountId} | accountName: ${formData.accountName}`,
        name: registryName,
      }),
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
  });

  const onSubmitHandler = async (formData: FormData, evt: any) => {
    try {
      const data = await addRegistry(formData);

      if (data?.ok) {
        toast.success('Registry added');
        onSubmit();
      } else {
        const error = await data?.json();
        toast.error(error?.message ?? 'Something went wrong!');
        logger.warn({ error });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <ModalContent>
          <FormDevTool control={control} />
          <div className="flex flex-col gap-base">
            <div className="flex flex-col gap-xs">
              <Text size={FontSize.small}>Account name</Text>
              <Input
                type="text"
                config={{
                  color: 'gray',
                }}
                formRegister={register('accountName')}
              />
              {errors.accountName?.message && (
                <Text size={FontSize.small} color={Colors.danger_700}>
                  {errors.accountName?.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col gap-xs">
              <Text size={FontSize.small}>Account ID</Text>
              <Input
                type="text"
                config={{
                  color: 'gray',
                }}
                formRegister={register('accountId')}
              />
              {errors.accountId?.message && (
                <Text size={FontSize.small} color={Colors.danger_700}>
                  {errors.accountId?.message}
                </Text>
              )}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="flex flex-1 justify-center items-center gap-base">
            <Button
              variant={ButtonVariant.outlined}
              className="flex-1"
              type={ButtonType.Button}
              onPress={() => onCancel()}
            >
              Cancel
            </Button>
            <Button variant={ButtonVariant.secondary} className="flex-1" type={ButtonType.Submit}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </form>
    </>
  );
};

export default RegistryAccountForm;
