import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import { Button, ButtonVariant } from '@aircarbon/ui';
import { projects } from '@aircarbon/utils-common';
import type { ExternalBrokerClient } from '@aircarbon/utils-common/src/dto/trading';

import FormDevTool from 'components/FormDevTool';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  contactPersonName: yup.string().optional(),
  address: yup.string().optional(),
  country: yup.string().optional(),
  phone: yup.string().optional(),
});

export type BiofuelClientFormData = {
  name: string;
  address: string;
  country: string;
  email: string;
  phone: string;
  contactPersonName: string;
};

export function BiofuelClientForm({
  edit,
  onSubmit,
  onClose,
}: {
  edit?: ExternalBrokerClient;
  onSubmit?: (biofuelClientFormData: BiofuelClientFormData) => void;
  onClose?: () => void;
}) {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<BiofuelClientFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (edit) {
      setValue('name', edit.name);
      setValue('address', edit.address || '');
      setValue('country', edit.country || '');
      setValue('email', edit.email);
      setValue('phone', edit.phone || '');
      setValue('contactPersonName', edit.contactPersonName || '');
    }
  }, [edit]);

  watch();

  const trySubmit = async () => {
    const isValid = await trigger();
    if (isValid === true) {
      onSubmit?.(getValues());
    }
  };

  return (
    <>
      <FormDevTool control={control} />
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onSubmit={(event) => event.preventDefault()}
        className="flex flex-col"
      >
        <ModalContent>
          <div className="flex flex-col gap-base">
            <div className={`flex flex-col sm:flex-row gap-base`}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Client Name</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('name')}
                  onChange={(event) => {
                    setValue('name', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Name"
                />
                {errors.name?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.name.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Email</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('email')}
                  onChange={(event) => {
                    setValue('email', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Email"
                />
                {errors.email?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.email.message}
                  </Text>
                )}
              </div>
            </div>
            <div className={`flex flex-col sm:flex-row gap-base `}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Address</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('address')}
                  onChange={(event) => {
                    setValue('address', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Address"
                />
                {errors.address?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.address.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Country</Text>
                <Dropdown
                  list={Object.keys(projects.countryNames).map((code) => ({
                    id: code,
                    label: projects.countryNames[code],
                  }))}
                  selected={[getValues('country')]}
                  onSelectItem={(list) => {
                    setValue('country', (list?.id as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  placeholder="Select Country"
                />
                {errors.country?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.country?.message}
                  </Text>
                )}
              </div>
            </div>
            <div className={`flex flex-col sm:flex-row gap-base`}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Phone</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('phone')}
                  onChange={(event) => {
                    setValue('phone', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Phone"
                />
                {errors.phone?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.phone.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Contact Person Name</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('contactPersonName')}
                  onChange={(event) => {
                    setValue('contactPersonName', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Contact Person Name"
                />
                {errors.contactPersonName?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.contactPersonName.message}
                  </Text>
                )}
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalFooter className="gap-base">
          <Button
            className="flex-1"
            variant={ButtonVariant.outlined}
            isDisabled={Object.keys(errors).length > 0}
            onPress={onClose}
          >
            Cancel
          </Button>
          <Button
            className="flex-1"
            variant={ButtonVariant.secondary}
            isDisabled={Object.keys(errors).length > 0}
            onPress={() => {
              trySubmit().then();
            }}
          >
            {edit ? 'Save' : 'Add'}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
}

export default BiofuelClientForm;
