import { Route, Switch } from 'react-router-dom';

import { PageDepositRequestContainer } from './components/PageDepositRequestsContainer';

export const DepositRequests = () => {
  return (
    <Switch>
      <Route path={'/account/balances/deposit-requests/:id'} component={PageDepositRequestContainer} />
    </Switch>
  );
};
