import {
  Badge,
  Card,
  Divider,
  LabelWithDescription,
  LabelWithDescriptionSize,
  Layer,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';

import { User } from 'state/user';

import { BadgeProjectStatus } from '../../../../components/BadgeProjectStatus';
import type { ProjectStatus } from '../../../../utils/ProjectStatus';
import { ProjectDocument } from './components/ProjectDocument';
import { SectionHeader } from './components/SectionHeader';

export interface ProjectInformationProps {
  id: string;
  description?: string;
  status?: ProjectStatus;
  projectDetails: Array<
    Array<
      | {
          type: 'country';
          label: string;
          value: {
            code: string;
            name: string;
          };
        }
      | {
          type: 'item';
          label: string;
          value: string;
        }
      | {
          type: 'list';
          label: string;
          value: Array<string>;
        }
    >
  >;
  sdgGoals?: Array<{
    id: string;
    name: string;
  }>;
  proponents?: Array<
    Array<
      Array<{
        label: string;
        value: string;
      }>
    >
  >;
  documents?: Array<
    Array<{
      label: string;
      name: string;
      url: string;
    }>
  >;
}

export const ProjectInformation: React.FC<ProjectInformationProps> = (props) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { id, status, description, projectDetails, sdgGoals = [], proponents = [], documents = [] } = props;
  const onDownloadDocument = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    options: {
      url: string;
      name: string;
    },
  ) => {
    e.preventDefault();
    const authToken = await getAuthToken();

    fetch(options.url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', options.name);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  return (
    <StyledProjectInformation>
      <Layer>
        <StyledCard
          header={
            <SectionHeader
              suffix={[
                <Badge key={'id'} value={`ID: #${id}`} />,
                !!status && <BadgeProjectStatus status={status} key={'status'} />,
              ]}
            >
              Project Information
            </SectionHeader>
          }
        >
          {!!description && (
            <Text variant={TypographyVariant.body1} color={TextColor.secondary}>
              {description}
            </Text>
          )}
          <DetailsList>
            {projectDetails.map((column, index) => (
              <DetailsColumn key={index}>
                {column.map((detail, index) => {
                  if (detail.type === 'country') {
                    return (
                      <LabelWithDescription
                        key={index}
                        size={LabelWithDescriptionSize.s}
                        label={detail.label}
                        description={detail.value.name}
                        descriptionSuffix={
                          <CountryFlag src={`/assets/country-flags/${detail.value.code}.svg`} alt={detail.value.name} />
                        }
                      />
                    );
                  }

                  if (detail.type === 'item') {
                    return (
                      <LabelWithDescription
                        size={LabelWithDescriptionSize.s}
                        key={index}
                        label={detail.label}
                        description={detail.value}
                      />
                    );
                  }

                  if (detail.type === 'list') {
                    return (
                      <LabelWithDescription
                        label={detail.label}
                        size={LabelWithDescriptionSize.s}
                        key={index}
                        description={
                          !!detail.value.length ? (
                            <UnorderedList>
                              {detail.value.map((item, index) => (
                                <li key={index}>
                                  <Text variant={TypographyVariant.subtitle1}>{item}</Text>
                                </li>
                              ))}
                            </UnorderedList>
                          ) : (
                            '-'
                          )
                        }
                      />
                    );
                  }
                })}
              </DetailsColumn>
            ))}
          </DetailsList>
          {!!sdgGoals.length && (
            <>
              <Divider />
              <SectionHeader>SDG Goals:</SectionHeader>
              <SDGGoals>
                {sdgGoals.map((goal, index) => (
                  <img key={index} src={`/assets/sdg-goals/sdg-${goal.id}-white.png`} />
                ))}
              </SDGGoals>
            </>
          )}
          {!!proponents.length && (
            <>
              <Divider />
              <SectionHeader>Proponents</SectionHeader>
              {proponents.map((proponent, index) => (
                <DetailsList key={index}>
                  {proponent.map((column, index) => (
                    <DetailsColumn key={index}>
                      {column.map((row, index) => (
                        <LabelWithDescription
                          size={LabelWithDescriptionSize.s}
                          key={index}
                          label={row.label}
                          description={row.value}
                        />
                      ))}
                    </DetailsColumn>
                  ))}
                </DetailsList>
              ))}
            </>
          )}
        </StyledCard>

        {!!documents.length && (
          <StyledCard header={<SectionHeader>Documents</SectionHeader>}>
            <DetailsList>
              {documents.map((column, index) => (
                <DetailsColumn key={index}>
                  {column.map((row, index) => (
                    <ProjectDocument
                      key={index}
                      label={row.label}
                      name={row.name}
                      href={row.url}
                      onPress={(e) => onDownloadDocument(e, row)}
                    />
                  ))}
                </DetailsColumn>
              ))}
            </DetailsList>
          </StyledCard>
        )}
      </Layer>
    </StyledProjectInformation>
  );
};

const StyledProjectInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`;

const CountryFlag = styled.img`
  width: 28px;
  display: inline-flex;
`;

const StyledCard = styled(Card)`
  height: auto;
  flex: 1 0 auto;
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(6)};
`;

const DetailsList = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  @media (max-width: 1367px) {
    flex-direction: column;
  }
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const UnorderedList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;

  & li > div {
    display: inline;
    line-height: 24px;
  }
`;

const SDGGoals = styled.div`
  display: flex;
  margin-inline-start: -12px;

  & > * {
    width: 72px;
  }
  & > *:not(:first-child) {
    margin-inline-start: -16px;
  }
`;
