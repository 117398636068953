import { ErrorBoundary } from '@sentry/react';
import ReactDOM from 'react-dom';

import ErrorBoundaryHandler from 'components/ErrorBoundaryHandler';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { setupResizeObserverError } from './utils/resize-observer';

// Mock API on local development
if (process.env.NODE_ENV === 'development' && process.env.WEB_MOCK_API === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

setupResizeObserverError();

ReactDOM.render(
  <ErrorBoundary fallback={<ErrorBoundaryHandler />}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
