import { LabelWithDescription } from '@aircarbon/ui';

type InformationItemProps = {
  label: string;
  value?: string;
};

export const InformationItem: React.FC<InformationItemProps> = (props) => {
  const { label, value = '-' } = props;
  return <LabelWithDescription label={label} description={value} />;
};
