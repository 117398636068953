import type { getBrandEntity } from '@aircarbon/utils-server-db-orm';

export * from './page';
export * from './mutation';
export * from './role';
export * from './roleCategroy';
export * from './adminUser';
export * from './trading';
export * from './apx';
export * from './project';
export * from './user';
export * from './carbon';
export * from './transaction';
export * from './order';
export * from './pair';
export * from './entity';
export * from './market';
export * from './ledger-explorer';
export * from './contract';
export * from './report';
export * from './asset';
export * from './retokenize';
export * from './account';
export * from './fiat';
export * from './projectRegistry';
export * as ProjectRegistryIntegration from './projectRegistryIntegration';
export * from './queueMessage';
export * from './market-board';
export * from './assetDeposit';
export * from './notificationSubscription';
export * from './indexer';
export * from './common';

export type EntityDto = Awaited<ReturnType<typeof getBrandEntity>>;
export interface DBLinkedUser {
  first_name: string;
  last_name: string;
  is_member: number;
  is_parent_member: number;
  cynopsis_full_name: string;
  account: string;
  user_id: number;
  parent_id: number;
  user_name: string;
  email: string;
  phone: string;
  cognito_id: string;
  status: string;
  role: number;
  trading_name: string;
  kyc_status: string;
  account_type: string;
  allow_multi_access: boolean;
}
