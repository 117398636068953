import { type ChangeEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';

import { InputText, InputTextType } from '@aircarbon/ui';
import { hooks } from '@aircarbon/utils-common';

import { toOfferedQuantity } from './utils/toOfferedQuantity';
import { toOfferedQuantityPercentage } from './utils/toOfferedQuantityPercentage';

export const OfferedQuantity: React.FC<{
  estimatedQuantity: string;
  offeredQuantity: string;
  maximumOfferedQuantityPercentage: number;
  onChange(offeredQuantity: string): void;
}> = (props) => {
  const { estimatedQuantity, offeredQuantity, maximumOfferedQuantityPercentage, onChange } = props;
  const prevEstimatedQuantity = hooks.usePrevious(estimatedQuantity);
  const [percentage, setPercentage] = useState(
    toOfferedQuantityPercentage({
      estimatedQuantity,
      offeredQuantity,
      maximumOfferedQuantityPercentage,
    }),
  );

  useEffect(() => {
    if (prevEstimatedQuantity === undefined || prevEstimatedQuantity === estimatedQuantity) {
      return;
    }

    onChange(
      toOfferedQuantity({
        percentage,
        estimatedQuantity,
      }),
    );
  }, [prevEstimatedQuantity, estimatedQuantity, percentage]);

  const onChangeOfferedQuantity: ChangeEventHandler<HTMLInputElement> = (e) => {
    const maximumOfferedQuantity = toOfferedQuantity({
      percentage: String(maximumOfferedQuantityPercentage),
      estimatedQuantity: estimatedQuantity,
    });

    if (Number(maximumOfferedQuantity) < Number(e.target.value)) {
      return;
    }

    setPercentage(
      toOfferedQuantityPercentage({
        estimatedQuantity,
        offeredQuantity: e.target.value,
        maximumOfferedQuantityPercentage,
      }),
    );
    onChange(e.target.value);
  };

  const onChangePercentage: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newPercentage = Number(e.target.value);

    if (isNaN(newPercentage) || newPercentage < 0) {
      setPercentage('');
      return;
    }

    if (newPercentage > maximumOfferedQuantityPercentage) {
      return;
    }

    setPercentage(e.target.value);
    onChange(
      toOfferedQuantity({
        percentage: e.target.value,
        estimatedQuantity,
      }),
    );
  };

  return (
    <OfferedQuantityContainer style={{ minWidth: '300px' }}>
      <StyledPercentageInput
        suffix={'%'}
        onChange={onChangePercentage}
        value={percentage}
        type={InputTextType.Number}
        min="0"
      />
      <InputText
        suffix="tCO2e"
        value={offeredQuantity}
        onChange={onChangeOfferedQuantity}
        type={InputTextType.Number}
        placeholder="0"
        min="0"
      />
    </OfferedQuantityContainer>
  );
};

const OfferedQuantityContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledPercentageInput = styled(InputText)`
  max-width: 120px;
`;
