export interface Transaction {
  id: number;
  txHash: string;
  txStatus: string;
  exchangeTxType: string;
  txFromAccountAddress: string;
  txToAccountAddress: string;
  createdAt: Date | string;
  confirmedAt?: Date | string;
}

export interface TransactionResponse {
  items: Transaction[];
  total: number;
  totalPage: number;
}

export enum ExchangeTxType {
  OtcOrderTrade = 'TRADE_MB',
  BlockOrderTrade = 'TRADE_BLOCK_ORDER',
  OtcOrderDeposit = 'OTC_ORDER_DEPOSIT',
  Burn = 'burn',
  TradeExchange = 'trade-exchange',
}
