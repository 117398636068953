import type React from 'react';
import { Step, StepStatus } from 'refreshed-component/atoms/Step';
import styled from 'styled-components';

const StyledStepper = styled.div<{ $itemsCount: number }>`
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(${({ $itemsCount }) => $itemsCount}, 1fr);
  overflow-x: auto;
  > * {
    cursor: pointer;
    flex-shrink: 0;
  }
`;

type Props = {
  className?: string;
  steps: Array<{
    label: string;
    value: string;
    status: StepStatus;
  }>;
  onPressStep(stepIndex: number): void;
};

export const Stepper: React.FC<Props> = (props) => {
  const { steps = [], className, onPressStep } = props;

  return (
    <StyledStepper className={className} $itemsCount={steps.length}>
      {steps.map((step, index) => (
        <Step
          key={index}
          onPress={step.status !== StepStatus.Next ? () => onPressStep(index) : undefined}
          status={step.status}
          hasEndLine={index !== steps.length - 1}
          hasStartLine={index !== 0}
        >
          {step.label}
        </Step>
      ))}
    </StyledStepper>
  );
};
