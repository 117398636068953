// eslint-disable-next-line import/prefer-default-export
export enum FeatureToggle {
  MarketBoardAndAuctionsV2SettlementMode = 'market_board_and_auctions_v2_otc_settlement_mode',
  MarketBoardAndAuctionsV2 = 'feature_market_board_and_auctions_v2',
  MarketBoardAndAuctions = 'feature_market_board_and_auctions',
  Biofuel = 'feature_biofuel',
  GER = 'feature_ger',
  CashDeposit = 'feature_cash_deposit',
  CashWithdraw = 'feature_cash_withdraw',
  Delivery = 'feature_delivery',
  Retirement = 'feature_retirement',
  SpotCarbon = 'feature_spot_carbon',
  BlockTrade = 'feature_block_trade',
  CarbonFinder = 'feature_carbon_finder',
  RecSpot = 'feature_rec_spot',
  RecTokenBurn = 'feature_rec_token_burn',
  RecMarketBoardAndAuctions = 'feature_rec_market_board_and_auctions',
  RecMarketBoardAndAuctionsV2 = 'feature_rec_market_board_and_auctions_v2',
  RecBlockTrade = 'feature_rec_block_trade',
  RecSettle = 'feature_rec_settle',
  Apx = 'feature_apx',
  Settle = 'feature_settle',
  Report = 'feature_report',
  CurrentBalanceReport = 'feature_current_balance_report',
  AssetReport = 'feature_asset_report',
  SpotOrdersReport = 'feature_spot_orders_report',
  TradesReport = 'feature_trades_report',
  TransfersReport = 'feature_transfers_report',
  AuctionV2 = 'feature_auctions_v2',
  AssetDeposit = 'feature_asset_deposit',
  NotificationSubscription = 'feature_notification_subscription',
  DelayedBiofuel = 'feature_delayed_biofuel',
  DelayedCarbon = 'feature_delayed_carbon',
  DelayedRec = 'feature_delayed_rec',
}
