import { useMemo, useState } from 'react';
import * as yup from 'yup';

import { IconName, InputText, Modal, Select, ToastVariant, showToast, styled, toSpacing } from '@aircarbon/ui';
import { AssetCategory, type Dto } from '@aircarbon/utils-common';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { FieldsGroup } from '../FieldsGroup';
import { createProject } from './utils/createProject';

const validator = yup.object().shape({
  name: yup.string().required('Please provide Project Name'),
  registry: yup.string().required('Please select Registry'),
  registryProjectId: yup.string().required('Please provide Registry Project ID'),
});

interface FormValue {
  name: string;
  registry: string;
  registryProjectId: string;
}
export const CreateNewProjectModal: React.FunctionComponent<{
  isVisible: boolean;
  onProjectCreated(project: {
    id: string;
    name: string;
    description: string;
    registryProjectId: string;
    status: Dto.ProjectStatus;
  }): void;
  onClose(): void;
}> = (props) => {
  const { isVisible, onClose, onProjectCreated } = props;
  const [value, setValue] = useState<FormValue>({
    name: '',
    registry: '',
    registryProjectId: '',
  });

  const { product } = useMarketplaceProduct();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    carbonMetaOptions: { registries },
  } = useCarbonMetaOptions({
    query: {
      assetCategory: AssetCategory[Number(product)],
    },
  });

  const availableRegistries: Array<{
    title: string;
    value: string;
  }> = useMemo(
    () =>
      registries?.map((registry) => ({
        title: String(registry.registryName),
        value: String(registry.id),
      })) ?? [],
    [registries],
  );

  const changeFormValue =
    <F extends keyof FormValue>(field: F) =>
    (value: FormValue[F]) => {
      setDirtyFields((prevDirtyFields) => ({
        ...prevDirtyFields,
        [field]: true,
      }));
      setValue((prevValue) => ({ ...prevValue, [field]: value }));
    };

  const onPressSubmitForReview = async () => {
    setIsSubmitting(true);
    setDirtyFields({});
    setErrors({});
    try {
      validator.validateSync(value, {
        abortEarly: false,
      });
    } catch (e) {
      setErrors(
        (e as yup.ValidationError).inner.reduce((acc, curr) => ({ ...acc, [curr.path as string]: curr.message }), {}),
      );
      setIsSubmitting(false);
      return;
    }

    const result = await createProject({
      registryId: Number(value.registry),
      registryProjectId: value.registryProjectId,
      assetCategory: AssetCategory[Number(product)],
      projectName: value.name,
    });

    setIsSubmitting(false);

    if (result.status === 'error') {
      showToast({
        variant: ToastVariant.Danger,
        message: result.error,
      });
      return;
    }

    onProjectCreated({
      id: String(result.data.id),
      name: result.data.name,
      description: [result.data.__registry__.registryName, `ID: ${result.data.registryProjectId}`]
        .filter((v) => !!v)
        .join(' | '),
      registryProjectId: result.data.registryProjectId,
      status: result.data.status,
    });
  };

  const onCloseModal = () => {
    if (isSubmitting) {
      return;
    }

    onClose();
  };

  return (
    <Modal
      title="List a New Project"
      isVisible={isVisible}
      positiveButtonText="Submit for Review"
      positiveButtonIcon={IconName.ArrowEnd}
      onClose={onCloseModal}
      onPressPositiveButton={onPressSubmitForReview}
      isPositiveButtonLoading={isSubmitting}
    >
      <Content>
        <InputText
          label={'Project Name'}
          value={value.name}
          error={!dirtyFields.name ? errors.name : undefined}
          onChange={(e) => changeFormValue('name')(e.target.value)}
          description="Please enter name exactly as it is on the carbon registry"
        />

        <FieldsGroup>
          <Select
            label="Registry"
            items={availableRegistries}
            placeholder="Select Registry"
            value={value.registry}
            onChange={({ value }) => changeFormValue('registry')(value)}
            error={!dirtyFields.registry ? errors.registry : undefined}
          />
          <InputText
            label={'Registry Project ID'}
            placeholder="Registry Project ID"
            value={value.registryProjectId}
            onChange={(e) => changeFormValue('registryProjectId')(e.target.value)}
            error={!dirtyFields.registryProjectId ? errors.registryProjectId : undefined}
          />
        </FieldsGroup>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
