import { format } from 'date-fns';
import { Radius } from 'refreshed-component/design-system';
import { DetailedCardBodyGrid } from 'refreshed-component/templates/DetailedCard';
import styled from 'styled-components';

import { Link, LinkColor, Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import { Dto, formatter, projects as projectUtils } from '@aircarbon/utils-common';

import useCarbonProjects from 'hooks/useCarbonProjects';

import { getFileName, getIpfsHash, ipfsLink } from 'utils/helpers';

import { ProjectRatingBadge } from './ProjectRatingBadge';
import useProjectRatingDisclaimers from './hooks/useProjectRatingDisclaimers';

const SgdGoalImage = styled.div`
  width: 48px;
  height: 48px;
  background-size: contain;
  border-radius: var(${Radius.small});
`;

const ignoreMetaKeys = [
  'TXT_PROJECT_NAME',
  'TXT_REGISTRY',
  'LIST_COUNTRY',
  'vintage',
  'countryName',
  'TXT_MINTED_SERIAL_BLOCK',
  'TXT_VERIFIED_SERIAL_BLOCK',
  'TXT_PROJECT_DEVELOPER',
  'TXT_PROJECT_ID',
  'TXT_MINTED_SERIAL_START',
  'TXT_MINTED_SERIAL_END',
  'TXT_VINTAGE_YEAR',
  'DATE_VINTAGE_START',
  'DATE_VINTAGE_END',
  'TXT_BEZERO_RATING',
  'TXT_BEZERO_PROJECT_ID',
  'TXT_BEZERO_RATING_STATUS',
  'LIST_SDG_CONTRIBUTIONS',
];

export const ProjectMetadata = ({ projectMetadata, uom = 'MWh' }: { projectMetadata: any; uom?: string }) => {
  const { projects } = useCarbonProjects({
    isFilterLike: false,
    filterBy: { registryProjectId: projectMetadata?.TXT_PROJECT_ID },
    searchBy: projectMetadata?.TXT_REGISTRY,
    options: { enabled: !!projectMetadata?.TXT_PROJECT_ID && !!projectMetadata?.TXT_REGISTRY },
  });

  let projectDocumentsKeys: string[] = [];
  let projectUrlsKeys: string[] = [];
  let projectRestKeys: string[] = [];
  if (projectMetadata) {
    projectDocumentsKeys = projectMetadata
      ? Object.keys(projectMetadata)?.filter((key: string) => key.includes('IPFS'))
      : [];
    projectUrlsKeys = projectMetadata ? Object.keys(projectMetadata)?.filter((key: string) => key.includes('URL')) : [];
    projectRestKeys = Object.keys(projectMetadata).filter(
      (key: string) =>
        !projectDocumentsKeys.includes(key) && !ignoreMetaKeys.includes(key) && !projectUrlsKeys.includes(key),
    );
  }

  const { bezeroDisclaimerUrls, sylveraDisclaimerUrls } = useProjectRatingDisclaimers();

  const project = projects?.length === 1 ? projects[0] : undefined;
  const bezeroRating = project?.__ratings__?.find(
    (r: any) => r.ratingType === Dto.ProjectRatingType.BezeroCarbonRating,
  )?.rating;
  const sylveraRating = project?.__ratings__?.find(
    (r: any) => r.ratingType === Dto.ProjectRatingType.SylveraRatingCategory,
  )?.rating;

  const shouldShowDetailsSection = !![bezeroRating, sylveraRating, project?.carbonProjectSdgGoals?.length].filter(
    (v) => !!v,
  )?.length;

  return (
    <>
      <DetailedCardBodyGrid
        imgUrl={projectMetadata?.URL_PROJECT_IMG}
        list={[
          {
            title: 'Registry',
            description: projectMetadata?.TXT_REGISTRY,
          },
          {
            title: 'Registry Project ID',
            description: project?.registryProjectUrl ? (
              <Link
                href={project?.registryProjectUrl}
                target="_blank"
                rel="noopener noreferrer"
                color={LinkColor.Primary}
              >
                {projectMetadata?.TXT_PROJECT_ID}
              </Link>
            ) : (
              projectMetadata?.TXT_PROJECT_ID
            ),
          },
          projectMetadata?.vintage && {
            title: 'Vintage',
            description: projectMetadata?.vintage,
          },
          projectMetadata?.LIST_COUNTRY && {
            title: 'Country',
            description:
              projectUtils?.countryNames[String(projectMetadata?.LIST_COUNTRY)] ?? projectMetadata?.LIST_COUNTRY,
          },
          ...projectRestKeys.map((key) => ({
            title: projectUtils?.projectLabel[key] || key,
            description: projectUtils?.getProjectMetaValueLabel(key, projectMetadata[key]),
          })),
          ...projectDocumentsKeys.map((key) => ({
            title: projectUtils?.projectLabel[key] || key,
            description: (
              <Link
                href={ipfsLink(getIpfsHash(projectMetadata[key]))}
                target="_blank"
                rel="noopener noreferrer"
                color={LinkColor.Primary}
              >
                {getFileName(projectMetadata[key]) || '-'}
              </Link>
            ),
          })),
          !!project?.address && {
            title: 'Address',
            description: project?.address,
          },
          !!project?.commissioningDate && {
            title: 'Commissioning Date',
            description: format(new Date(project?.commissioningDate), 'dd MMM yyyy'),
          },
          !!project?.registrationDate && {
            title: 'Registration Date',
            description: format(new Date(project?.registrationDate), 'dd MMM yyyy'),
          },
          !!project?.expiraryDate && {
            title: 'Expirary Date',
            description: format(new Date(project?.expiraryDate), 'dd MMM yyyy'),
          },
          !!project?.primaryFuel && {
            title: 'Primary Fuel',
            description: project?.primaryFuel,
          },
          !!project?.fuelCode && {
            title: 'Fuel Code',
            description: project?.fuelCode,
          },
          !!project?.technology && {
            title: 'Technology',
            description: project?.technology,
          },
          !!project?.capacity && {
            title: 'Capacity (MW)',
            description: formatter.formatNumber(project.capacity, 6),
          },
          !!project?.volumeMwh && {
            title: `Volume (${uom})`,
            description: formatter.formatNumber(project.volumeMwh, 6),
          },
          project?.isSupportedByGovSubsidies !== undefined && {
            title: 'Supported by Government Subsidies',
            description: project?.isSupportedByGovSubsidies ? 'Yes' : 'No',
          },
          project?.worldMapLink && {
            title: 'Seagrass Carbon Map',
            description: (
              <Link href={project.worldMapLink} target="_blank" rel="noopener noreferrer" color={LinkColor.Primary}>
                Project Link
              </Link>
            ),
          },
        ]}
      />
      {shouldShowDetailsSection && (
        <div className="details">
          {!!project?.carbonProjectSdgGoals?.length && (
            <div className="item">
              <Text variant={TypographyVariant.subtitle2}>SDG Goals</Text>
              <div className="flex flex-row flex-wrap flex-1 w-full gap-base">
                {project?.carbonProjectSdgGoals?.map(({ carbonSdgGoalId }) => (
                  <SgdGoalImage
                    key={`sdg-icon-${carbonSdgGoalId}`}
                    style={{
                      backgroundImage: `url(/sdg_icons/E-WEB-Goal-${carbonSdgGoalId}.png)`,
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {bezeroRating && (
            <div className="item">
              <ProjectRatingBadge
                projectRating={bezeroRating}
                urls={bezeroDisclaimerUrls}
                ratingType={Dto.ProjectRatingType.BezeroCarbonRating}
                className="gap-xs"
                title={{
                  color: TextColor.primary,
                }}
              />
            </div>
          )}
          {sylveraRating && (
            <div className="item">
              <ProjectRatingBadge
                projectRating={sylveraRating}
                urls={sylveraDisclaimerUrls}
                ratingType={Dto.ProjectRatingType.SylveraRatingCategory}
                className="gap-xs"
                title={{
                  color: TextColor.primary,
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
