import { useCallback, useEffect, useMemo, useState } from 'react';

import { hooks } from '@aircarbon/utils-common';

import type { Vintage } from '../utils/Vintage';
import { toEndYear } from '../utils/toEndYear';
import { toYears } from '../utils/toEndYears';
import { toStartYear } from '../utils/toStartYear';
import { toStartYears } from '../utils/toStartYears';
import { toVintagesCount } from '../utils/toVintagesCount';

const maximumVintagesCount = 10;

export const useIssuanceYears = ({
  implementationDate,
  prevVintages,
}: {
  implementationDate: string;
  prevVintages: Array<Vintage>;
}) => {
  const [startYear, setStartYear] = useState(toInitialStartYear(prevVintages));
  const [endYear, setEndYear] = useState(toInitialEndYear(prevVintages));
  const [vintagesCount, setVintagesCount] = useState(toInitialVintagesCount(startYear, endYear));
  const previousVintagesCount = hooks.usePrevious(vintagesCount);
  const [startYears, setStartYears] = useState<
    Array<{
      value: string;
      title: string;
    }>
  >(
    toStartYears({
      implementationDate,
      maximumYearsCount: maximumVintagesCount,
    }),
  );
  const [endYears, setEndYears] = useState<
    Array<{
      value: string;
      title: string;
    }>
  >(
    startYear
      ? toYears({
          startYear: Number(startYear),
          maximumYearsCount: maximumVintagesCount,
        })
      : [],
  );

  useEffect(() => {
    setStartYears(
      toStartYears({
        implementationDate,
        maximumYearsCount: maximumVintagesCount,
      }),
    );
  }, [implementationDate]);
  useEffect(() => {
    setEndYears(
      startYear
        ? toYears({
            startYear: Number(startYear),
            maximumYearsCount: maximumVintagesCount,
          })
        : [],
    );
  }, [startYear]);
  useEffect(() => {
    setStartYear(
      toStartYear({
        startYears,
        startYear,
      }),
    );
  }, [startYears, startYear]);
  useEffect(() => {
    setEndYear(
      toEndYear({
        endYear,
        endYears,
      }),
    );
  }, [endYears, endYear]);
  useEffect(() => {
    setVintagesCount(
      toVintagesCount({
        startYear,
        endYear,
      }),
    );
  }, [startYear, endYear]);
  useEffect(() => {
    if (
      vintagesCount === previousVintagesCount ||
      !vintagesCount ||
      Number(vintagesCount) <= 0 ||
      Number(vintagesCount) > maximumVintagesCount ||
      !startYear
    ) {
      return;
    }
    const newEndYear = '' + (Number(startYear) + Number(vintagesCount) - 1);
    setEndYear(newEndYear);
  }, [startYear, endYear, vintagesCount]);

  const onChangeStartYear = useCallback((startYear: string) => {
    setStartYear(startYear);
  }, []);
  const onChangeEndYear = useCallback((endYear: string) => {
    setEndYear(endYear);
  }, []);
  const onChangeVintagesCount = useCallback((count: string) => {
    if (Number(count) > maximumVintagesCount) {
      return;
    }
    setVintagesCount(count);
  }, []);

  const result = useMemo(
    () => ({
      startYear,
      endYear,
      vintagesCount,
      startYears,
      endYears,
      onChangeStartYear,
      onChangeEndYear,
      onChangeVintagesCount,
    }),
    [
      startYear,
      endYear,
      vintagesCount,
      startYears,
      endYears,
      onChangeStartYear,
      onChangeEndYear,
      onChangeVintagesCount,
    ],
  );

  return result;
};

const toInitialStartYear = (prevVintages: Array<Vintage>) => {
  if (!prevVintages.length) {
    return '';
  }

  return String(Math.min(...prevVintages.map((vintage) => Number(vintage.year))) || '');
};

const toInitialEndYear = (prevVintages: Array<Vintage>) => {
  if (!prevVintages.length) {
    return '';
  }

  return String(Math.max(...prevVintages.map((vintage) => Number(vintage.year))) || '');
};

const toInitialVintagesCount = (startYear: string, endYear: string) => {
  if (!startYear || !endYear) {
    return '';
  }

  return String(Number(endYear) - Number(startYear) + 1 || '');
};
