import { Text } from 'refreshed-component/atoms/Text';
import { Colors, CssTheme, FontSize, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Button, ButtonVariant } from '@aircarbon/ui';
import { helpers } from '@aircarbon/utils-common';

import PageNotFound from 'pages/PageNotFound';
import { Themes } from 'pages/account/trading/components/Themes';

import { Entity } from 'state/entity';

import { NotFoundError } from 'utils/NotFoundError';

import { Footer } from './Footer';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  align-items: stretch;
  > .controls {
    width: 560px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    display: flex;
    > .children {
      flex: 1 1 0;
      position: relative;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      > .info {
        position: relative;
        max-width: 360px;
        display: flex;
        flex-direction: column;
        gap: var(${Spacing.large});
      }
    }
  }
  > .brand-image {
    width: auto;
    height: auto;
    flex: 1 0 0;
    background-color: var(${Colors.gray_0});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const ThemedErrorHandler = (props: { error?: Error }) => {
  const { entity } = Entity.useContainer();
  const { themeMode } = Themes.useContainer();

  if (props.error instanceof NotFoundError) {
    return <PageNotFound />;
  }

  return (
    <CssTheme>
      <Wrapper>
        <div className="controls">
          <div className="flex flex-row justify-start p-large">
            <div
              className="flex-1"
              style={{
                maxHeight: '64px',
              }}
            >
              <img
                style={{
                  height: '100%',
                }}
                src={themeMode === 'dark' ? entity.theme.web.logoSidebarDark : entity.theme.web.logoSidebarLight}
              />
            </div>
          </div>
          <div className="children">
            <div className="info">
              <Text color={Colors.gray_900} size={FontSize._2Xl}>
                Something went wrong
              </Text>
              <Text color={Colors.gray_500}>The page you are looking for doesn’t exist or has been removed.</Text>
              <div className="flex flex-row gap-medium">
                <Button
                  variant={ButtonVariant.secondary}
                  className="flex-1"
                  onPress={() => {
                    window.location.reload();
                  }}
                >
                  Reload Page
                </Button>
                <Button
                  variant={ButtonVariant.outlined}
                  onPress={() => {
                    window.open(`mailto:support@${helpers.getTopLevelDomainByUrl(window.location.href)}`);
                  }}
                  className="flex-1"
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>
          <div className="p-small">
            <Footer notLogged={true} />
          </div>
        </div>
        <div
          className="brand-image"
          style={{
            backgroundImage: `url(${
              themeMode === 'dark' ? entity.theme.web.errorPageImageDark : entity.theme.web.errorPageImageLight
            })`,
          }}
        />
      </Wrapper>
    </CssTheme>
  );
};
