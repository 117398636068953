import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import type { Project } from 'refreshed-pages/market-board-v2/utils/Project';
import { toVintageYears } from 'refreshed-pages/market-board-v2/utils/toVintageYears';
import * as yup from 'yup';

import { Badge, BadgeSize, Select, Tooltip, styled } from '@aircarbon/ui';
import { AssetCategory, Const, Dto } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { FieldsGroup } from '../FieldsGroup';
import { SelectProject } from '../SelectProject';

const getVintageBalancePerAsset = (project: Project, vintage: string) => {
  let totalBalance = 0;
  const balancePerAsset = project?.balances?.reduce(
    (assets: { [key: string]: { assetId: number; quantity: number } }, entry) => {
      if (!assets[entry.assetId]) {
        assets[entry.assetName] = { assetId: entry.assetId, quantity: 0 };
      }

      if (entry.vintage === Number(vintage)) {
        assets[entry.assetName].quantity += entry.qty;
        totalBalance += entry.qty;
      }
      return assets;
    },
    {},
  );

  return { totalBalance, balancePerAsset };
};

export interface SelectProjectAndVintageRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

const validator = yup.object().shape({
  project: yup.object().shape({
    value: yup.string().required('Please select project'),
    title: yup.string().required(),
    description: yup.string().required(),
    status: yup
      .string()
      .required()
      .when('$shouldOnlyAcceptActiveProjects', {
        is: (v: boolean) => v,
        then: (s) =>
          s.equals(
            [Dto.ProjectStatus.Active],
            'This project is still under review. You will be able to place the offer once project is approved',
          ),
        otherwise: (s) => s,
      }),
  }),
  vintage: yup.string().required('Please select vintage'),
});

interface SelectProjectAndVintageProps {
  project?: Project;
  isReadOnly?: boolean;
  projectLabel?: string;
  shouldOnlyAcceptActiveProjects?: boolean;
  vintage: string;
  ownedByUserId: number;
  selectProjectFilterCriteria?: Array<{
    key: string;
    operator: string;
    values: Array<string>;
  }>;
  availableVintages?: Array<string>;
  onChange(props: { project?: Project; vintage: string }): void;
  onPressCreateNewProject?(): void;
}

export const SelectProjectAndVintage = forwardRef<SelectProjectAndVintageRef, SelectProjectAndVintageProps>(
  (props, ref) => {
    const {
      project,
      vintage,
      ownedByUserId,
      onPressCreateNewProject,
      isReadOnly,
      onChange,
      projectLabel = 'Project',
      availableVintages,
      selectProjectFilterCriteria,
      shouldOnlyAcceptActiveProjects,
    } = props;
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});
    const { getSetting } = UI.useContainer();

    const { product } = useMarketplaceProduct();

    const vintagesList = useMemo(() => {
      if (product === String(AssetCategory.fct)) {
        return (
          project?.balances?.map((balance) => ({
            title: '' + balance.vintage,
            value: '' + balance.vintage,
          })) ?? []
        );
        // only show vintage years that are available for the project
      }
      return toVintageYears({
        include: availableVintages?.map((v) => Number(v)),
      }).map((item) => {
        const { totalBalance } = getVintageBalancePerAsset(project!, item.value);

        if (!totalBalance) {
          return item;
        }

        return {
          ...item,
          suffix: (
            <Tooltip value="You have available assets of this vintage">
              <Badge size={BadgeSize.s} value="avail." />
            </Tooltip>
          ),
        };
      });
    }, [availableVintages, project, product]);

    useImperativeHandle(
      ref,
      () => ({
        validate() {
          setDirtyFields({});
          try {
            validator.validateSync(
              {
                project,
                vintage,
              },
              {
                abortEarly: false,
                context: {
                  shouldOnlyAcceptActiveProjects,
                },
              },
            );
          } catch (e) {
            setErrors(
              (e as yup.ValidationError).inner.reduce(
                (acc, curr) => ({ ...acc, [curr.path as string]: curr.message }),
                {},
              ),
            );
            return true;
          }

          setErrors({});
          return false;
        },
      }),
      [project, vintage, shouldOnlyAcceptActiveProjects],
    );

    const isMarketBoardAndAuctionsV2SettlementModeEnabled =
      getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

    const onChangeSelectProject = (value?: Project) => {
      setDirtyFields((prevFields) => ({
        ...prevFields,
        project: true,
      }));

      onChange({
        project: value,
        vintage: vintagesList.find((v) => v.value === vintage) ? vintage : '',
      });
    };

    const onChangeVintage = ({ value }: { value: string }) => {
      const { totalBalance, balancePerAsset } = getVintageBalancePerAsset(project!, value);
      setDirtyFields((prevFields) => ({
        ...prevFields,
        vintage: true,
      }));
      onChange({
        project: { ...project!, vintageBalance: totalBalance, vintageBalancePerAsset: balancePerAsset },
        vintage: value,
      });
    };

    return (
      <StyledFieldsGroup columnMinWidth="14rem" $hasVintages={!!vintagesList.length}>
        <SelectProject
          isReadOnly={isReadOnly}
          isOwnedByMeFilterEnabled={!isMarketBoardAndAuctionsV2SettlementModeEnabled}
          ownedByUserId={ownedByUserId}
          onPressCreateNewProject={product === String(AssetCategory.fct) ? undefined : onPressCreateNewProject}
          value={project}
          error={!dirtyFields['project'] ? errors['project.value'] || errors['project.status'] : undefined}
          onChange={onChangeSelectProject}
          label={projectLabel}
          filterCriteria={selectProjectFilterCriteria}
        />
        {!!vintagesList.length && (
          <Select
            isDisabled={isReadOnly}
            label="Vintage"
            placeholder="Select"
            error={!dirtyFields.vintage ? errors.vintage : undefined}
            value={vintage}
            items={vintagesList}
            onChange={onChangeVintage}
          />
        )}
      </StyledFieldsGroup>
    );
  },
);

const StyledFieldsGroup = styled(FieldsGroup)<{
  $hasVintages: boolean;
}>`
  grid-template-columns: ${({ $hasVintages }) => ($hasVintages ? '1fr 11rem' : '1fr')};

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;
