import queryString from 'query-string';

import type { Dto } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from '../../../../../data-provider/fetcher';
import type { DepositRequestResource } from './DepositRequestResource';

export const fetchDepositRequests = async (props: {
  filters: {
    statuses: Array<Dto.AssetDepositStatus>;
    dateRange: string;
  };
  search: string;
  pagination: {
    page: number;
    limit: number;
  };
}) => {
  const { pagination, filters, search } = props;
  const [dateFrom, dateTo] = filters.dateRange.split(',');
  const endpoint = '/user/assets/deposits';
  const userService = await userAuthenticatedApi();
  const res = userService
    .url(endpoint)
    .query(
      queryString.stringify(
        {
          page: pagination.page,
          limit: pagination.limit,
          statuses: filters.statuses as any,
          search: search || undefined,
          dateFrom: dateFrom ? new Date(dateFrom).toISOString() : undefined,
          dateTo: dateTo ? new Date(dateTo).toISOString() : undefined,
        },
        {
          arrayFormat: 'bracket',
        },
      ),
    )
    .get();

  return res.json<{
    data: {
      data: DepositRequestResource[];
      totalPages: number;
      totalCount: number;
    };
  }>();
};
