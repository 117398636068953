/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetApxProjectsResponseDTO } from '../models/GetApxProjectsResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ApxService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns data.
     * @returns GetApxProjectsResponseDTO Successful operation
     * @throws ApiError
     */
    public getApxProjects({
        xEntityDomain,
        filter,
        page,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        filter?: {
            /**
             * Project types
             */
            'project-types'?: Array<string>;
            /**
             * Pair ids
             */
            'pair-ids'?: Array<string>;
            /**
             * Vintage
             */
            vintage?: {
                /**
                 * Vintage from
                 */
                from?: string;
                /**
                 * Vintage to
                 */
                to?: string;
            };
            /**
             * Sectoral scopes
             */
            'sectoral-scopes'?: Array<string>;
            /**
             * Status
             */
            status?: string;
            /**
             * User id
             */
            userId?: string;
            /**
             * Search
             */
            search?: {
                /**
                 * Search fields
                 */
                fields?: Array<string>;
                /**
                 * Search value
                 */
                value?: string;
            };
        },
        page?: {
            /**
             * Page number
             */
            number?: string;
            /**
             * Page size
             */
            size?: string;
        },
    }): CancelablePromise<GetApxProjectsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/apx/projects',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            query: {
                'filter': filter,
                'page': page,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
}
