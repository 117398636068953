import { LabelWithDescription, LabelWithDescriptionSize, styled, toSpacing } from '@aircarbon/ui';

export const PeriodInformation: React.FC<{
  label: string;
  value?: string;
  className?: string;
}> = (props) => {
  const { label, value, className } = props;

  return (
    <StyledPeriodInformation className={className}>
      <LabelWithDescription size={LabelWithDescriptionSize.l} label={label} description={value ?? ''} />
    </StyledPeriodInformation>
  );
};

const StyledPeriodInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${toSpacing(theme)(6)} ${toSpacing(theme)(12)}`};
  width: 100%;
`;
