import {
  BorderColor,
  Card,
  CardVariant,
  Text,
  TypographyVariant,
  styled,
  toBorderColor,
  toSpacing,
} from '@aircarbon/ui';

import { PeriodInformation } from '../../../../components/PeriodInformation';
import { BiddingTable } from './components/BiddingTable';
import { MilestonesTable } from './components/MilestonesTable';

export const Sidebar: React.FC<{
  markets: Array<{
    id: string;
    token: string;
    bid: string;
    ask: string;
    last: string;
  }>;
  milestones: Array<[string, string, string]>;
  creditingPeriod: string;
  onPressAsset(assetSymbol: string): void;
}> = (props) => {
  const { milestones, markets, creditingPeriod = '-', onPressAsset } = props;

  return (
    <StyledSidebarCard
      variant={CardVariant.Bordered}
      header={<PeriodInformation label="Crediting Period" value={creditingPeriod} />}
    >
      {!!markets.length && <BiddingTable items={markets} onPressRow={onPressAsset} />}
      {!!milestones.length && (
        <>
          <StyledMilestonesText variant={TypographyVariant.h5Title}>Milestones</StyledMilestonesText>
          <MilestonesTable milestones={milestones} />
        </>
      )}
    </StyledSidebarCard>
  );
};

const StyledSidebarCard = styled(Card)`
  max-width: 374px;
  width: 100%;

  @media (max-width: 1367px) {
    max-width: 320px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

const StyledMilestonesText = styled(Text)`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  border-radius: 0;
`;
