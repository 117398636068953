import styled from 'styled-components';

import { LabelWithDescription, LabelWithDescriptionSize, toLayerBackground } from '@aircarbon/ui';

export const ProjectInformation: React.FC<{
  batchId: string;
  quantity: string;
}> = (props) => {
  return (
    <StyledProjectInformation>
      <LabelWithDescription label="Project Name" description={'GER'} size={LabelWithDescriptionSize.m} />
      <LabelWithDescription label="Batch ID" description={props.batchId} size={LabelWithDescriptionSize.m} />
      <LabelWithDescription label="Quantity (CET)" description={props.quantity} size={LabelWithDescriptionSize.m} />
    </StyledProjectInformation>
  );
};

export const StyledProjectInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
  margin-bottom: 16px;
`;
