import Countdown from 'refreshed-component/atoms/CountDown';
import { Empty } from 'refreshed-component/atoms/Empty';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import PlaceBid from 'refreshed-component/forms/PlaceBid';
import DownloadButton from 'refreshed-component/molecules/DownloadButton';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';

import { Button } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import type { CmbAsk } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useAvailableBalance from 'hooks/useAvailableBalance';
import useCurrencies from 'hooks/useCurrencies';

import { formatDate } from 'utils/helpers';

import { MarketPlaceProjectCard } from './MarketPlaceProjectCard';

export const MarketPlaceList = ({
  items,
  isLoading,
  refreshData,
}: {
  items: CmbAsk[];
  isLoading: boolean;
  refreshData: () => void;
}) => {
  const {
    status: { canBidAuction },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const asks = items ?? [];
  const genericAssetSymbol = getSetting('global_genericCarbonAssetSymbol');
  const depositGenericToken = getSetting('global_emb_depositGenericToken');
  const placeBidDeposit = getSetting('auction_placeBidDeposit') ?? 0;
  const acceptedBidDeposit = getSetting('auction_acceptedBidDeposit') ?? 0;
  const bidPriceMinPercentage = getSetting('global_emb_bidPriceMinPercentage') ?? 100;

  const {
    selector: { mainCcyScId },
  } = Entity.useContainer();

  const { balance } = useAvailableBalance({
    params: { ccyTypeId: mainCcyScId, getRoot: false },
  });
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  if (isLoading || isLoadingMarketSettings) {
    return (
      <div className="flex flex-col w-full gap-large">
        <div className="p-large">
          <Loading isOverLay={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-large">
      {asks && asks.length > 0 ? (
        asks.map((ask: CmbAsk) => {
          const carbonProject = (ask.__carbonProject__ as any | undefined) || {};
          const tokenAsset = (ask.__tokenAsset__ as any | undefined) || {};
          const tokenUnit = tokenAsset?.__uom__?.name ?? '';
          const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];

          return (
            <MarketPlaceProjectCard
              key={ask.id}
              info={{
                carbonProject,
                tokenAsset,
                id: `Auction #${ask.id}`,
                carbonProjectVintageYear: ask?.carbonProjectVintageYear,
                footer: ask?.auctionRulesFilename && (
                  <DownloadButton
                    url={`/api/user/carbon/auction-rules-file/${ask.id}`}
                    fileName={`acx-cmb-auction-rules-${ask.id}.pdf`}
                  >
                    Download Auction Rules
                  </DownloadButton>
                ),
              }}
              controls={
                <>
                  <div className="flex flex-row justify-between whitespace-pre gap-base">
                    <div className="flex flex-col">
                      <Text color={Colors.gray_500} size={FontSize.small}>
                        Available Qty
                      </Text>
                      <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                        {formatter.formatNumber(ask?.quantity, 0)}
                        <Text color={Colors.gray_500} size={null}>
                          {tokenUnit}
                        </Text>
                      </Text>
                    </div>
                    <div className="flex flex-col">
                      <Text color={Colors.gray_500} size={FontSize.small}>
                        Price (per {tokenUnit})
                      </Text>
                      <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                        {ccyAsset?.code}
                        {formatter.formatNumber(ask?.price, ccyAsset?.numDecimals)}{' '}
                        <Text color={Colors.gray_500} size={null}>
                          {ccyAsset?.symbol}
                        </Text>
                      </Text>
                    </div>
                  </div>
                  <div className="flex overflow-hidden flex-col justify-between whitespace-pre rounded-medium">
                    <div className="flex flex-col bg-gray_100 p-base">
                      <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.medium}>
                        Started on:{' '}
                        <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                          {formatDate(ask.startDate)}
                        </Text>
                      </Text>
                      <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.medium}>
                        Ends on:{' '}
                        <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                          {formatDate(ask.endDate)}
                        </Text>
                      </Text>
                    </div>
                    {ask.status === 'LIST' && (
                      <div className="flex flex-col text-gray_900 bg-gray_200 p-base">
                        <Countdown
                          date={new Date(ask.endDate)}
                          endLabel={'This auction has ended.'}
                          onZero={() => refreshData()}
                        />
                      </div>
                    )}
                  </div>
                  {canBidAuction() && (
                    <div className="flex flex-col gap-base">
                      <Modal
                        title={'Place Bid'}
                        action={
                          <Button
                            isDisabled={
                              ask.status !== 'LIST' ||
                              new Date(ask.startDate) > new Date() ||
                              new Date() > new Date(ask.endDate) ||
                              marketSettings?.otcEntryEnabled === 0
                            }
                            className="flex-1"
                          >
                            Place Bid
                          </Button>
                        }
                      >
                        {({ onClose, onLoading }) => {
                          const generalDepositPercentage =
                            Number(placeBidDeposit) > 0 ? placeBidDeposit : acceptedBidDeposit;
                          const isGenericAsset =
                            genericAssetSymbol && genericAssetSymbol === ask?.__tokenAsset__?.symbol;
                          const depositPercentage = isGenericAsset ? depositGenericToken : generalDepositPercentage;
                          const tokenAsset = ask?.__tokenAsset__;
                          const tokenUnit = tokenAsset?.__uom__?.name ?? '';
                          const minBid = ask?.price - (ask?.price * Number(bidPriceMinPercentage)) / 100;
                          return (
                            <PlaceBid
                              ask={ask}
                              tokenAssetCategoryId={tokenAsset?.assetCategoryId}
                              minQty={ask?.minBidQty}
                              maxQty={ask?.maxBidQty}
                              bidQtyMultiplier={ask?.bidQtyMultiplier}
                              minBid={minBid}
                              availableBalance={balance?.availableAmount ?? 0}
                              depositPercentage={Number(depositPercentage ?? 0)}
                              tokenUnit={tokenUnit}
                              onLoading={onLoading}
                              onSuccess={() => onClose()}
                            />
                          );
                        }}
                      </Modal>
                    </div>
                  )}
                </>
              }
            />
          );
        })
      ) : (
        <Empty title="No listings available" description="There are no auction listings available currently." />
      )}
    </div>
  );
};
