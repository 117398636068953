import type { MouseEventHandler } from 'react';

import {
  BorderColor,
  Icon,
  IconName,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
} from '@aircarbon/ui';

type ProjectDocumentProps = {
  label: string;
  name: string;
  href: string;
  onPress?: MouseEventHandler<HTMLAnchorElement>;
};

export const ProjectDocument: React.FC<ProjectDocumentProps> = (props) => {
  const { label, name, href, onPress } = props;

  return (
    <StyledProjectDocument>
      <Text variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
        {label}
      </Text>
      <FileLink href={href} target="_blank" download={name} onClick={onPress}>
        <Icon size="1.5rem" name={IconName.Download} />
        {name}
      </FileLink>
    </StyledProjectDocument>
  );
};

const StyledProjectDocument = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.active)};
  background-color: ${({ theme }) => toLayerBackground(theme)('field')};
  margin-top: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
