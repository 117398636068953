import { AssetCategory } from '@aircarbon/utils-common';

import type { DepositRequestResource } from './DepositRequestResource';

const AssetCategoryToNameMap: Record<AssetCategory, string> = {
  [AssetCategory.token]: 'Carbon',
  [AssetCategory.biofuel]: 'BioFuel',
  [AssetCategory.currency]: 'Cash',
  [AssetCategory.fct]: 'FCT',
  [AssetCategory.rec]: 'REC',
};

// eslint-disable-next-line import/prefer-default-export
export const toAssetCategoryName = (request: DepositRequestResource) =>
  AssetCategoryToNameMap[request.asset?.categoryId as unknown as AssetCategory] ?? 'Unknown';
