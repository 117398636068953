import 'bootstrap-daterangepicker/daterangepicker.css';
import format from 'date-fns/format';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { DownloadCSV } from 'refreshed-component/molecules/DownloadCSV';
import {
  type FilterDateRangeInput,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import { formatter, helpers } from '@aircarbon/utils-common';
import type { Asset } from '@aircarbon/utils-common/src/dto';

import { Account } from 'state/account';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';
import useTokenTypes from 'hooks/useTokenTypes';

const { WEB_INDEXER_API } = process.env;
const { serialize } = helpers;
const { formatCurrency, formatNumber } = formatter;

type TransactionItem = {
  blockNumber: number;
  from: {
    account: string;
    columnType: string;
    fee: number;
    formattedValue: string;
    symbol: string;
    value: number;
    valueType: string;
  };
  gasPrice: number;
  to: {
    account: string;
    columnType: string;
    fee: number;
    formattedValue: string;
    symbol: string;
    value: number;
    valueType: string;
  };
  tradePrice: number;
  txDate: string;
  txHash: string;
  txLabel: string;
  txType: 'fund' | 'withdraw' | 'mint' | 'burn' | 'currencyTransfer';
};

interface Transaction {
  data: Array<TransactionItem>;
}

const renderValue = (value: number | { _hex: string }) => {
  if (Number.isNaN(Number(value))) {
    return formatter.formatNumber(formatter.hex2int(value as any));
  }
  return formatter.formatNumber(value as number);
};

//TODO:
//case needed to differentiate between funds
//deposited from refund of rejected/cancelled withdrawal

function transformData({
  transactions,
  accountUsers,
  tokenTypes,
  currencies,
}: {
  transactions: Array<TransactionItem> | undefined;
  accountUsers: Array<Record<string, any>> | null;
  tokenTypes: Asset[];
  currencies: Asset[];
}): Array<{
  txHash: string;
  date: string;
  amount: number;
  assetType: string;
  columnType: string;
  formattedValue: string;
  accountName: string;
  isDebit: boolean;
  typeLabel: string;
} | null> {
  return (
    transactions
      ?.map((trade) => {
        const { from, to, txDate, txType, txLabel, txHash } = trade;
        const txTime = format(new Date(txDate), 'MMM d yyyy h:mm:ss a');
        const fromAccount = (accountUsers ?? []).find((user) => user.account === from.account);
        const toAccount = (accountUsers ?? []).find((user) => user.account === to.account);

        // Cannot be internal transfers between user's accounts.
        if (fromAccount && toAccount) return null;

        let isDebit = fromAccount ? true : false;

        let typeLabel = '';
        let formattedValue = '';
        let amount;
        let assetType = to.symbol;
        let account;
        let columnType = to.columnType;
        const toTokenType = tokenTypes.find((type) => type.symbol === to.symbol);
        const fromTokenType = tokenTypes.find((type) => type.symbol === from.symbol);
        const toCurrency = currencies.find((item) => item.symbol === to.symbol);
        const formCurrency = currencies.find((item) => item.symbol === from.symbol);

        switch (txType) {
          case 'fund':
            amount = to.value;
            typeLabel = 'Deposit';
            formattedValue = `${toCurrency?.code}${formatCurrency(amount, 2)}`;
            account = toAccount;
            columnType = to.columnType;
            break;

          case 'withdraw':
            amount = from.value;
            assetType = from.symbol;
            typeLabel = 'Withdraw';
            formattedValue = `${formCurrency?.code}${formatCurrency(amount, 2)}`;
            isDebit = true;
            account = fromAccount;
            columnType = from.columnType;
            break;

          case 'mint':
            amount = to.value;
            typeLabel = 'Deposit';
            formattedValue = toTokenType?.uom?.name
              ? `${formatNumber(amount, 0)} ${toTokenType?.uom?.name}`
              : renderValue(amount);
            account = toAccount;
            columnType = to.columnType;
            break;

          case 'currencyTransfer':
            assetType = from.symbol;
            amount = from.value;
            typeLabel = txLabel;
            formattedValue = `${formCurrency?.code}${formatCurrency(amount, 2)}`;
            account = fromAccount ?? toAccount;
            columnType = from.columnType;
            break;

          case 'burn':
          default:
            assetType = from.symbol;
            amount = from.value;
            typeLabel = txLabel;
            formattedValue = fromTokenType?.uom?.name
              ? `${formatNumber(amount, 0)} ${fromTokenType?.uom?.name}`
              : renderValue(amount);
            account = fromAccount ?? toAccount;
            columnType = from.columnType;
            break;
        }

        return {
          date: txTime,
          txHash,
          isDebit,
          assetType,
          amount,
          accountName: account?.fullName ?? '',
          typeLabel,
          formattedValue,
          columnType,
        };
      })
      .filter(Boolean) ?? []
  );
}

const types = ['fund', 'withdraw', 'mint', 'burn'];
const transferTypes = [
  'PHYSICAL_DELIVERY',
  'RETIREMENT',
  'BURN_FEE',
  'WITHDRAW_FEE',
  'EXCHANGE_FEE',
  'ORIGINATOR_FEE',
  'TAKEPAY_FEE',
  'MINT_FEE',
  'BURN_FEE',
  'WITHDRAW_FEE',
  'DEPOSIT_FEE',
  'DATA_FEE',
  'ADJUSTMENT',
  'RELATED_TRANSFER',
];

export const Assets = () => {
  const { accountAddresses, accountUsers } = Account.useContainer();
  const { tokenTypes } = useTokenTypes();
  const { currencies } = useCurrencies({});

  const accountOptions = accountUsers
    ?.filter((account) => account.account)
    ?.map((userAccount) => ({
      label: `${userAccount.first_name} ${userAccount.last_name}`,
      id: userAccount.account,
    }));
  const pagination = usePagination();

  const filters: {
    account: FilterRadioBox;
    date: FilterDateRangeInput;
  } = {
    account: {
      type: 'radio-box',
      label: 'Accounts',
      list: accountOptions || [],
    },
    date: {
      type: 'date-range-input',
      label: 'Date range',
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});

  const {
    status: { isSuperAdmin },
  } = User.useContainer();

  const [summary, setSummary] = useState<Record<string, any>>();

  let url = `${WEB_INDEXER_API}/transactions?page=${pagination.page}&limit=${pagination.pageSize}${
    isSuperAdmin() ? '&excludeTestAsset=no' : ''
  }`;
  if (filterSelections?.date?.range?.startDate && filterSelections?.date?.range?.endDate)
    url += `&startDate=${filterSelections?.date?.range?.startDate.toISOString()}&endDate=${filterSelections?.date?.range?.endDate.toISOString()}`;
  url += `&${serialize({ txType: types })}`;
  url += `&${serialize({ transferType: transferTypes })}`;
  url += `&address=${filterSelections?.account?.selection || accountAddresses().join(',')}`;

  const fetchTransactions = async (): Promise<Transaction> => {
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp: Response) => resp.json())
      .then((result) => {
        setSummary(result.summary);
        return result;
      });
  };

  const { data: transactions, isLoading } = useQuery(url, fetchTransactions, {
    enabled: filterSelections?.account?.selection || accountAddresses().join(',') || false,
  });

  const rows = transformData({
    transactions: transactions?.data,
    accountUsers,
    currencies,
    tokenTypes,
  });

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="Assets"
          controls={{
            secondary: (
              <DownloadCSV
                data={rows.map((item) => ({
                  Date: item?.date,
                  Account: item?.accountName,
                  Transaction: item?.typeLabel,
                  Asset: item?.assetType,
                  Amount: `${item?.isDebit ? '-' : '+'}${item?.amount}`,
                }))}
                fileName={'asset-report.csv'}
              />
            ),
          }}
        />
      </PageSections>
      <PageSections type="card" className="flex flex-col">
        <div className="flex flex-row justify-between items-center gap-base">
          <div className="flex flex-row gap-4 justify-start">
            {/*<Input
              placeholder="Search"
              config={{
                size: 'base',
                color: 'gray',
                postfix: (
                  <HitArea
                    width={20}
                    height={20}
                    className="cursor-pointer"
                    onClick={() => {
                      setSearch('');
                    }}
                  >
                    <Icon type={IconType.X} width={10} height={10} />
                  </HitArea>
                ),
                prefix: <Icon type={IconType.Search} width={14} height={14} />,
              }}
              value={search || ''}
              onChange={(event) => {
                setSearch(event.target.value.trim() || '');
              }}
            />*/}
          </div>
          <div className="flex flex-row justify-start">
            <FilterDropdown
              selections={filterSelections}
              onChange={(value) => setFilterSelections(value)}
              list={filters}
            />
          </div>
        </div>
        <FilterSelections
          selections={filterSelections}
          onChange={(value) => setFilterSelections(value)}
          list={filters}
        />
        {isLoading ? (
          <Loading isOverLay={true} />
        ) : (
          <Table
            config={{
              sticky: {
                left: ['transaction'],
              },
              columns: {
                transaction: {
                  label: 'TRANSACTION',
                },
                date: {
                  label: 'DATE',
                },
                accountName: {
                  label: 'ACCOUNT NAME',
                },
                asset: {
                  label: 'ASSET',
                },
                amount: {
                  label: 'AMOUNT',
                },
              },
              rows:
                rows.map((item) => {
                  return {
                    _key: item?.txHash || item?.date || '-',
                    date: item?.date,
                    transaction: item?.typeLabel,
                    accountName: item?.accountName ?? '',
                    asset: item?.assetType,
                    amount: (
                      <Text
                        color={item?.isDebit ? TextColor.error : TextColor.success}
                        variant={TypographyVariant.subtitle2}
                      >
                        {item?.isDebit ? '-' : '+'}
                        {item?.formattedValue}
                      </Text>
                    ),
                  };
                }) || [],
            }}
          />
        )}
        <Pagination total={summary?.totalTransactions ?? 0} actions={pagination} />
      </PageSections>
    </PageHolder>
  );
};
