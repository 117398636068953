import { format } from 'date-fns';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Badge,
  Button,
  ButtonSize,
  ButtonVariant,
  DepositRequests,
  type DepositRequestsFilter,
  IconName,
  Knob,
  ModalApproveDepositRequest,
  ModalRejectDepositRequest,
  Text,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import { DepositRequestsContext } from './utils/DepositRequestsContext';
import { toAssetCategoryName } from './utils/toAssetCategoryName';
import { toAssetName } from './utils/toAssetName';
import { toDepositAmount } from './utils/toDepositAmount';
import { toStatusBadgeProps } from './utils/toStatusBadgeProps';
import { toUserName } from './utils/toUserName';

export const DepositRequestsContainer = () => {
  const {
    filters: depositRequestsFilters,
    depositRequests,
    search,
    pagination,
    modalApproveDepositRequestProps,
    modalRejectDepositRequestProps,
    isLoadingDepositRequests,
    changeFilter,
    changeSearch,
    changePagination,
    showApproveModal,
    hideApproveModal,
    showRejectModal,
    hideRejectModal,
    approveRequest,
    rejectRequest,
    changeRejectionReason,
  } = useContext(DepositRequestsContext);

  const history = useHistory();

  const onPressApproveDepositRequest = (requestId: string) => {
    showApproveModal(requestId);
  };

  const onPressRejectDepositRequest = (requestId: string) => {
    showRejectModal(requestId);
  };

  const onPressShowDetails = (requestId: string) => {
    history.push(`/account/balances/deposit-requests/${requestId}`); // todo
  };

  const onChangeDepositRequestsFilter = (filter: DepositRequestsFilter) => {
    changeFilter(filter);
  };

  const onChangeDepositRequestsSearch = (newSearch: string) => {
    changeSearch(newSearch);
  };

  const onChangeDepositRequestsLimit = (limit: number) => {
    changePagination({ limit });
  };

  const onChangeCurrentPage = (currentPage: number) => {
    changePagination({ currentPage });
  };

  return (
    <>
      <DepositRequests
        isLoading={isLoadingDepositRequests}
        filters={depositRequestsFilters}
        limit={pagination.limit}
        limits={pagination.limitOptions}
        columns={
          [
            {
              label: '#',
              value: 'id',
            },
            {
              label: 'User',
              value: 'user',
            },
            {
              label: 'Asset Category',
              value: 'assetCategory',
            },
            {
              label: 'Asset',
              value: 'asset',
            },
            {
              label: 'Amount',
              value: 'amount',
            },
            {
              label: 'Date',
              value: 'date',
            },
            {
              label: 'Status',
              value: 'status',
            },
            {
              label: 'Comment',
              value: 'comment',
            },
            {
              label: 'Actions',
              value: 'actions',
              isSticky: true,
            },
          ] as const
        }
        rows={depositRequests.map((request) => {
          const statusBadgeProps = toStatusBadgeProps(request);

          return {
            id: String(request.id),
            user: toUserName(request),
            assetCategory: <Text variant={TypographyVariant.subtitle2}>{toAssetCategoryName(request)}</Text>,
            asset: <Text variant={TypographyVariant.subtitle2}>{toAssetName(request)}</Text>,
            amount: toDepositAmount(request),
            date: format(new Date(request.createdAtUtc), "MMMM do',' yyyy 'at' h:mm b"),
            status: statusBadgeProps && <Badge {...statusBadgeProps} />,
            comment: request.comment ?? '',
            actions: (
              <ActionsWrapper>
                {request.status === Dto.AssetDepositStatus.New ? (
                  <>
                    <Button
                      onPress={() => onPressApproveDepositRequest(String(request.id))}
                      variant={ButtonVariant.secondary}
                      size={ButtonSize.xs}
                    >
                      Confirm
                    </Button>
                    <Knob
                      variant={ButtonVariant.outlined}
                      size={ButtonSize.xs}
                      icon={IconName.XCircle}
                      onPress={() => onPressRejectDepositRequest(String(request.id))}
                    />
                    <Knob
                      variant={ButtonVariant.outlined}
                      size={ButtonSize.xs}
                      icon={IconName.Eye}
                      onPress={() => onPressShowDetails(String(request.id))}
                    />
                  </>
                ) : (
                  <StyledShowDetailsButton
                    size={ButtonSize.xs}
                    variant={ButtonVariant.outlined}
                    onPress={() => onPressShowDetails(String(request.id))}
                  >
                    Show details
                  </StyledShowDetailsButton>
                )}
              </ActionsWrapper>
            ),
          };
        })}
        currentPage={pagination.currentPage}
        pagesCount={pagination.pagesCount}
        itemsCount={pagination.itemsCount}
        search={search}
        onChangeFilter={onChangeDepositRequestsFilter}
        onChangeSearch={onChangeDepositRequestsSearch}
        onChangeLimit={onChangeDepositRequestsLimit}
        onChangeCurrentPage={onChangeCurrentPage}
      />
      <ModalApproveDepositRequest
        {...modalApproveDepositRequestProps}
        onClose={() => {
          hideApproveModal();
        }}
        onConfirm={() => {
          approveRequest();
        }}
      />
      <ModalRejectDepositRequest
        {...modalRejectDepositRequestProps}
        onClose={() => {
          hideRejectModal();
        }}
        onPressReject={() => {
          rejectRequest();
        }}
        onChangeRejectionReason={(rejectionReason) => {
          changeRejectionReason(rejectionReason);
        }}
      />
    </>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  > * {
    flex-shrink: 0;
  }
`;

const StyledShowDetailsButton = styled(Button)`
  flex-grow: 1;
`;
