import { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  SelectProjectAndVintage,
  type SelectProjectAndVintageRef,
} from 'refreshed-pages/market-board-v2/components/SelectProjectAndVintage';

import { styled, toSpacing } from '@aircarbon/ui';
import { Const } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import type { OfferFormValue } from '../../hooks/usePlaceOrderForm';
import { CommonOrderFields, type CommonOrderFieldsRef } from '../CommonOrderFields';

export interface PlaceOfferFormRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

export const PlaceOfferForm = forwardRef<
  PlaceOfferFormRef,
  {
    value: OfferFormValue;
    ownedByUserId: number;
    priceCurrency: string;
    quantityUnit: string;
    isInstantTradeEnabled: boolean;
    selectProjectFilterCriteria: Array<{
      key: string;
      operator: string;
      values: Array<string>;
    }>;
    availableVintages?: Array<string>;
    minimumQuantity?: number;
    maximumQuantity?: number;
    openQuantity: number;
    onPressCreateNewProject?(): void;
    onChange(value: OfferFormValue): void;
  }
>((props, ref) => {
  const {
    onPressCreateNewProject,
    value,
    availableVintages,
    priceCurrency,
    quantityUnit,
    ownedByUserId,
    isInstantTradeEnabled,
    selectProjectFilterCriteria,
    maximumQuantity,
    minimumQuantity,
    openQuantity,
    onChange,
  } = props;
  const commonOrderFieldsRef = useRef<CommonOrderFieldsRef>(null);
  const selectProjectAndVintageRef = useRef<SelectProjectAndVintageRef>(null);

  const { getSetting } = UI.useContainer();

  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  useImperativeHandle(ref, () => ({
    validate() {
      const hasCommonFieldsErrors = commonOrderFieldsRef.current?.validate() || false;
      const hasSelectProjectAndVintageErrors = selectProjectAndVintageRef.current?.validate() || false;

      return hasCommonFieldsErrors || hasSelectProjectAndVintageErrors;
    },
  }));

  const onChangeFormValue = (newValue: Partial<OfferFormValue>) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  return (
    <StyledPlaceOfferForm>
      <SelectProjectAndVintage
        projectLabel="Select project that meets criteria"
        ref={selectProjectAndVintageRef}
        vintage={value.vintage}
        project={value.project}
        availableVintages={availableVintages}
        ownedByUserId={ownedByUserId}
        onPressCreateNewProject={onPressCreateNewProject}
        onChange={onChangeFormValue}
        shouldOnlyAcceptActiveProjects
        selectProjectFilterCriteria={selectProjectFilterCriteria}
      />
      <CommonOrderFields
        ref={commonOrderFieldsRef}
        value={value}
        priceCurrency={priceCurrency}
        quantityUnit={quantityUnit}
        onChange={onChangeFormValue}
        balance={value.project?.vintageBalance}
        balancePerAsset={value.project?.vintageBalancePerAsset}
        isInstantTradeEnabled={isInstantTradeEnabled && value.project?.vintageBalance! > 0}
        isInstantTradeHidden={isMarketBoardAndAuctionsV2SettlementModeEnabled}
        maximumQuantity={maximumQuantity}
        minimumQuantity={minimumQuantity}
        openQuantity={openQuantity}
      />
    </StyledPlaceOfferForm>
  );
});

const StyledPlaceOfferForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
