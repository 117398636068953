import { useEffect } from 'react';
import { queryCache, useQuery } from 'react-query';
import { Table } from 'refreshed-component/templates/Table';

import { Text } from '@aircarbon/ui';
import { formatter, logger } from '@aircarbon/utils-common';

import useCarbonFinderRealtimeMessage from 'hooks/useCarbonFinderRealtimeMessage';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchRFQResponses } from 'data-provider/rfq';

const { truncateMiddle } = formatter;

interface Props {
  userId: number;
}

const RFQAllResponses = ({ userId }: Props) => {
  const realtimeMessage = useCarbonFinderRealtimeMessage();
  const { tokenTypesByScId } = useTokenTypes();
  const { data } = useQuery(['view-responses'], async () => {
    try {
      const response = await fetchRFQResponses(20);
      return response;
    } catch (error) {
      logger.error(error);
    }
  });

  // NOTE: real-time message should return Seller ID and Buyer ID and update here if current userId is either.
  useEffect(() => {
    if (realtimeMessage?.rfqMessages?.type === 'RFQ_UPDATE') {
      queryCache.invalidateQueries(['view-responses']);
    }
  }, [realtimeMessage]);

  const responses = data !== undefined ? data?.items : [];

  return (
    <div>
      {responses?.length === 0 ? (
        <Text className="mt-base">No fills yet.</Text>
      ) : (
        <>
          <Table
            config={{
              sticky: {
                left: ['tradeId'],
                right: ['status'],
              },
              columns: {
                tradeId: {
                  label: 'TRADE ID',
                },
                bidId: {
                  label: 'BID ID',
                },
                side: {
                  label: 'SIDE',
                },
                asset: {
                  label: 'ASSET',
                },
                quantity: {
                  label: 'QUANTITY',
                },
                price: {
                  label: 'PRICE',
                },
                total: {
                  label: 'TOTAL',
                },
                status: {
                  label: 'STATUS',
                },
              },
              rows:
                responses?.map((response) => {
                  return {
                    _key: response.id.toString(),
                    tradeId: response.txHash ? (
                      <a
                        href={`${window.location.origin}/explore/tx/${response.txHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {truncateMiddle(response.txHash, 8)}
                      </a>
                    ) : (
                      '-'
                    ),
                    bidId: response.rfqRequestId,
                    side: response.userId === userId ? 'Sell' : 'Buy',
                    asset: tokenTypesByScId()[response.tokenTypeId]?.symbol,
                    quantity: formatter.formatNumber(response.quantity, 0),
                    price: formatter.formatNumber(response.price, 2),
                    total: formatter.formatNumber(response.quantity * response.price, 2),
                    status: (
                      <>
                        {response.status}
                        {response.statusMessage ? (
                          <span className="ml-1 text-red-400">{response.statusMessage}</span>
                        ) : (
                          ''
                        )}
                      </>
                    ),
                  };
                }) || [],
            }}
          />
        </>
      )}
    </div>
  );
};

export default RFQAllResponses;
