export const toYears = (props: { startYear: number; maximumYearsCount: number }) => {
  const { startYear, maximumYearsCount } = props;
  if (!startYear) {
    return [];
  }

  return Array.from({ length: maximumYearsCount }, (_, index) => {
    const currentYear = startYear + index;

    return {
      value: '' + currentYear,
      title: '' + currentYear,
    };
  });
};
