import { type ChangeEventHandler, useEffect, useState } from 'react';
import { Input } from 'refreshed-component/atoms/Input';
import { Colors } from 'refreshed-component/design-system';
import {
  FilterDropdown,
  FilterSelections,
  type Filters as SelectionFilters,
} from 'refreshed-component/molecules/Filter';
import styled from 'styled-components';

import { Icon, IconName } from '@aircarbon/ui';

import useDebounce from 'hooks/useDebounce';

import type { Filters } from './utils/Filters';
import { toFilters } from './utils/toFilters';
import { toSelections } from './utils/toSelections';

interface FilterOption {
  id: number;
  label: string;
}

export interface FilteringOptions {
  projectTypes: Array<FilterOption>;
  ccbStandards: Array<FilterOption>;
  sectoralScopes: Array<FilterOption>;
}

interface SarchAndFilterProps {
  search: string;
  areFilteringOptionsLoading?: boolean;
  filters: Filters;
  filteringOptions: FilteringOptions;
  onSearch(value: string): void;
  onChange(filters: Filters): void;
}

export const SearchAndFilter: React.FC<SarchAndFilterProps> = (props) => {
  const { onSearch, search, filteringOptions, areFilteringOptionsLoading, filters, onChange } = props;
  const [searchValue, setSearchValue] = useState(search);
  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    if (debouncedSearchValue === search) {
      return;
    }

    onSearch(debouncedSearchValue);
  }, [debouncedSearchValue, search]);

  const onPressClearSearch = () => {
    setSearchValue('');
    onSearch('');
  };

  const onChangeSearchValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const filtersList: SelectionFilters = {
    projectType: {
      type: 'check-box',
      label: 'Project Type',
      list: filteringOptions.projectTypes,
    },
    vintageYear: {
      type: 'range-input',
      label: 'Vintage Year',
      range: {
        from: {
          label: 'Starting:',
          placeholder: 'yyyy',
        },
        to: {
          label: 'Ending:',
          placeholder: 'yyyy',
        },
      },
    },
    sectoralScope: {
      type: 'check-box',
      label: 'Sectoral Scope',
      list: filteringOptions.sectoralScopes,
    },
  };

  const selections = toSelections(filters);

  const onChangeFilters = (selections?: FilterSelections<SelectionFilters>) => {
    const newFilters = toFilters({
      selections,
      filters,
    });
    onChange(newFilters);
  };

  const onChangeFilterSelection = (selections?: FilterSelections<SelectionFilters>) => {
    const newFilters = toFilters({
      selections,
      filters,
      shouldMerge: true,
    });
    onChange(newFilters);
  };

  return (
    <>
      <StyledSearchAndFilter>
        <Input
          placeholder="Search"
          config={{
            size: 'base',
            color: 'gray',
            postfix: (
              <ClearSearch onClick={onPressClearSearch}>
                <Icon name={IconName.XCircle} size="0.875rem" />
              </ClearSearch>
            ),
            prefix: <Icon name={IconName.Search} size="0.875rem" />,
          }}
          value={searchValue}
          onChange={onChangeSearchValue}
        />

        {!areFilteringOptionsLoading && (
          <FilterDropdown selections={selections} list={filtersList} onChange={onChangeFilters} />
        )}
      </StyledSearchAndFilter>
      {!areFilteringOptionsLoading && (
        <FilterSelections selections={selections} onChange={onChangeFilterSelection} list={filtersList} />
      )}
    </>
  );
};

const StyledSearchAndFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClearSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-inline-end: -6px;
  color: var(${Colors.gray_400});
  cursor: pointer;
`;
