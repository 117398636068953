import { BadgeVariant } from '@aircarbon/ui';

const badgeVariantsToStatusMap: Record<string, BadgeVariant> = {
  submitted: BadgeVariant.Success,
  done: BadgeVariant.Success,
  pending: BadgeVariant.Alert,
  processing: BadgeVariant.Alert,
  reverted: BadgeVariant.Danger,
  cancelled: BadgeVariant.Danger,
};

export const toBadgeVariant = (status: string) => {
  return badgeVariantsToStatusMap[status] || BadgeVariant.Default;
};
