import { useMemo, useRef } from 'react';

import {
  BorderColor,
  ButtonSize,
  ButtonVariant,
  IconName,
  Knob,
  Tabs,
  TabsSize,
  styled,
  toBorderColor,
  toSpacing,
  useLayerBackground,
} from '@aircarbon/ui';

import { useBottomTabs } from '../BottomTabsProvider/hooks/useBottomTabs';

/**
 * Header height in px
 */
const headerHeight = 49;

export const BottomSlideUp = () => {
  const { layerBackground } = useLayerBackground();
  const { activeTabId, isOpened, updateState, availableTabs } = useBottomTabs();
  const slideUpRef = useRef<HTMLDivElement>(null);
  const ContentComponent = useMemo(() => {
    return availableTabs.find((tab) => tab.id === activeTabId)?.component || availableTabs[0].component;
  }, [activeTabId, availableTabs]);

  return (
    <StyledBottomSlideUp
      ref={slideUpRef}
      background={layerBackground('layer')}
      $isOpened={isOpened}
      className={'with-animation'}
    >
      <Header>
        <div>
          <Tabs
            onPressTab={(tab) => {
              updateState(() => ({
                activeTabId: tab.id,
                isOpened: true,
              }));
            }}
            activeItemId={activeTabId}
            items={availableTabs}
            size={TabsSize.l}
          />
        </div>
        <div>
          <Knob
            size={ButtonSize.xs}
            icon={isOpened ? IconName.Collapse : IconName.Expand}
            variant={ButtonVariant.secondary}
            tooltip={isOpened ? 'Collapse' : 'Expand'}
            onPress={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateState((prevState) => ({
                ...prevState,
                isOpened: !prevState.isOpened,
              }));
            }}
          />
        </div>
      </Header>
      <Content>
        <ContentComponent />
      </Content>
    </StyledBottomSlideUp>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
  flex-shrink: 0;
`;

const Content = styled.div`
  flex-shrink: 1;
  overflow-y: auto;
`;

const StyledBottomSlideUp = styled.div<{
  background: string;
  $isOpened: boolean;
}>`
  width: 100%;
  position: absolute;
  bottom: calc(-100% + 1px);
  z-index: 1;
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background: ${({ background }) => background};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.2s ease-in;
  ${({ $isOpened }) => {
    return `
      transform: translate3d(
        0,
        ${$isOpened ? '-100%' : `-${headerHeight}px`},
        0
      );
    `;
  }}
`;
