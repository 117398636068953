/**
 * Handles ResizeObserver loop limit exceeded error that can occur with UI libraries
 * This is a known issue with ResizeObserver when multiple resize events occur rapidly
 * @see https://github.com/WICG/resize-observer/issues/38
 */
import { logger } from '@aircarbon/utils-common';

// Create a new utility file to handle ResizeObserver errors
function debounce(fn: () => void, delay: number) {
  let timeoutId: NodeJS.Timeout;
  return () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
}

export function setupResizeObserverError() {
  // Prevent ResizeObserver loop limit exceeded error
  const observer = new ResizeObserver((entries) => {
    const debouncedUpdate = debounce(() => {
      window.requestAnimationFrame(() => {
        for (const entry of entries) {
          // Handle resize logic if needed
          logger.info({ entry }, 'ResizeObserver - handle resize if needed');
        }
      });
    }, 16); // roughly one frame at 60fps

    debouncedUpdate();
  });

  // Handle error
  window.addEventListener('error', (e) => {
    if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
      e.stopPropagation();
      e.preventDefault();
    }
  });

  return observer;
}
