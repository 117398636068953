import queryString from 'query-string';
import type { ProjectBalance } from 'refreshed-pages/market-board-v2/utils/Project';

import { userAuthenticatedApi } from 'data-provider/fetcher';

interface FetchProjectsProps {
  projectIds?: Array<string>;
  assetCategoryId: number;
  ownedByUserId?: number;
  showOwnedOnly?: boolean;
}

export interface ApiProject {
  id: number;
  name: string;
  registryProjectId: string;
  __registry__: {
    id: number;
    name: string;
    code: string;
  };
  contracts: Record<string, number>[];
  balances?: ProjectBalance[];
}

export const fetchProjects = async (
  props: FetchProjectsProps,
): Promise<{
  data: Array<ApiProject>;
}> => {
  try {
    const userService = await userAuthenticatedApi();
    const request = await userService.url(`/user/project`).query(queryString.stringify(props)).get();

    const response = (await request.json()) as any;

    return response;
  } catch (e) {
    throw e;
  }
};
