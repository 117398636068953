/**
 * Parse VCS serial block format
 * @param serialBlock string
 * @returns project.serialStart string
 * @returns project.serialEnd string
 * @returns project.country string
 * @returns project.projectId string
 * @returns project.vintageStart string
 * @returns project.vintageEnd string
 */
export function parseVCSFormat(serialBlock: string): { [key: string]: string } | undefined {
  const vcsSerialRegex =
    /^\w+-(?<serialStart>\w+)-(?<serialEnd>\w+)-\w+-\w+-\w+-\w+-(?<country>\w+)-\w+-(?<projectId>\w+)-(?<vintageStart>\w+)-(?<vintageEnd>\w+)-\w+$/;
  if (vcsSerialRegex.test(serialBlock)) {
    const match = vcsSerialRegex.exec(serialBlock);
    return match?.groups;
  }
  return undefined;
}

/**
 * Parse GS serial block format
 * @param serialBlock string
 * @returns project.serialStart string
 * @returns project.serialEnd string
 * @returns project.country string
 * @returns project.projectId string
 * @returns project.vintage string
 */
export function parseGSFormat(serialBlock: string): { [key: string]: string } | undefined {
  const vcsSerialRegex =
    /^\w+-\w+-(?<country>\w+)-(?<projectId>\w+)-\w+-(?<vintage>\w+)-\w+-(?<serialStart>\w+)-(?<serialEnd>\w+)$/;
  if (vcsSerialRegex.test(serialBlock)) {
    const match = vcsSerialRegex.exec(serialBlock);
    return match?.groups;
  }
  return undefined;
}

export function extractGSMetaFromSerialBlock(serialBlock: string): {
  extractedMetadata: Record<string, any> | undefined;
  parsedSerial: { [key: string]: string } | undefined;
} {
  const parsedSerial = parseGSFormat(serialBlock);

  if (parsedSerial) {
    const extractedMetadata: Record<string, any> = {
      TXT_MINTED_SERIAL_BLOCK: serialBlock,
      TXT_MINTED_SERIAL_START: parsedSerial.serialStart,
      TXT_MINTED_SERIAL_END: parsedSerial.serialEnd,
      DATE_VINTAGE_START: `0101${parsedSerial.vintage}`,
      DATE_VINTAGE_END: `3112${parsedSerial.vintage}`,
    };

    return { extractedMetadata, parsedSerial };
  }

  return { extractedMetadata: undefined, parsedSerial };
}

export function extractVCSMetaFromSerialBlock(serialBlock: string): {
  extractedMetadata: Record<string, any> | undefined;
  parsedSerial: { [key: string]: string } | undefined;
} {
  const parsedSerial = parseVCSFormat(serialBlock);

  if (parsedSerial) {
    const extractedMetadata: Record<string, any> = {
      TXT_MINTED_SERIAL_BLOCK: serialBlock,
      TXT_MINTED_SERIAL_START: parsedSerial.serialStart,
      TXT_MINTED_SERIAL_END: parsedSerial.serialEnd,
      DATE_VINTAGE_START: parsedSerial.vintageStart,
      DATE_VINTAGE_END: parsedSerial.vintageEnd,
    };

    return { extractedMetadata, parsedSerial };
  }

  return { extractedMetadata: undefined, parsedSerial };
}
