import { FaRegCopy } from 'react-icons/fa';
import styled from 'styled-components';

import { clipboardCopy } from 'utils/helpers';

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  padding-right: 25px;

  svg {
    display: none;
    margin-left: 0.5em;
    cursor: pointer;
    position: absolute;
    right: 0;

    &:active {
      transform: scale(1.4);
      fill: currentColor;
      opacity: 0.5;
    }
  }

  &:hover {
    svg {
      display: block;
    }
  }
`;

export interface CopyInputProps {
  text: string;
  children?: React.ReactNode;
}

const CopyInput: React.FC<CopyInputProps> = ({ children, text }) => (
  <Wrapper>
    {children}
    <FaRegCopy onClick={() => clipboardCopy(text)} />
  </Wrapper>
);

export default CopyInput;
