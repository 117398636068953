import { ProjectStatus } from 'refreshed-pages/project-exchange/utils/ProjectStatus';

import { Badge, BadgeVariant, IconName } from '@aircarbon/ui';

export const BadgeProjectStatus: React.FC<{
  status: ProjectStatus;
}> = ({ status }) => {
  const badgeColor = toBadgeVariant(status);

  return <Badge variant={badgeColor} value={toStatusText(status)} icon={toBadgeIconType(status)} />;
};

const statusBadgeColorMap = {
  [ProjectStatus.Draft]: BadgeVariant.Default,
  [ProjectStatus.Rejected]: BadgeVariant.Danger,
  [ProjectStatus.Submitted]: BadgeVariant.Alert,
  [ProjectStatus.Active]: BadgeVariant.Success,
};

const statusesIconMap = {
  [ProjectStatus.Draft]: IconName.Document,
  [ProjectStatus.Rejected]: IconName.XCircle,
  [ProjectStatus.Submitted]: IconName.Clock,
  [ProjectStatus.Active]: IconName.CheckCircle,
};

const statusesTextMap = {
  [ProjectStatus.Draft]: 'Draft',
  [ProjectStatus.Rejected]: 'Rejected',
  [ProjectStatus.Submitted]: 'Submitted',
  [ProjectStatus.Active]: 'Active',
};

const toBadgeVariant = (status: ProjectStatus) => statusBadgeColorMap[status];

const toBadgeIconType = (status: ProjectStatus) => statusesIconMap[status];

const toStatusText = (status: ProjectStatus) => statusesTextMap[status];
