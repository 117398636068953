type ToEndYearProps = {
  endYears: Array<{
    value: string;
    title: string;
  }>;
  endYear: string;
};

export const toEndYear = (props: ToEndYearProps) => {
  if (!props.endYears.length) {
    return '';
  }

  if (props.endYears.map((endYear) => endYear.value).includes(props.endYear)) {
    return props.endYear;
  }

  return '';
};
