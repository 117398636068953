import { memo, useState } from 'react';

import { Alert, AlertColor, AlertVariant } from '@aircarbon/ui';
import {
  LocalStorage,
  type WebSettingsAnnouncementOption,
  resolveWebSettingsAnnouncement,
} from '@aircarbon/utils-common';

import { UI } from 'state/ui';

const webSettingsAnnouncementKey = 'web-settings-announcement';

const webAnnouncementTypeToAlertType: Record<WebSettingsAnnouncementOption, AlertColor> = {
  ALERT: AlertColor.Danger,
  WARNING: AlertColor.Warning,
  INFO: AlertColor.Info,
};

export const WebAnnouncement = memo(() => {
  const { getSetting } = UI.useContainer();
  const webAnnouncement = resolveWebSettingsAnnouncement(getSetting('web_settings_announcement'));
  const webAnnouncementJson = JSON.stringify(webAnnouncement);
  const isClosed = LocalStorage.getItem('web-settings-announcement') === webAnnouncementJson;
  const [isOpen, setOpen] = useState(!isClosed);
  return (
    <>
      {isOpen && webAnnouncement.message && (
        <Alert
          variant={AlertVariant.Primary}
          title={webAnnouncement.message}
          color={webAnnouncementTypeToAlertType[webAnnouncement.type]}
          onPressClose={
            webAnnouncement.cancelable
              ? () => {
                  LocalStorage.setItem(webSettingsAnnouncementKey, webAnnouncementJson);
                  setOpen(false);
                }
              : undefined
          }
        />
      )}
    </>
  );
});
