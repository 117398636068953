import type { ProjectStatus } from 'refreshed-pages/project-exchange/utils/ProjectStatus';

import { Tabs } from '@aircarbon/ui';

type TabItemValue = ProjectStatus | 'all';

type TabItem = {
  id: TabItemValue;
  label: string;
};

export const FilterTabs: React.FC<{
  items: Array<TabItem>;
  activeTab: TabItemValue;
  onPressTab: (value: TabItemValue) => void;
}> = (props) => {
  const { items, activeTab, onPressTab } = props;

  return (
    <Tabs
      activeItemId={activeTab as string}
      items={
        items as {
          id: string;
          label: string;
        }[]
      }
      onPressTab={(tabItem) => onPressTab(tabItem.id as TabItemValue)}
    />
  );
};
