import { userAuthenticatedApi } from 'data-mutation/mutator';

import type { Dto } from '@aircarbon/utils-common';

export const createProject = async (props: { params: Dto.AddApxProjectApiParams }) => {
  try {
    const userService = await userAuthenticatedApi();
    // TODO: Migrate to properly fetcher with api client
    const request = await userService.url('/user/apx/projects').post(props.params);

    const response = (await request.json()) as any;

    if (!response.record.id) {
      return null;
    }

    return {
      id: response.record.id,
    };
  } catch {
    return null;
  }
};
