import { addMinutes, format, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Empty } from 'refreshed-component/atoms/Empty';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import {
  type FilterCheckBox,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { Pagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';

import { Badge, BadgeVariant, Button, IconName } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { BlockRequestCard } from '../containers/BlockRequestCard';
import { Summery } from './containers/Summery';
import { useOutgoingRequest } from './hooks/outgoingTradeRequest';

export const OutgoingRequests = () => {
  const history = useHistory();
  const tradeRequest = useOutgoingRequest();
  const { getSetting } = UI.useContainer();
  const {
    status: { canCreateBlockRequest },
  } = User.useContainer();

  const clientFilterItems = tradeRequest.clientList?.length
    ? tradeRequest.clientList.map((client) => ({
        id: client.id,
        label: `${client.firstName} ${client.lastName}`,
      }))
    : [];

  const filters: {
    status: FilterCheckBox;
    account?: FilterRadioBox;
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 'ALL STATUS',
          label: 'ALL STATUS',
        },
        {
          id: 'APPROVED',
          label: 'APPROVED',
        },
        {
          id: 'NEW',
          label: 'NEW',
        },
        {
          id: 'PROCESSING',
          label: 'PROCESSING',
        },
        {
          id: 'REJECTED',
          label: 'REJECTED',
        },
        {
          id: 'EXECUTED',
          label: 'EXECUTED',
        },
        {
          id: 'CANCELLED',
          label: 'CANCELLED',
        },
      ],
    },
    ...(clientFilterItems.length && {
      account: {
        type: 'radio-box',
        label: 'Accounts',
        list: clientFilterItems,
      },
    }),
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();
  const acceptTimeLimitMinutes = Number(getSetting('blockTrade_acceptTimeLimitMinutes'));
  const {
    selector: { mainCcyNumDecimals },
  } = Entity.useContainer();

  useEffect(() => {
    tradeRequest.setSelectedStatus(filterSelections?.status?.selection?.[0].toString());
    tradeRequest.setSelectedBroker(filterSelections?.account?.selection?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelections]);

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageControls
            title="Outgoing Requests"
            controls={{
              secondary: (
                <>
                  {canCreateBlockRequest() && (
                    <Button
                      onPress={() => {
                        history.push('/account/block-trade/create-request');
                      }}
                      endIcon={IconName.ArrowEnd}
                    >
                      Create Request
                    </Button>
                  )}
                </>
              ),
            }}
          />
        </PageSections>
        <PageSections>
          <Summery />
        </PageSections>
        <PageSections type="card" className="flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <Text size={FontSize.large}>Requests To Trade</Text>
            <div className="flex flex-row gap-4 justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          {!tradeRequest.isLoading && !!tradeRequest.response?.items.length ? (
            <>
              {tradeRequest.response?.items.map((item) => {
                const project = item.tradeRequestDetail?.carbonProject;
                const createdAtUtc = new Date(item.createdAtUtc);
                const now = new Date();
                const acceptanceExpiresOn = addMinutes(createdAtUtc, acceptTimeLimitMinutes);
                const isClosed = ['EXECUTED', 'CANCELLED', 'REJECTED'].includes(item.status);
                const isAccepted =
                  item.sellerConfirmationStatus === 'ACCEPTED' && item.buyerConfirmationStatus === 'ACCEPTED';
                const canStillAccept = isBefore(now, acceptanceExpiresOn);

                return (
                  <BlockRequestCard
                    title={
                      project
                        ? `${project?.registryProjectId} - ${project?.name} / Vintage:${' '} ${
                            item.tradeRequestDetail?.carbonProjectVintageYear
                          }`
                        : undefined
                    }
                    description={project?.name || `#${item.id}`}
                    info={{
                      onClick: undefined,
                      fields: [
                        {
                          name: 'Request ID',
                          value: `#${item.id}`,
                        },
                        {
                          name: 'Status',
                          value: (
                            <>
                              {canStillAccept || isClosed || isAccepted ? (
                                <div>
                                  <Badge variant={BadgeVariant.Success} value={item.status} />
                                </div>
                              ) : (
                                <Badge value="EXPIRED" />
                              )}
                            </>
                          ),
                        },
                        {
                          name: 'Asset',
                          value: `${item.baseAsset?.symbol}/${item.quoteAsset?.symbol}`,
                        },
                        {
                          name: `Qty (${item.baseAsset?.uom?.code || 'tCO2'})`,
                          value: formatter.formatNumber(item.quantity, 0),
                        },
                        {
                          name: 'Price',
                          value: formatter.formatNumber(item.price, mainCcyNumDecimals),
                        },
                        {
                          name: 'Amount',
                          value: formatter.formatNumber(item.price * item.quantity, mainCcyNumDecimals),
                        },
                        {
                          name: 'Fee',
                          value: formatter.formatNumber(item.buyerTradeFee, mainCcyNumDecimals),
                        },
                        {
                          name: 'Arranger Fee',
                          value: formatter.formatNumber(item.sellerArrangerFeeAmount, mainCcyNumDecimals),
                        },
                        {
                          name: 'Confirm by',
                          value: canStillAccept ? format(acceptanceExpiresOn, 'MMM d yyyy h:mm:ss a') : '-',
                        },
                        {
                          name: 'Expired on',
                          value:
                            !canStillAccept && !isClosed && !isAccepted
                              ? format(acceptanceExpiresOn, 'MMM d yyyy h:mm:ss a')
                              : '-',
                        },
                      ],
                    }}
                    control={{
                      fields: [
                        {
                          name: 'Created At',
                          value: format(new Date(item.createdAtUtc), 'MMM d yyyy h:mm:ss a'),
                        },
                        {
                          name: 'Buyer',
                          value: (
                            <div className="flex flex-row justify-between">
                              <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
                                {item.buyerUser.firstName} {item.buyerUser.lastName}
                              </Text>
                              <Badge variant={BadgeVariant.Success} value={item.buyerConfirmationStatus} />
                            </div>
                          ),
                        },
                        {
                          name: 'Seller',
                          value: (
                            <div className="flex flex-row justify-between">
                              <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
                                {item.sellerUser.firstName} {item.sellerUser.lastName}
                              </Text>
                              <Badge variant={BadgeVariant.Success} value={item.sellerConfirmationStatus} />
                            </div>
                          ),
                        },
                      ],
                    }}
                  />
                );
              })}
            </>
          ) : tradeRequest.isLoading ? (
            <Loading isOverLay={false} />
          ) : (
            <Empty title="No outgoing requests" description="You have no outgoing requests to trade yet." />
          )}
          <Pagination total={tradeRequest.response?.count || 0} actions={tradeRequest.pagination} />
        </PageSections>
      </PageHolder>
    </>
  );
};
