// eslint-disable-next-line import/prefer-default-export
export enum AssetDepositStatus {
  New = 'NEW',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  ConfirmedByUser = 'CONFIRMED_BY_USER',
  RejectedByUser = 'REJECTED_BY_USER',
}
