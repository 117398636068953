import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback, useEffect, useState } from 'react';
import { queryCache } from 'react-query';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import { DownloadCSV } from 'refreshed-component/molecules/DownloadCSV';
import {
  type FilterDateRangeInput,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { toast } from 'refreshed-component/molecules/toast';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Button, Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { useUserOrders } from 'pages/account/trading/hooks';
import { useCancelOrder } from 'pages/account/trading/hooks/useCancelOrder';

import { Account } from 'state/account';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';

import { fetchUserOrders } from 'data-provider/oms/fetchUserOrders';

import { formatACXDate } from 'utils/helpers';

const { formatNumber } = formatter;

export const SpotOrders = () => {
  const {
    status,
    selector: { getAccountAddress, getUserRootAccount },
  } = User.useContainer();

  const defaultAccountAddress = status.isCorporateMonitor() ? getUserRootAccount() : getAccountAddress();

  const pagination = usePagination();
  const { accountUsers } = Account.useContainer();
  const [includeUser, setIncludeUser] = useState<boolean>(true);
  const [selectedAccountAddress, setSelectedAccountAddress] = useState<string | null>(null);
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  const { getSetting } = UI.useContainer();

  const spotLabel = getSetting('web_settings_spot_label') ?? 'Spot';

  const accountOptions = accountUsers
    ?.filter((account) => account.account)
    ?.map((userAccount: Record<string, any>) => ({
      label: `${userAccount.first_name} ${userAccount.last_name}`,
      id: userAccount.account,
    }));

  const filters: {
    account: FilterRadioBox;
    date: FilterDateRangeInput;
  } = {
    account: {
      type: 'radio-box',
      label: 'Accounts',
      list: accountOptions || [],
    },
    date: {
      type: 'date-range-input',
      label: 'Date range',
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});

  const { orders, isLoading } = useUserOrders({
    page: pagination.page,
    limit: Number(pagination.pageSize) ?? 0,
    status: 'all',
    includeUser,
    startDate: filterSelections?.date?.range?.startDate,
    endDate: filterSelections?.date?.range?.endDate,
    accountAddress: selectedAccountAddress ? selectedAccountAddress : defaultAccountAddress,
  });

  const refreshOrderList = useCallback(() => {
    const filterStatus = 'all';
    queryCache.invalidateQueries(['oms-orders', 0, pagination.page, pagination.pageSize, filterStatus]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize, orders, 0]);

  const refreshOrders = () => {
    // Reset values first
    setSelectedAccountAddress(status.isCorporateMonitor() ? getUserRootAccount() : getAccountAddress());
    setIncludeUser(true);
    refreshOrderList();
  };

  useEffect(() => {
    refreshOrderList();
  }, [refreshOrderList]);

  const { cancelOrder: mutateCancelOrder } = useCancelOrder();

  const cancelOrder = async (orderId: number, status: string) => {
    if (['NEW', 'QUEUED', 'SUBMITTED', 'PARTIALLY FILLED'].includes(status.toUpperCase())) {
      const cancel = await mutateCancelOrder(orderId);
      if (cancel?.id) {
        toast.success(`Order #${orderId} cancellation request has been initiated.`);
        queryCache.invalidateQueries('oms-orders');
      } else {
        const errorResponse = cancel?.json;
        toast.error(errorResponse ? errorResponse.message : 'Something went wrong!');
      }
    } else {
      toast.error('Order is not in open status');
    }
  };

  const orderList = orders?.items;

  const getNonPaginatedData = useCallback(
    () =>
      fetchUserOrders({
        pairId: undefined,
        page: pagination.page,
        limit: Number(pagination.pageSize),
        status: 'all',
        accountAddress: selectedAccountAddress ? selectedAccountAddress : defaultAccountAddress,
        includeUser,
        startDate: filterSelections?.date?.range?.startDate,
        endDate: filterSelections?.date?.range?.endDate,
      }),
    [
      defaultAccountAddress,
      filterSelections?.date?.range?.endDate,
      filterSelections?.date?.range?.startDate,
      includeUser,
      pagination.page,
      pagination.pageSize,
      selectedAccountAddress,
    ],
  );

  const getCSVRows = async () => {
    const allOrders = await getNonPaginatedData();
    return allOrders?.items?.map(
      (item: {
        id: any;
        createdAtUtc: any;
        price: any;
        qty: any;
        filled: any;
        __user__: any;
        __pair__: any;
        __orderSide__: any;
        __status__: any;
        __placedByUser__: any;
      }) => {
        const {
          id,
          createdAtUtc,
          price,
          qty,
          filled,
          __pair__,
          __orderSide__,
          __status__,
          __placedByUser__,
          __user__,
        } = item;
        const placedByValue = () =>
          `${__placedByUser__?.firstName} ${__placedByUser__?.lastName} [${__placedByUser__?.id}]`;
        return {
          ID: id,
          Trader: `${__user__?.firstName} ${__user__?.lastName}`,
          Date: formatACXDate({ date: createdAtUtc, week: false }),
          Pair: __pair__.name,
          Side: __orderSide__.name,
          Price: formatNumber(price, currenciesObjById?.[__pair__?.quoteAssetId]?.numDecimals),
          Quantity: formatNumber(qty, 0),
          Filled: formatNumber(filled, currenciesObjById?.[__pair__?.quoteAssetId]?.numDecimals),
          State: __status__.status,
          'Placed By': __placedByUser__ ? placedByValue() : '',
        };
      },
    );
  };

  const onSelectAccount = (account: string) => {
    if (account) {
      setIncludeUser(false);
    } else {
      setIncludeUser(true);
    }
    setSelectedAccountAddress(account);
  };
  useEffect(() => {
    onSelectAccount(filterSelections?.account?.selection?.toString() || '');
  }, [filterSelections?.account?.selection]);

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title={`${spotLabel} Orders`}
          controls={{
            secondary: <DownloadCSV data={getCSVRows} fileName={'spot-orders-report.csv'} />,
          }}
        />
      </PageSections>
      <PageSections type="card" className="flex flex-col">
        <div className="flex flex-row justify-between items-center gap-base">
          <div className="flex flex-row gap-4 justify-start">
            {/*<Input
              placeholder="Search"
              config={{
                size: 'base',
                color: 'gray',
                postfix: (
                  <HitArea
                    width={20}
                    height={20}
                    className="cursor-pointer"
                    onClick={() => {
                      setSearch('');
                    }}
                  >
                    <Icon type={IconType.X} width={10} height={10} />
                  </HitArea>
                ),
                prefix: <Icon type={IconType.Search} width={14} height={14} />,
              }}
              value={search || ''}
              onChange={(event) => {
                setSearch(event.target.value.trim() || '');
              }}
            />*/}
          </div>
          <div className="flex flex-row justify-start">
            <FilterDropdown
              selections={filterSelections}
              onChange={(value) => setFilterSelections(value)}
              list={filters}
            />
          </div>
        </div>
        <FilterSelections
          selections={filterSelections}
          onChange={(value) => setFilterSelections(value)}
          list={filters}
        />
        {isLoading ? (
          <Loading isOverLay={true} />
        ) : (
          <Table
            config={{
              sticky: {
                left: ['orderId'],
                right: ['action'],
              },
              columns: {
                trader: {
                  label: 'TRADER',
                },
                orderId: {
                  label: 'ORDER ID',
                },
                date: {
                  label: 'DATE',
                },
                pair: {
                  label: 'PAIR',
                },
                side: {
                  label: 'SIDE',
                },
                price: {
                  label: 'PRICE',
                },
                quantity: {
                  label: 'QUANTITY',
                },
                filled: {
                  label: 'FILLED',
                },
                placedBy: {
                  label: 'PLACED BY',
                },
                state: {
                  label: 'STATE',
                },
                action: {
                  label: '',
                },
              },
              rows:
                orderList?.map((order: Record<string, any>) => {
                  const {
                    id,
                    createdAtUtc,
                    price,
                    qty,
                    filled,
                    __pair__,
                    __orderSide__,
                    __status__,
                    __user__,
                    __placedByUser__,
                    __orderType__,
                  } = order;
                  const state = __status__.status;
                  const placedByValue = () =>
                    `${__placedByUser__?.firstName} ${__placedByUser__?.lastName} [${__placedByUser__?.id}]`;

                  return {
                    _key: id,
                    orderId: id,
                    trader: `${__user__?.firstName} ${__user__?.lastName}`,
                    date: formatACXDate({ date: createdAtUtc, week: false }),
                    pair: __pair__.name,
                    side:
                      (__orderSide__.name as string).toLocaleLowerCase() === 'buy' ? (
                        <Text variant={TypographyVariant.body2} color={TextColor.success}>
                          Buy
                        </Text>
                      ) : (
                        <Text variant={TypographyVariant.body2} color={TextColor.error}>
                          Sell
                        </Text>
                      ),
                    price:
                      __orderType__?.name !== 'Market'
                        ? formatNumber(price, currenciesObjById?.[__pair__?.quoteAssetId]?.numDecimals)
                        : 'MKT',
                    quantity: formatNumber(qty, 0),
                    filled: formatNumber(filled, currenciesObjById?.[__pair__?.quoteAssetId]?.numDecimals),
                    placedBy: __placedByUser__ ? placedByValue() : '',
                    state: <Text variant={TypographyVariant.body2}>{state}</Text>,
                    action: status?.canTradeSpot() && (
                      <>
                        {['NEW', 'QUEUED', 'SUBMITTED', 'PARTIALLY FILLED'].includes(state.toUpperCase()) && (
                          <ConfirmModal
                            title={`Cancel Order #${id}`}
                            accept={{
                              label: 'Proceed',
                              callback(props) {
                                props.onLoading(true);
                                cancelOrder(id, state)
                                  .then(() => {
                                    props.onLoading(false);
                                    props.onClose();
                                    refreshOrders();
                                  })
                                  .catch(() => {
                                    props.onLoading(false);
                                  });
                                return false;
                              },
                            }}
                            action={<Button>Cancel Order</Button>}
                          >
                            <div className="flex flex-col items-center gap-base">
                              <Text variant={TypographyVariant.subtitle2}>This action will cancel order #{id}.</Text>
                            </div>
                          </ConfirmModal>
                        )}
                      </>
                    ),
                  };
                }) || [],
            }}
          />
        )}
        <Pagination total={orders?.total ?? 0} actions={pagination} />
      </PageSections>
    </PageHolder>
  );
};
