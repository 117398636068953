import { useCallback, useEffect, useState } from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, Spacing } from 'refreshed-component/design-system';
import { type FilterCheckBox, FilterDropdown, FilterSelections } from 'refreshed-component/molecules/Filter';
import { toast } from 'refreshed-component/molecules/toast';
import { Table } from 'refreshed-component/templates/Table';

import { Button, ButtonSize, ButtonVariant } from '@aircarbon/ui';
import { formatter, logger } from '@aircarbon/utils-common';
import type { Pair } from '@aircarbon/utils-common/src/dto';

import { Themes } from 'pages/account/trading/components/Themes';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import Pagination from 'components/Pagination';
import Loading from 'components/styled/Loading';

import { Contract } from 'state/contract';
import { Entity } from 'state/entity';
import { User } from 'state/user';

import useCarbonFinderRealtimeMessage from 'hooks/useCarbonFinderRealtimeMessage';

import { fetchRFQBids } from 'data-provider/rfq/fetchRFQBids';

import Criteria from '../Criteria';
import ViewRFQ from '../View';
import { BidExpandClose, BidExpandWrapper, Wrapper } from './styled';

interface Props {
  userId: number;
  addr: string;
  filter: string;
  onSelect: (rfqId: number | null) => void;
  pairsWatchList?: Partial<Pair[]>;
}

const BidExpand = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => {
  return (
    <BidExpandWrapper>
      <BidExpandClose onClick={onClose}>
        <svg width={24} height={24} viewBox="0 0 24 24">
          <path
            fill="currentcolor"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
          />
        </svg>
      </BidExpandClose>
      {children}
    </BidExpandWrapper>
  );
};

function RFQList({ userId, addr, filter, onSelect, pairsWatchList }: Props) {
  const { tokenTypesByScId } = Contract.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol, mainCcyScId },
  } = Entity.useContainer();
  const realtimeMessage = useCarbonFinderRealtimeMessage();
  const { themeMode } = Themes.useContainer();
  const {
    status: { canTradeRFQ },
    selector: { getAuthToken },
  } = User.useContainer();
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const [selectRfq, setSelectRfq] = useState<{
    id: number;
    type: string;
    tokenTypeId: number;
    ccyTypeId: number;
    quantity: number;
    filled: number;
    price: number;
    createdAt: Date;
    userId: number;
    __rfqRequestDetails__: Array<any>;
    __rfqResponses__: Array<any>;
  } | null>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterStatus, setFilterStatus] = useState<Array<string>>([]);

  const onChangeFilterStatus = (newFilterStatuses: Array<string>) => {
    setPage(1);
    setFilterStatus(newFilterStatuses);
  };

  // reset to page 1 when change filter
  useEffect(() => {
    setPage(1);
  }, [filter]);

  const { data } = useQuery(['rfq-list', page, limit, filter, filterStatus], () =>
    fetchRFQBids({
      page,
      limit,
      filter,
      filterStatus,
      status: 'OPEN',
      userId,
      ccyTypeId: mainCcyScId,
    }),
  );

  const refreshList = useCallback(
    () => queryCache.invalidateQueries(['rfq-list', page, limit, filter, filterStatus]),
    [filter, filterStatus, limit, page],
  );

  // Refresh list when realtime message received
  useEffect(() => {
    const isRFQListed = data?.items?.find((item) => item.id === realtimeMessage?.rfqMessages?.id);
    if (realtimeMessage?.rfqMessages?.type === 'RFQ_UPDATE' && isRFQListed) {
      refreshList();
    }
  }, [data?.items, realtimeMessage, refreshList]);

  const [cancelRFQRequest] = useMutation(
    async (rfqId: number) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/user/rfq/${rfqId}/cancel`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          logger.warn(data);
          refreshList();
          toast.default('Your RFQ request has been cancelled.');
          queryCache.invalidateQueries('account-balance');
        } else {
          logger.warn(data);
          const error = await data.json();
          toast.error(error?.message ?? 'Something went wrong!');
          logger.warn({ error });
        }
      },
      onError: (error: Error) => {
        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  const filters: {
    status: FilterCheckBox;
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 'OPEN',
          label: 'OPEN',
        },
        {
          id: 'DONE',
          label: 'DONE',
        },
        {
          id: 'CANCELLED',
          label: 'CANCELLED',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();

  useEffect(() => {
    onChangeFilterStatus(filterSelections?.status?.selection?.map((item) => item.toString()) || []);
  }, [filterSelections?.status]);

  if (!data || isLoadingMarketSettings) return <Loading />;

  const noBidsMessage = filter === 'bids-to-me' ? 'No bids matching your inventory.' : 'No bids to show.';

  const rfqItems =
    filter === 'bids-to-me'
      ? data?.items?.filter(
          (item) =>
            pairsWatchList?.find((pair) => pair?.baseAsset?.scId === item.tokenTypeId)?.marketFlags?.showInRfq === 1,
        )
      : data?.items;

  // TODO: Disable RQF if the balance is not enough
  return (
    <Wrapper>
      {!selectRfq && (
        <div className="flex flex-col p-large gap-large">
          {(marketSettings?.rfqOrderMatchingEnabled === 0 || marketSettings?.rfqOrderCancelationEnabled === 0) && (
            <div className="mb-1 bg-red-500">
              {marketSettings?.rfqOrderMatchingEnabled === 0 && <div className="ml-1">BID Matching is disabled</div>}
              {marketSettings?.rfqOrderCancelationEnabled === 0 && (
                <div className="ml-1">BID Cancellation is disabled</div>
              )}
            </div>
          )}
          <div className="flex flex-col items-stretch gap-medium">
            {filter === 'my-bids' && (
              <>
                <div className="flex flex-row justify-between items-center gap-base">
                  <div className="flex flex-row justify-center items-center">
                    <Text>My Bids</Text>
                  </div>
                  <FilterDropdown
                    selections={filterSelections}
                    onChange={(value) => setFilterSelections(value)}
                    list={filters}
                  />
                </div>
                <FilterSelections
                  selections={filterSelections}
                  onChange={(value) => setFilterSelections(value)}
                  list={filters}
                />
              </>
            )}
            {filter === 'bids-to-me' && (
              <>
                <div className="flex flex-row justify-between items-center gap-base">
                  <div className="flex flex-row justify-center items-center">
                    <Text>Bids I Can Fill</Text>
                  </div>
                  <div className="opacity-0 pointer-events-none">
                    <FilterDropdown
                      selections={filterSelections}
                      onChange={(value) => setFilterSelections(value)}
                      list={filters}
                    />
                  </div>
                </div>
              </>
            )}
            {data?.total > limit && (
              <div className="my-2 pagination-wrapper">
                <Pagination
                  theme={themeMode === 'dark' ? 'dark' : undefined}
                  itemType="items"
                  page={page}
                  setPage={(page, limit) => {
                    setPage(page);
                    setLimit(Number(limit));
                  }}
                  limit={limit}
                  recordCount={data?.total}
                />
              </div>
            )}
          </div>
          {rfqItems?.length === 0 ? (
            <div className="p-base">{noBidsMessage}</div>
          ) : (
            <>
              <Table
                config={{
                  sticky: {
                    left: ['id'],
                    right: ['status', 'action'],
                  },
                  columns: {
                    id: {
                      label: 'ID',
                    },
                    date: {
                      label: 'DATE',
                    },
                    userId: {
                      label: 'USER ID',
                    },
                    quantity: {
                      label: 'QUANTITY',
                    },
                    filledQty: {
                      label: 'FILLED QUANTITY',
                    },
                    openQty: {
                      label: 'OPEN QUANTITY',
                    },
                    price: {
                      label: `PRICE (${mainCcySymbol})`,
                    },
                    total: {
                      label: `TOTAL (${mainCcySymbol})`,
                    },
                    criteria: {
                      label: 'CRITERIA',
                    },
                    status: {
                      label: 'STATUS',
                    },
                    action: {
                      label: 'ACTION',
                    },
                  },
                  rows:
                    rfqItems
                      .filter((item) => !(item.status === 'DONE' && userId !== item.userId))
                      ?.map((item) => {
                        const filledQty = item.filled;
                        const remaining = item.quantity - filledQty;
                        let status = item.status;
                        if (remaining === 0 && status === 'OPEN') status = 'FILLING';
                        let statusContent;
                        if (status === 'OPEN') {
                          statusContent = (
                            <Text
                              background={Colors.success_100}
                              color={Colors.success_700}
                              spacingLR={Spacing.small}
                              spacingTB={Spacing.xs}
                              size={FontSize.small}
                            >
                              {status}
                            </Text>
                          );
                        } else if (status === 'FILLING' || status === 'CANCELLED' || status === 'PENDING') {
                          statusContent = (
                            <Text
                              background={Colors.danger_100}
                              color={Colors.danger_700}
                              spacingLR={Spacing.small}
                              spacingTB={Spacing.xs}
                              size={FontSize.small}
                            >
                              {status}
                            </Text>
                          );
                        } else {
                          statusContent = (
                            <Text
                              background={Colors.gray_100}
                              color={Colors.gray_500}
                              spacingLR={Spacing.small}
                              spacingTB={Spacing.xs}
                              size={FontSize.small}
                            >
                              {status}
                            </Text>
                          );
                        }

                        return {
                          _key: item.id.toString(),
                          id: `#${item.id}`,
                          date: (
                            <span className="font-bold">
                              {new Date(item.createdAt).toLocaleString('en-SG', { hour12: true }).replace(',', '')}
                            </span>
                          ),
                          userId: item.userId,
                          quantity: formatter.formatNumber(item.quantity, 0),
                          filledQty: formatter.formatNumber(filledQty, 0),
                          openQty: formatter.formatNumber(item.quantity - filledQty, 0),
                          price: (
                            <>
                              {mainCcyCode}
                              {formatter.formatNumber(item.price, mainCcyNumDecimals)}
                            </>
                          ),
                          total: (
                            <>
                              {mainCcyCode}
                              {formatter.formatNumber(item.quantity * item.price, mainCcyNumDecimals)}
                            </>
                          ),
                          status: statusContent,
                          criteria: (
                            <Criteria
                              filters={[
                                {
                                  id: 'tokenTypeId',
                                  filterName: 'Token',
                                  filterValue:
                                    (item?.tokenTypeId ?? 0) > 0
                                      ? tokenTypesByScId()?.[item?.tokenTypeId ?? 0]?.symbol
                                      : 'ANY',
                                },
                                ...item.__rfqRequestDetails__,
                              ]}
                            />
                          ),
                          action: (
                            <>
                              {canTradeRFQ() && (
                                <div>
                                  {![item.userId, item.placedBy].includes(userId) && (
                                    <Button
                                      variant={ButtonVariant.secondary}
                                      size={ButtonSize.s}
                                      isDisabled={marketSettings?.rfqOrderMatchingEnabled === 0}
                                      onPress={() => {
                                        onSelect(item.id);
                                        setSelectRfq(item);
                                      }}
                                    >
                                      Fill
                                    </Button>
                                  )}
                                  {[item.userId, item.placedBy].includes(userId) &&
                                    item.status !== 'CANCELLED' &&
                                    filledQty === 0 && (
                                      <Button
                                        variant={ButtonVariant.outlined}
                                        size={ButtonSize.s}
                                        isDisabled={marketSettings?.rfqOrderCancelationEnabled === 0}
                                        onPress={() => cancelRFQRequest(item.id)}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                  {userId === item.userId && filledQty > 0 && (
                                    <Button
                                      variant={ButtonVariant.secondary}
                                      size={ButtonSize.s}
                                      onPress={() => {
                                        onSelect(item.id);
                                        setSelectRfq(item);
                                      }}
                                    >
                                      View
                                    </Button>
                                  )}
                                </div>
                              )}
                            </>
                          ),
                        };
                      }) || [],
                }}
              />
              {data?.total > limit && (
                <div className="flex items-center mt-2">
                  <div className="pagination-wrapper">
                    <Pagination
                      theme={themeMode === 'dark' ? 'dark' : undefined}
                      itemType="items"
                      page={page}
                      setPage={(page, limit) => {
                        setPage(page);
                        setLimit(Number(limit));
                      }}
                      limit={limit}
                      recordCount={data?.total}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {selectRfq && (
        <BidExpand
          onClose={() => {
            onSelect(null);
            setSelectRfq(null);
          }}
        >
          <ViewRFQ
            addr={addr}
            rfqId={selectRfq?.id}
            tokenTypeId={selectRfq?.tokenTypeId}
            isRFQOwner={userId === selectRfq.userId}
            rfqOrderCancelationEnabled={marketSettings?.rfqOrderCancelationEnabled}
            onSubmitAccept={() => setSelectRfq(null)}
            cancelRFQRequest={cancelRFQRequest}
          />
        </BidExpand>
      )}
    </Wrapper>
  );
}

export default RFQList;
