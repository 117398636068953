import { InputText, InputTextArea, Select, SelectCountry, Text, TypographyVariant } from '@aircarbon/ui';

import { FieldWrapper } from 'components/styled/Styled';

export interface BasicDetailsValue {
  id: string;
  projectName: string;
  projectDescription: string;
  projectLocation: string;
  projectPhase: string;
  methodology: string;
  customMethodology: string;
  projectType: string;
  standardUsed: string;
  projectId: string;
  sdgGoals: Array<string>;
  sectoralScope: string;
}

export interface BasicDetailsMetadata {
  countries: { name: string; countryCode: string; phoneCode: string }[];
  projectPhases: {
    title: string;
    value: string;
  }[];
  registries: {
    title: string;
    value: string;
  }[];
  methodologies: {
    title: string;
    value: string;
  }[];
  projectTypes: {
    value: string;
    title: string;
  }[];
  sectoralScopes: {
    value: string;
    title: string;
  }[];
  sdgGoals: {
    value: string;
    title: string;
  }[];
  additonalCertifications: { id: string; label: string }[];
  internalGradesEligibilities: { id: string; label: string }[];
}

export const BasicDetails: React.FC<{
  value: BasicDetailsValue;
  metadata: BasicDetailsMetadata;
  error?: Record<string, string>;
  onChange(value: BasicDetailsValue): void;
}> = (props) => {
  const { value, onChange, metadata, error } = props;

  const onChangeFormValue =
    <K extends keyof typeof value>(key: K) =>
    (fieldValue: (typeof value)[K]) => {
      const newValue = {
        ...value,
        [key]: fieldValue,
      };
      onChange(newValue);
    };

  const onChangeMethodology = ({ value: updatedValue }: { value: string }) => {
    onChange({
      ...value,
      customMethodology: '',
      methodology: updatedValue,
    });
  };

  return (
    <>
      {!value.id && (
        <div className="mb-6">
          <Text variant={TypographyVariant.h4Title}>List New Carbon Project</Text>
          <Text variant={TypographyVariant.body1}>Offer Pre-Issued Carbon Credits on the Exchange</Text>
        </div>
      )}
      <FieldWrapper>
        <InputText
          label="Project Name*"
          value={value.projectName}
          onChange={(e) => onChangeFormValue('projectName')(e.target.value)}
          error={error?.projectName}
        />
      </FieldWrapper>
      <FieldWrapper>
        <InputTextArea
          label="Project Description"
          value={value.projectDescription}
          onChange={(e) => onChangeFormValue('projectDescription')(e.target.value)}
        />
      </FieldWrapper>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <SelectCountry
              label="Project Location*"
              placeholder="Select"
              countries={metadata.countries}
              onChange={({ value }) => onChangeFormValue('projectLocation')(value)}
              value={value.projectLocation}
              error={error?.projectLocation}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Select
              label="Project Phase*"
              placeholder="Select"
              value={value.projectPhase}
              items={metadata.projectPhases}
              onChange={({ value }) => onChangeFormValue('projectPhase')(value)}
              error={error?.projectPhase}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Select
              items={metadata.registries}
              placeholder="Select"
              label="Standard Used / Registry*"
              value={value.standardUsed}
              error={error?.standardUsed}
              onChange={({ value }) => {
                onChangeFormValue('standardUsed')(value);
              }}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <InputText
              label="Project ID (If registered)"
              placeholder="ID#"
              value={value.projectId}
              onChange={(e) => onChangeFormValue('projectId')(e.target.value)}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Select
              onChange={onChangeMethodology}
              label="Proposed Methodology*"
              placeholder="Select"
              items={metadata.methodologies}
              value={value.customMethodology ? value.customMethodology : value.methodology ? value.methodology : ''}
              error={error?.customMethodology || error?.methodology}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Select
              label="Project Type*"
              placeholder="Select"
              items={metadata.projectTypes}
              value={value.projectType}
              error={error?.projectType}
              onChange={({ value }) => onChangeFormValue('projectType')(value)}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Select
              label="Sectoral Scope"
              items={metadata.sectoralScopes}
              value={value.sectoralScope}
              placeholder="Select"
              onChange={({ value }) => onChangeFormValue('sectoralScope')(value)}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Select
              label="SDG Goals (if any)"
              isMultiple
              placeholder="Select"
              value={value.sdgGoals.join(',')}
              items={metadata.sdgGoals}
              onChange={({ value }) => onChangeFormValue('sdgGoals')(value.split(','))}
            />
          </FieldWrapper>
        </div>
      </div>
    </>
  );
};
