import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import { PoweredACXByContent } from 'refreshed-component/molecules/Footer';
import { generatePath } from 'refreshed-component/templates/ContentHolder';
import styled from 'styled-components';

import { ButtonSize, ButtonVariant, Divider, IconName, Knob, SidebarMenu as UISidebarMenu } from '@aircarbon/ui';

import { Themes } from 'pages/account/trading/components/Themes';

import { Entity } from 'state/entity';

import Popover from '../atoms/Popover';

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  gap: var(${Spacing.small});
  line-height: 21px;
  outline: rgba(0, 0, 0, 0) solid 0px !important;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out,
    outline-color 200ms ease-in-out;
  padding: 8px 12px;
  font-size: var(${FontSize.xs});
  font-weight: var(${FontWeight.default});
  border-radius: var(${Radius.medium});
  overflow: hidden;
  > .children {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    gap: var(${Spacing.small});
    overflow: hidden;
    line-height: 21px;
    text-overflow: ellipsis;
  }
  &.size-base {
    padding: var(${Spacing.xs});
    height: 36px;
    width: 36px;
  }
`;

const SideBarRoot = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(${Colors.sidebarBackground});
  height: 100%;
  width: 100%;
  overflow: hidden;
  > .title {
    display: flex;
    padding-left: var(${Spacing.small});
    padding-right: var(${Spacing.small});
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    > div.handle {
      width: 40px;
      height: 40px;
      transition: all 100ms ease-in-out;
      transform: rotate(180deg);
      svg path {
        fill: var(${Colors.sidebarMainMenuContentDefault}) !important;
      }
      background-color: var(${Colors.sidebarMainMenuSurfaceHover}) !important;
    }
  }

  .handle-collapse-holder {
    .expand-collapse {
      font-size: var(${FontSize.base}) !important;
      color: var(${Colors.sidebarMainMenuContentSelect}) !important;
      > .handle {
        transition: all 100ms ease-in-out;
        svg path {
          fill: var(${Colors.sidebarMainMenuContentDefault}) !important;
        }
      }
    }
  }

  > .menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    > div.item {
      &:hover {
        background-color: var(${Colors.sidebarMainMenuSurfaceHover}) !important;
      }
      > .icon {
        width: 18px;

        > svg {
          fill: var(${Colors.sidebarSubMenuContentDefault}) !important;
          path {
            fill: var(${Colors.sidebarSubMenuContentDefault}) !important;
          }
        }
      }
      &.selected {
        background-color: var(${Colors.sidebarMainMenuSurfaceSelect}) !important;
        fill: var(${Colors.sidebarSubMenuContentSelect}) !important;
        > .icon > svg {
          path {
            fill: var(${Colors.sidebarSubMenuContentSelect}) !important;
          }
        }
      }
    }
    > .separator {
      min-height: 1px;
      align-self: stretch;
      margin-top: var(${Spacing._2xs});
      margin-bottom: var(${Spacing._2xs});
      background-color: var(${Colors.sidebarSeparator}, ${Colors.gray_200});
    }
    > .item {
      display: flex;
      width: 100%;
      font-size: var(${FontSize.base});
      color: #575d33;
      &.selected {
        color: var(${Colors.gray_900});
      }
    }
  }
  > .footer {
    height: auto;
    width: 100%;
    &:empty {
      display: none;
    }
    > .item {
      font-size: var(${FontSize.xs});
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(${Spacing.xs});
      svg.coverage {
        path {
          fill: var(-${Colors.success_700}) !important;
        }
      }
    }
  }

  &.expanded {
    padding-top: var(${Spacing.large});
    padding-left: var(${Spacing.small});
    padding-right: var(${Spacing.small});
    padding-bottom: var(${Spacing.small});

    gap: var(${Spacing.large});
    > .title {
      flex-direction: row;
      gap: var(${Spacing.base});
      align-items: flex-start;
      > div.handle {
        transition: all 100ms;
        transform: rotate(0deg);
      }
    }
    > .menu {
      gap: var(${Spacing.small});
      > div.item {
        transition: all 100ms;
        > .arrow {
          transform: rotate(0deg);
        }
        &.expanded {
          > .arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.collapsed {
    padding-top: var(${Spacing.base});
    padding-left: var(${Spacing.small});
    padding-right: var(${Spacing.small});
    padding-bottom: var(${Spacing.small});
    gap: var(${Spacing.small});
    align-items: center;
    > .title {
      flex-direction: column;
      gap: var(${Spacing.large});
      > div.handle {
        transition: all 100ms ease-in-out;
        transform: rotate(180deg);
      }
    }
    > .menu {
      gap: var(${Spacing.small});
      > div.item {
        width: 40px;
        height: 40px;
        transition: all 100ms;
        > .children {
          display: none;
        }
        > .arrow {
          display: none;
        }
      }
    }
    div.handle {
      transition: all 100ms;
      transform: rotate(0deg);
    }
  }
`;

export type SideBarMenuItemProps = {
  title: string;
  path?: string;
  query?: { [key: string]: string };
  isSelected?: boolean;
};

export type SideBarMenuProps = { isParentExpanded?: boolean; onClickItem?: () => void } & (
  | {
      type: 'Separator';
    }
  | ({
      path?: string;
      query?: {
        [key: string]: string;
      };
      title: string;
      icon: IconName;
    } & (
      | {
          type: 'list';
          list?: SideBarMenuItemProps[];
        }
      | {
          type: 'link';
          path?: string;
          query?: { [key: string]: string };
          isSelected?: boolean;
        }
    ))
);

export type SideBarProps = {
  menu: SideBarMenuProps[];
  isHeaderVisible: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  onCollops?: () => void;
  inOverlay: boolean;
};

const ItemListRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition:
    max-height 100ms ease-in-out,
    margin-bottom 100ms ease-in-out;
  &.collapsed {
    max-height: 0;
    margin-bottom: calc(-1 * var(${Spacing.small}));
    overflow: hidden;
  }
  &.expanded {
    max-height: 1000px;
    overflow: hidden;
    flex-shrink: 0;
  }
  > .holder {
    display: flex;
    flex-direction: column;
    height: auto;
    > .title {
      color: var(${Colors.sidebarSubMenuContentDefault});
      width: 100%;
      min-width: 200px;
      font-size: var(${FontSize.large});
      padding: var(${Spacing.xs});
      border-bottom: 1px solid var(${Colors.gray_300});
      margin-bottom: var(${Spacing.xs});
      font-weight: var(${FontWeight.medium});
    }
    > .item {
      display: flex;
      width: 100%;
      font-size: var(${FontSize.base});
      gap: var(${Spacing.xs});
      color: var(${Colors.sidebarSubMenuContentDefault});
      &.selected {
        color: var(${Colors.sidebarSubMenuContentSelect});
      }
      &:hover {
        background-color: var(${Colors.sidebarMainMenuSurfaceHover}) !important;
      }
    }
    > .label {
      display: flex;
      width: 100%;
      font-size: var(${FontSize.base});
      gap: var(${Spacing.xs});
      color: var(${Colors.sidebarSubMenuContentDefault});
      padding-left: var(${Spacing.xs});
      padding-right: var(${Spacing.xs});
    }
  }
`;

const SideBarMenuItemList = ({
  isExpanded,
  inPopover,
  title,
  list,
  onClickItem,
}: {
  isExpanded?: boolean;
  inPopover?: boolean;
  title?: string;
  list: SideBarMenuItemProps[];
  onClickItem?: () => void;
}) => {
  const history = useHistory();
  return (
    <ItemListRoot className={`${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="holder">
        {title && (
          <div
            onClick={() => {
              const firstItem = list[0];
              if (firstItem) {
                firstItem.path && history.push(generatePath(firstItem.path, firstItem.query));
                onClickItem?.();
              }
            }}
            className="cursor-pointer title"
          >
            {title}
          </div>
        )}
        {list.map((item) => {
          const isSelected = item.isSelected;
          return (
            <MenuButton
              key={`${item.title}-${item.path}`}
              onClick={() => {
                item.path && history.push(generatePath(item.path, item.query));
                onClickItem?.();
              }}
              className={`item ${isSelected ? 'selected' : 'deselected'}`}
            >
              <div className="children">
                {inPopover ? undefined : (
                  <div className="icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"></svg>
                  </div>
                )}{' '}
                <div className="w-full text-left">{item.title}</div>
              </div>
            </MenuButton>
          );
        })}
      </div>
    </ItemListRoot>
  );
};

const SideBarMenu = (props: SideBarMenuProps) => {
  const isListItemSelected =
    props.type === 'link'
      ? props.isSelected
      : props.type === 'list' && props.list?.map((item) => item.isSelected).find((isSelected) => isSelected);

  const [isExpanded, setExpand] = useState(isListItemSelected);
  const history = useHistory();

  if (props.type === 'Separator') {
    return <Divider />;
  }

  const onPressMenu = () => {
    if (props.type === 'list') {
      if (!props.isParentExpanded) {
        const page = props.list?.[0];
        page?.path && history.push(generatePath(page.path, page.query));
        props.onClickItem?.();
      } else {
        setExpand(!isExpanded);
      }
    } else {
      props.path && history.push(generatePath(props.path, props.query));
      props.onClickItem?.();
    }
  };

  const menu = (
    <UISidebarMenu
      title={props.title}
      isActive={isListItemSelected && props.type !== 'list'}
      isParent={props.type === 'list'}
      isExpanded={isExpanded}
      onPress={onPressMenu}
      icon={props.icon}
    />
  );

  return (
    <>
      {props.isParentExpanded ? (
        menu
      ) : props.type === 'list' && props.list ? (
        <Popover
          placement={'rightTop'}
          view="sidebarMenu"
          content={() =>
            props.list && (
              <SideBarMenuItemList isExpanded={true} title={props.title} inPopover={true} list={props.list} />
            )
          }
        >
          <div>
            <UISidebarMenu
              title={props.title}
              isActive={isListItemSelected}
              isParent={props.type === 'list'}
              isExpanded={isExpanded}
              isCompact
              onPress={onPressMenu}
              icon={props.icon}
            />
          </div>
        </Popover>
      ) : (
        <Popover
          placement={'rightTop'}
          view="sidebarMenu"
          content={() => (
            <ItemListRoot
              onClick={() => {
                props.path && history.push(generatePath(props.path, props.query));
                props.onClickItem?.();
              }}
              className={`expanded cursor-pointer`}
            >
              <div className="holder">
                <div className={`label`}>
                  <div className="w-full text-left">{props.title}</div>
                </div>
              </div>
            </ItemListRoot>
          )}
        >
          <div>
            <UISidebarMenu
              title={props.title}
              isActive={isListItemSelected}
              isParent={props.type === 'list'}
              isExpanded={isExpanded}
              isCompact
              onPress={onPressMenu}
              icon={props.icon}
            />
          </div>
        </Popover>
      )}
      {props.type === 'list' && props.list && props.isParentExpanded && isExpanded && (
        <ItemListRoot className={`${isExpanded ? 'expanded' : 'collapsed'}`}>
          {props.list.map((childMenu) => (
            <UISidebarMenu
              title={childMenu.title}
              isActive={childMenu.isSelected}
              key={`${childMenu.title}-${childMenu.path}`}
              onPress={() => {
                childMenu.path && history.push(generatePath(childMenu.path, childMenu.query));
                props.onClickItem?.();
              }}
            />
          ))}
        </ItemListRoot>
      )}
    </>
  );
};

const MiniLogo = styled.div<{ logoUrl: string }>`
  min-height: 36px;
  min-width: 36px;
  background-image: url(${(props) => props.logoUrl});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  cursor: pointer;
`;

export const SideBar: React.FC<SideBarProps> = (props) => {
  const { isExpanded, isHeaderVisible } = props;
  const { entity } = Entity.useContainer();
  const { themeMode } = Themes.useContainer();
  const history = useHistory();

  const isFinallyExpanded =
    props.isExpanded !== undefined && props.isExpandable === false ? props.isExpanded : isExpanded;

  return (
    <SideBarRoot className={`${isFinallyExpanded ? 'expanded' : 'collapsed'}`}>
      {isHeaderVisible && (
        <div className="title">
          {isFinallyExpanded ? (
            <div
              key={'max'}
              className="flex-1 cursor-pointer"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => history.push('/account/balances')}
            >
              <img src={themeMode === 'dark' ? entity.theme.web.logoSidebarDark : entity.theme.web.logoSidebarLight} />
            </div>
          ) : (
            <MiniLogo
              key="min"
              onClick={() => history.push('/account/balances')}
              logoUrl={
                themeMode === 'dark' ? entity.theme.web.logoSidebarMiniDark : entity.theme.web.logoSidebarMiniLight
              }
            />
          )}
          {props.onCollops && (
            <Knob
              className="children"
              icon={IconName.ArrowStart}
              size={ButtonSize.s}
              variant={ButtonVariant.outlined}
              onPress={props.onCollops}
            />
          )}
        </div>
      )}
      <div className="menu">
        {props.menu.map((item, index) => {
          return (
            <SideBarMenu
              key={index}
              {...item}
              isParentExpanded={isFinallyExpanded}
              onClickItem={() => props.onCollops?.()}
            />
          );
        })}
      </div>
      {props.inOverlay && (
        <div className="footer">
          <div className="item">{PoweredACXByContent}</div>
        </div>
      )}
    </SideBarRoot>
  );
};
