import 'bootstrap-daterangepicker/daterangepicker.css';
import { format } from 'date-fns';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Button } from 'refreshed-component/atoms/Button';
import { CheckBox } from 'refreshed-component/atoms/CheckBox';
import { Toggle } from 'refreshed-component/atoms/Toggle';
import Loading from 'refreshed-component/molecules/Loading';

import {
  BorderColor,
  Icon,
  IconName,
  InputText,
  InputTextType,
  Select,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
} from '@aircarbon/ui';

import { FieldWrapper } from 'pages/account/Baskets/RetireBasketForm';

import { AssetsAllocationTable } from '../AssetsAllocationTable';
import { ProjectInformation } from './components/ProjectInformation';

export interface RetireBatchFormValue {
  retirementReason: string;
  isPublicRetirement: boolean;
  retirementBeneficiary: string;
  offsettingPeriod: {
    from?: Date;
    to?: Date;
  };
  offsetScopes: Record<number, boolean>;
  otherNotes: string;
  quantity: string;
}

const toQuantityError = (errors: Record<string, string>) => {
  if (errors?.['formValue.quantity']) {
    return errors?.['formValue.quantity'];
  }

  const allocationsErrorsKeys = Object.keys(errors).filter((key) => key.includes('assetsBalances['));
  if (!allocationsErrorsKeys.length) {
    return '';
  }

  return errors[allocationsErrorsKeys[0]];
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const numberFormatter = new Intl.NumberFormat('en-US');

export const RetireBatchForm: React.FC<{
  value: RetireBatchFormValue;
  batchInformation: {
    batchId: number;
    quantity: number;
  };
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  transactionFee: {
    total: number;
    isLoading: boolean;
  };
  errors: Record<string, string>;
  onChange(value: RetireBatchFormValue): void;
}> = (props) => {
  const { value, assets, errors, transactionFee, batchInformation, onChange } = props;
  const quantityError = toQuantityError(errors);

  const onChangeFormValue =
    <K extends keyof typeof value>(key: K) =>
    (newValue: (typeof value)[K]) => {
      onChange({
        ...value,
        [key]: newValue,
      });
    };

  const onChangeScope = (key: number) => () => {
    onChange({
      ...value,
      offsetScopes: {
        ...value.offsetScopes,
        [key]: !value.offsetScopes[key],
      },
    });
  };

  return (
    <StyledRetireBatchForm>
      <ProjectInformation
        quantity={numberFormatter.format(batchInformation.quantity)}
        batchId={String(batchInformation.batchId)}
      />
      <FieldWrapper>
        <Select
          label="Retirement Reason*"
          placeholder="Select"
          items={[
            {
              title: 'Voluntary',
              value: 'Voluntary',
            },
            {
              title: 'Compliance',
              value: 'Compliance',
            },
          ]}
          onChange={({ value }) => {
            onChangeFormValue('retirementReason')(value);
          }}
        />
      </FieldWrapper>
      <MakePublicRetirement>
        <Toggle
          id={'isPublic'}
          label={'Make Retirement Public ?'}
          isSelected={value.isPublicRetirement}
          onClick={() => onChangeFormValue('isPublicRetirement')(!value.isPublicRetirement)}
        />
      </MakePublicRetirement>
      <BeneficiaryAndOfsettingPeriod>
        <FieldWrapper>
          <InputText
            label="Beneficiary"
            value={value.retirementBeneficiary}
            onChange={(e) => onChangeFormValue('retirementBeneficiary')(e.target.value)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Text variant={TypographyVariant.body2}>Offsetting Period*</Text>
          <DateRangePicker
            maxDate={moment(moment('2200-01-01'))}
            minDate={moment('1970-01-01')}
            showDropdowns={true}
            alwaysShowCalendars
            startDate={value.offsettingPeriod.from}
            endDate={value.offsettingPeriod.to}
            onApply={(_evt, picker) => {
              onChangeFormValue('offsettingPeriod')({
                from: picker.startDate.toDate(),
                to: picker.endDate.toDate(),
              });
            }}
          >
            <StyledOffsettingPeriodButton
              config={{
                color: 'gray',
                size: 'sm',
                border: true,
                icon: {
                  left: <Icon name={IconName.Calendar} size="1.25rem" color={TextColor.disabled} />,
                },
              }}
            >
              {!!value.offsettingPeriod.from && !!value.offsettingPeriod.to ? (
                <Text className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">
                  {format(new Date(value.offsettingPeriod.from), 'MMM d yyyy')} -{' '}
                  {format(new Date(value.offsettingPeriod.to), 'MMM d yyyy')}
                </Text>
              ) : (
                <Text>Select date period</Text>
              )}
            </StyledOffsettingPeriodButton>
          </DateRangePicker>
          {!!(errors?.['formValue.offsettingPeriod.from'] || errors?.['formValue.offsettingPeriod.to']) && (
            <Text color={TextColor.error} variant={TypographyVariant.caption}>
              {errors?.['formValue.offsettingPeriod.from'] || errors?.['formValue.offsettingPeriod.to']}
            </Text>
          )}
        </FieldWrapper>
      </BeneficiaryAndOfsettingPeriod>
      <Text variant={TypographyVariant.subtitle1}>Offset Scope</Text>
      <OffsetScopes>
        <CheckBox id={'1'} label="Scope 1" isSelected={value.offsetScopes[1]} onClick={onChangeScope(1)} />
        <CheckBox id={'1'} label="Scope 2" isSelected={value.offsetScopes[2]} onClick={onChangeScope(2)} />
        <CheckBox id={'1'} label="Scope 3" isSelected={value.offsetScopes[3]} onClick={onChangeScope(3)} />
      </OffsetScopes>

      <FieldWrapper>
        <InputText
          label="Quantity (tCO2e)*"
          suffix="tCO2"
          error={quantityError}
          value={value.quantity}
          type={InputTextType.Number}
          onChange={(e) => onChangeFormValue('quantity')(e.target.value)}
        />

        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
          Transaction Fee:{' '}
          {transactionFee.isLoading ? (
            <Loading size="small" />
          ) : (
            <Text variant={TypographyVariant.body2}>{moneyFormatter.format(transactionFee.total)}</Text>
          )}
        </Text>
      </FieldWrapper>
      <AssetsAllocationTable assets={assets} errors={errors} />
    </StyledRetireBatchForm>
  );
};

const StyledRetireBatchForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const MakePublicRetirement = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background-color: ${({ theme }) => toLayerBackground(theme)('field')};
  margin-bottom: 16px;
`;

const BeneficiaryAndOfsettingPeriod = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 50%;

  & > * {
    width: auto;
  }

  @media (max-width: 716px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

const OffsetScopes = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;

  & > * {
    flex-basis: 33.33%;
  }
`;

const StyledOffsettingPeriodButton = styled(Button)`
  width: 100%;

  & .children {
    justify-content: flex-start;
  }
`;
