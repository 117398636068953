import { useHistory } from 'react-router-dom';
import { Pagination } from 'refreshed-component/molecules/Pagination';
import type {
  ProjectsListFilteringOptions,
  ProjectsListFilters,
} from 'refreshed-pages/project-exchange/components/MyProjects/components/ProjectsList';
import { SearchAndFilter } from 'refreshed-pages/project-exchange/components/SearchAndFilter';

import { Card, CardVariant, Layer, styled, toSpacing } from '@aircarbon/ui';
import { AssetCategory } from '@aircarbon/utils-common';

import type { Project } from '../../utils/Project';
import { ProjectCard } from './components/ProjectCard';

export const ProjectsList: React.FC<{
  projects: Array<Project>;
  totalProjectsCount: number;
  currentPage: number;
  pageSize: number;
  filters: ProjectsListFilters;
  filteringOptions: ProjectsListFilteringOptions;
  search: string;
  areProjectsLoading: boolean;
  areFilteringOptionsLoading: boolean;
  className?: string;
  onSearch: (value: string) => void;
  onFilter: (filters: ProjectsListFilters) => void;
  onChangePagination: (currentPage: number, pageSize: number) => void;
}> = (props) => {
  const history = useHistory();
  const {
    areFilteringOptionsLoading,
    areProjectsLoading,
    totalProjectsCount,
    filteringOptions,
    currentPage,
    pageSize,
    projects,
    filters,
    search,
    onChangePagination,
    onSearch,
    onFilter,
  } = props;

  return (
    <Layer>
      <StyledProjectsListCard variant={CardVariant.Bordered}>
        <SearchAndFilter
          areFilteringOptionsLoading={areFilteringOptionsLoading}
          filteringOptions={filteringOptions}
          onSearch={onSearch}
          onChange={onFilter}
          filters={filters}
          search={search}
        />
        <Layer>
          {areProjectsLoading &&
            Array.from({ length: 4 }).map((_, index) => <ProjectCard.Loader key={`loader-${index}`} />)}
          {!areProjectsLoading &&
            projects.map((project) => (
              <ProjectCard
                onPressTrade={() => {
                  history.push(`/account/trade?assetCategoryId=${AssetCategory.fct}&projectId=${project.id}`);
                }}
                onPressDetails={() => {
                  history.push(`/account/apx/marketplace/${project.id}`);
                }}
                {...project}
                key={project.id}
              />
            ))}
        </Layer>
        <Pagination total={totalProjectsCount} page={currentPage} pageSize={pageSize} onChange={onChangePagination} />
      </StyledProjectsListCard>
    </Layer>
  );
};

const StyledProjectsListCard = styled(Card)`
  margin: 0 24px;
  padding: 24px;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
