export enum NotificationSubscriptionEventType {
  SpotTrade = 'SPOT_TRADE',
  Delivery = 'DELIVERY',
  Retirement = 'RETIREMENT',
  AssetDepositUpdated = 'ASSET_DEPOSIT_UPDATED',
  AssetDelivered = 'ASSET_DELIVERED',
  AssetRetired = 'ASSET_RETIRED',
  AssetTradedMarketBoard = 'ASSET_TRADED_MARKET_BOARD',
  AssetTradedSpot = 'ASSET_TRADED_SPOT',
  AssetTradeReversed = 'ASSET_TRADE_REVERSED',
  AssetTradeReplaced = 'ASSET_TRADE_REPLACED',
  AssetTradedSettle = 'ASSET_TRADED_SETTLE',
}

export enum NotificationSubscriptionDeliveryMethod {
  Webhook = 'WEBHOOK',
}

export enum EventNotificationStatus {
  New = 'NEW',
  Sent = 'SENT',
  Failed = 'FAILED',
}
