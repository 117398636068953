import { BorderColor, Icon, IconName, Text, TextColor, TypographyVariant, styled, toBorderColor } from '@aircarbon/ui';

type FileCardProps = {
  /**
   * File name
   *
   * @example "Uploaded file.pdf"
   */
  name: string;

  /**
   * File description
   *
   * @example "File Size: 20MB"
   */
  description: string;

  /**
   * Handles press on remove button
   */
  onRemove(): void;
};

export const FileCard: React.FC<FileCardProps> = (props) => {
  const { name, description, onRemove } = props;

  return (
    <StyledFileCard>
      <StyledFileIcon name={IconName.Document} color={TextColor.secondary} />
      <FileInfo>
        <Text>{name}</Text>
        <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
          {description}
        </Text>
      </FileInfo>
      <div onClick={onRemove}>
        <StyledRemoveIcon name={IconName.X} />
      </div>
    </StyledFileCard>
  );
};

const StyledFileCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
`;

const StyledFileIcon = styled(Icon).attrs({
  size: '2.625rem',
})`
  flex-shrink: 0;
`;

const StyledRemoveIcon = styled(Icon).attrs({
  name: IconName.X,
})`
  cursor: pointer;
  flex-shrink: 0;
`;

const FileInfo = styled.div`
  flex-grow: 1;
`;
