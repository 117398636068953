import { userAuthenticatedApi } from 'data-provider/fetcher';

import type { DepositRequestResource } from './DepositRequestResource';

export const fetchDepositRequest = async (props: { requestId: string }) => {
  const { requestId } = props;

  const endpoint = `/user/assets/deposits/${requestId}`;
  const userService = await userAuthenticatedApi();

  return userService.url(endpoint).get().json<{
    data: {
      data: DepositRequestResource;
    };
    status: string;
  }>();
};
