/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAllEntitiesResponse } from '../models/GetAllEntitiesResponse';
import type { GetBrandEntityResponse } from '../models/GetBrandEntityResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EntityService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns data.
     * @returns GetAllEntitiesResponse Successful operation
     * @throws ApiError
     */
    public getAllEntities({
        xEntityDomain,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
    }): CancelablePromise<GetAllEntitiesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/entity/entities',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Entity was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Returns data.
     * @returns GetBrandEntityResponse Successful operation
     * @throws ApiError
     */
    public getBrandEntity({
        xEntityDomain,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
    }): CancelablePromise<GetBrandEntityResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/entity/entity',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Entity was not found`,
                500: `Internal server error`,
            },
        });
    }
}
