import { useHistory } from 'react-router-dom';

import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Icon,
  IconName,
  Text,
  TextAlign,
  TextColor,
  TypographyVariant,
  styled,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

type Props = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonLink?: string;
  buttonCallback?: () => void;
};

const EmptyRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => toSpacing(theme)(16)};

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    max-width: 40rem;
    gap: ${({ theme }) => toSpacing(theme)(6)};
  }

  .icon {
    position: relative;
    width: 66px;
    height: 66px;
    background: ${({ theme }) => toLayerBackground(theme)('layerAccent')};
    border-radius: 66px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .button {
    margin-top: ${({ theme }) => toSpacing(theme)(6)};
  }
`;

export const Empty: React.FC<Props> = ({ title, description, buttonLabel, buttonLink, buttonCallback }) => {
  const history = useHistory();
  const onCallback = () => {
    if (buttonLink) {
      history.push(buttonLink || '/');
    } else if (buttonCallback) {
      buttonCallback();
    }
  };

  return (
    <EmptyRoot>
      <div className="wrapper">
        <div className="icon">
          <Icon size="2.813rem" name={IconName.Folder} color={TextColor.secondary} />
        </div>
        {title && (
          <Text variant={TypographyVariant.h6Title} align={TextAlign.center}>
            {title}
          </Text>
        )}
        {description && <Text align={TextAlign.center}>{description}</Text>}
        {buttonLabel && (
          <div className="button">
            <Button size={ButtonSize.s} variant={ButtonVariant.outlined} type={ButtonType.Button} onPress={onCallback}>
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </EmptyRoot>
  );
};
