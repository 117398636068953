import { Fragment } from 'react';

import { Text, TextColor, TypographyVariant, styled, toLayerBackground } from '@aircarbon/ui';

export const MilestonesTable: React.FC<{
  milestones: Array<[string, string, string]>;
}> = (props) => {
  const { milestones } = props;
  return (
    <StyledMilestonesTable>
      <Row variant={TypographyVariant.caption} color={TextColor.secondary}>
        Vintage Year
      </Row>
      <Row variant={TypographyVariant.caption} color={TextColor.secondary}>
        Projected Qty (tCo2)
      </Row>
      <Row variant={TypographyVariant.caption} color={TextColor.secondary}>
        Offered Qty (tCo2)
      </Row>
      {milestones.map((milestone, index) => (
        <Fragment key={index}>
          <Row color={TextColor.secondary}>{milestone[0]}</Row>
          <Row>{milestone[1]}</Row>
          <Row>{milestone[2]}</Row>
        </Fragment>
      ))}
    </StyledMilestonesTable>
  );
};

const StyledMilestonesTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  padding: 24px;
`;

const Row = styled(Text).attrs(({ variant = TypographyVariant.body2, color = TextColor.primary }) => ({
  variant,
  color,
}))`
  text-transform: uppercase;
  display: block;
  justify-self: stretch;
  text-align: center;
  &:nth-child(3n + 1),
  &:first-child {
    text-align: start;
  }
  &:nth-child(3n) {
    text-align: end;
  }
`;
