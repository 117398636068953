import 'bootstrap-daterangepicker/daterangepicker.css';
import { useState } from 'react';
import { DownloadCSV } from 'refreshed-component/molecules/DownloadCSV';
import {
  type FilterDateRangeInput,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import { contractParser, formatter } from '@aircarbon/utils-common';

import { getNonPaginatedUserTrades, useUserTrades } from 'pages/account/trading/hooks';

import { Account } from 'state/account';
import { Contract } from 'state/contract';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';

import { formatDate } from 'utils/helpers';

const { ethExplorerUrlFromTXID } = contractParser;
const { formatNumber } = formatter;

const TRADE_STATUS = {
  3: 'Reversed',
  4: 'Replaced',
};

export const Trades = () => {
  const { accountUsers } = Account.useContainer();
  const accountOptions = accountUsers?.map((userAccount, index: number) => ({
    label: `${userAccount.first_name} ${userAccount.last_name}`,
    id: userAccount.user_id,
  }));

  const { status } = User.useContainer();

  const { contractJson } = Contract.useContainer();
  const pagination = usePagination();

  const networkId = Number(contractJson?.networkId);
  const {
    selector: { getUserId, getUserRootId },
  } = User.useContainer();

  const filters: {
    account: FilterRadioBox;
    date: FilterDateRangeInput;
  } = {
    account: {
      type: 'radio-box',
      label: 'Accounts',
      list: accountOptions || [],
    },
    date: {
      type: 'date-range-input',
      label: 'Date range',
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});

  const userId = getUserId();
  const rootUserId = getUserRootId();

  const isMultiAccount = status.isCorporateMonitor() || status.isCorporateAdmin();

  const { currenciesById } = useCurrencies();
  const { trades, isLoading } = useUserTrades({
    page: pagination.page,
    limit: Number(pagination.pageSize),
    startDate: filterSelections?.date?.range?.startDate?.toISOString?.(),
    endDate: filterSelections?.date?.range?.endDate?.toISOString?.(),
    userId: filterSelections?.account?.selection?.toString() || (isMultiAccount ? rootUserId : userId),
  });

  const currenciesObjById = currenciesById();
  const getTradeId = ({ txStatus, txHash, txId }: { txStatus: string; txHash?: string; txId: number }) => {
    if (txHash) {
      return (
        <a
          href={`${
            ['error', 'failed'].includes(txStatus)
              ? ethExplorerUrlFromTXID(networkId, txHash)
              : `${window.location.origin}/explore/tx/${txHash}`
          }`}
          className="flex flex-row gap-1 justify-center items-center"
          target="_blank"
          rel="noreferrer"
        >
          {formatter.truncateMiddle(txHash, 16)}
          <svg width="12" height="12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 5L10 5C8.89543 5 8 5.89543 8 7L8 41C8 42.1046 8.89543 43 10 43L38 43C39.1046 43 40 42.1046 40 41L40 24.75"
              stroke="#b7bdc6"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M28 5H40V17" stroke="#b7bdc6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M21.0002 23.9998L39.0001 6"
              stroke="#b7bdc6"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      );
    }
    return <span className="flex flex-row gap-1 justify-center items-center">{txId}</span>;
  };

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageControls
            title="Trades"
            controls={{
              secondary: (
                <DownloadCSV
                  data={async () => {
                    const data = await getNonPaginatedUserTrades({
                      page: pagination.page,
                      limit: Number(pagination.pageSize),
                      startDate: filterSelections?.date?.range?.startDate?.toISOString?.(),
                      endDate: filterSelections?.date?.range?.endDate?.toISOString?.(),
                      userId:
                        filterSelections?.account?.selection?.toString() || (isMultiAccount ? rootUserId : userId),
                    });
                    return (
                      data?.trades?.map((item) => {
                        const transaction = item?.transaction;
                        let trader;
                        let side;
                        let fee = 0;
                        if (
                          [rootUserId, userId].includes(item?.sellerUserId) ||
                          [rootUserId, userId].includes(item?.sellerUser?.parentId)
                        ) {
                          trader = `${item?.sellerUser?.firstName} ${item?.sellerUser?.lastName}`;
                          side = 'Sell';
                        } else {
                          trader = `${item?.buyerUser?.firstName} ${item?.buyerUser?.lastName}`;
                          side = 'Buy';
                        }
                        if (item.tradeTypeId !== 1) {
                          fee = side === 'Sell' ? item?.sellerTradeFee : item?.buyerTradeFee;
                        } else {
                          if (
                            [rootUserId, userId].includes(item?.makerOrder?.user?.id) ||
                            [rootUserId, userId].includes(item?.makerOrder?.user?.parent?.id)
                          ) {
                            trader = `${item?.makerOrder?.user?.firstName} ${item?.makerOrder?.user?.lastName}`;
                          } else {
                            trader = `${item?.takerOrder?.user?.firstName} ${item?.takerOrder?.user?.lastName}`;
                          }
                          fee = side === 'Sell' ? item?.makerTradeFee : item.takerTradeFee;
                        }
                        const txStatus = [3, 4].includes(item.statusId)
                          ? TRADE_STATUS[item.statusId as 3 | 4]
                          : item.transactionId
                            ? item.transaction?.txStatus
                            : 'pending';
                        return {
                          Trader: trader,
                          TradeID: item.id,
                          'Trade Type': item?.tradeType,
                          Date: formatDate(item.createdAtUtc),
                          TXID: transaction?.txHash ? transaction?.txHash : transaction?.id,
                          Pair: item?.pair?.name,
                          Side: side,
                          Price: formatNumber(item.price, currenciesObjById?.[item?.pair?.quoteAssetId]?.numDecimals),
                          Qty: formatNumber(item.qty, 0),
                          Fee: fee,
                          Total: formatNumber(
                            item?.price * item?.qty + fee,
                            currenciesObjById?.[item?.pair?.quoteAssetId]?.numDecimals,
                          ),
                          Status: txStatus?.toUpperCase(),
                        };
                      }) ?? []
                    );
                  }}
                  fileName={'trades-report.csv'}
                />
              ),
            }}
          />
        </PageSections>
        <PageSections type="card" className="flex flex-col">
          <div className="flex flex-row justify-between items-center gap-base">
            <div className="flex flex-row gap-4 justify-start">
              {/*<Input
                placeholder="Search"
                config={{
                  size: 'base',
                  color: 'gray',
                  postfix: (
                    <HitArea
                      width={20}
                      height={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setSearch('');
                      }}
                    >
                      <Icon type={IconType.X} width={10} height={10} />
                    </HitArea>
                  ),
                  prefix: <Icon type={IconType.Search} width={14} height={14} />,
                }}
                value={search || ''}
                onChange={(event) => {
                  setSearch(event.target.value.trim() || '');
                }}
              />*/}
            </div>
            <div className="flex flex-row justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          {isLoading ? (
            <Loading isOverLay={true} />
          ) : (
            <Table
              config={{
                sticky: {
                  left: ['tradeId'],
                },
                columns: {
                  tradeId: {
                    label: 'TRADE ID',
                  },
                  trader: {
                    label: 'TRADER',
                  },
                  txId: {
                    label: 'TXID',
                  },
                  tradeType: {
                    label: 'TRADE TYPE',
                  },
                  date: {
                    label: 'DATE',
                  },
                  pair: {
                    label: 'PAIR',
                  },
                  carbonUnit: {
                    label: 'CARBON UNIT',
                  },
                  vintage: {
                    label: 'VINTAGE',
                  },
                  side: {
                    label: 'SIDE',
                  },
                  price: {
                    label: 'PRICE',
                  },
                  quantity: {
                    label: 'QUANTITY',
                  },
                  fee: {
                    label: 'FEE',
                  },
                  total: {
                    label: 'Total',
                  },
                  status: {
                    label: 'STATUS',
                  },
                },
                rows:
                  trades?.trades?.map((item) => {
                    const transaction = item?.transaction;
                    let trader;
                    let side;
                    let fee = 0;
                    if (
                      [rootUserId, userId].includes(item?.sellerUserId) ||
                      [rootUserId, userId].includes(item?.sellerUser?.parentId)
                    ) {
                      trader = `${item?.sellerUser?.firstName} ${item?.sellerUser?.lastName}`;
                      side = 'Sell';
                    } else {
                      trader = `${item?.buyerUser?.firstName} ${item?.buyerUser?.lastName}`;
                      side = 'Buy';
                    }
                    if (item.tradeTypeId !== 1) {
                      fee = side === 'Sell' ? item?.sellerTradeFee : item?.buyerTradeFee;
                    } else {
                      if (
                        [rootUserId, userId].includes(item?.makerOrder?.user?.id) ||
                        [rootUserId, userId].includes(item?.makerOrder?.user?.parent?.id)
                      ) {
                        trader = `${item?.makerOrder?.user?.firstName} ${item?.makerOrder?.user?.lastName}`;
                      } else {
                        trader = `${item?.takerOrder?.user?.firstName} ${item?.takerOrder?.user?.lastName}`;
                      }
                      fee = side === 'Sell' ? item?.makerTradeFee : item.takerTradeFee;
                    }
                    const txStatus = [3, 4].includes(item.statusId)
                      ? TRADE_STATUS[item.statusId as 3 | 4]
                      : item.transactionId
                        ? item.transaction?.txStatus
                        : 'pending';
                    return {
                      _key: String(item.id),
                      tradeId: item.id,
                      trader: trader,
                      txId: transaction
                        ? getTradeId({
                            txId: transaction?.id,
                            txStatus: transaction.txStatus,
                            txHash: transaction?.txHash,
                          })
                        : '-',
                      tradeType: item?.tradeType || '-',
                      date: formatDate(item.createdAtUtc),
                      pair: item?.pair?.name || '-',
                      carbonUnit: item.uom,
                      vintage: item.vintages.join(',') || '-',
                      side:
                        side.toLocaleLowerCase() === 'buy' ? (
                          <Text color={TextColor.success} variant={TypographyVariant.body2}>
                            Buy
                          </Text>
                        ) : (
                          <Text color={TextColor.error} variant={TypographyVariant.body2}>
                            Sell
                          </Text>
                        ),
                      price: formatNumber(item.price, currenciesObjById?.[item?.pair?.quoteAssetId]?.numDecimals),
                      quantity: formatNumber(item.qty, 0),
                      fee,
                      total: formatNumber(
                        item?.price * item?.qty + fee,
                        currenciesObjById?.[item?.pair?.quoteAssetId]?.numDecimals,
                      ),
                      status: <Text variant={TypographyVariant.body2}>{txStatus?.toUpperCase()}</Text>,
                    };
                  }) || [],
              }}
            />
          )}
          <Pagination total={trades?.total ?? 0} actions={pagination} />
        </PageSections>
      </PageHolder>
    </>
  );
};
