import { Text, TypographyVariant, styled } from '@aircarbon/ui';

export const SectionHeader: React.FC<{
  suffix?: React.ReactNode;
}> = ({ children, suffix }) => {
  return (
    <StyledSectionHeader>
      <Text variant={TypographyVariant.subtitle1}>{children}</Text>
      {!!suffix && <Suffix>{suffix}</Suffix>}
    </StyledSectionHeader>
  );
};

const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1367px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const Suffix = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
