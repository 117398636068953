import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import styled from 'styled-components';

import { Button, ButtonVariant, IconName } from '@aircarbon/ui';

import { RetireBatchForm } from '../RetireBatchForm';
import { useRetireGER } from './hooks/useRetireGER';

export const RetireBatch: React.FC<{
  isVisible: boolean;
  batchId: number;
  maxRetireQuantity: number;
  onClose(): void;
}> = (props) => {
  const { isVisible, batchId, maxRetireQuantity, onClose } = props;
  const {
    formValues,
    isRetireDisabled,
    isRetireInProgress,
    validationErrorsMap,
    assets,
    transactionFee,
    changeFormValues,
    retire,
    resetUserInput,
  } = useRetireGER({
    batchId,
    maxRetireQuantity,
    onRetired() {
      onClose();
    },
  });

  const onPressConfirm = () => {
    retire();
  };

  const onCloseModal = () => {
    if (isRetireInProgress) {
      return;
    }

    resetUserInput();
    onClose();
  };

  return (
    <Modal isOpen={isVisible} onClose={onCloseModal} title="Request Retirement">
      {() => {
        return (
          <>
            <ModalContent>
              <RetireBatchForm
                batchInformation={{
                  batchId,
                  quantity: maxRetireQuantity,
                }}
                assets={assets}
                value={formValues}
                errors={validationErrorsMap}
                transactionFee={transactionFee}
                onChange={changeFormValues}
              />
            </ModalContent>
            <ModalFooter>
              <StyledButton
                endIcon={IconName.ArrowEnd}
                variant={ButtonVariant.secondary}
                isLoading={isRetireInProgress}
                isDisabled={isRetireDisabled}
                onPress={onPressConfirm}
              >
                Confirm
              </StyledButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;
