import { useEffect } from 'react';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { useProjectsListFilteringOptions } from 'refreshed-pages/project-exchange/hooks/useProjectsListFilteringOptions';
import { useSearchResultsPage } from 'refreshed-pages/project-exchange/hooks/useSearchResultsPage';

import type { ApxProject } from '@aircarbon/utils-common/src/dto';

import { toFiltersFromQueryParams } from '../../utils/toFiltersFromQueryParams';
import { toQueryParamsFromFilters } from '../../utils/toQueryParamsFromFilters';
import type { ProjectsListFilters } from '../MyProjects/components/ProjectsList';
import { ProjectsList } from './components/ProjectsList';
import type { Project } from './utils/Project';
import { toMarketplaceApiFilters } from './utils/toMarketplaceApiParams';
import { toResults } from './utils/toResults';

export const Marketplace = () => {
  const {
    filteringOptions,
    isFetching: isFetchingFilteringOptions,
    fetchFilteringOptions,
  } = useProjectsListFilteringOptions();

  useEffect(() => {
    fetchFilteringOptions();
  }, [fetchFilteringOptions]);

  const {
    totalResultsCount: totalProjectsCount,
    isFetching: isFetchingProjects,
    results: projects,
    searchValue,
    currentPage,
    pageSize,
    filters,
    changePagination,
    search,
    filter,
  } = useSearchResultsPage<ProjectsListFilters, ApxProject, Project>({
    // TODO: Migrate to properly fetcher with api client
    searchEndpoint: '/user/apx/projects',
    urlPath: '/account/apx/marketplace',
    toQueryParams: toQueryParamsFromFilters,
    toApiFilters: toMarketplaceApiFilters,
    toFilters: toFiltersFromQueryParams,
    toResults,
  });

  const onFilter = (filters: ProjectsListFilters) => {
    filter(filters);
  };

  const onSearch = (searchValue: string) => {
    search(searchValue);
  };

  const onChangePagination = (currentPage: number, pageSize: number) => {
    changePagination(currentPage, pageSize);
  };

  return (
    <>
      <PageHeader title={'Marketplace'} margin={'24px'} />
      <ProjectsList
        filteringOptions={filteringOptions}
        search={searchValue}
        filters={filters}
        onFilter={onFilter}
        projects={projects}
        currentPage={currentPage}
        pageSize={pageSize}
        totalProjectsCount={totalProjectsCount}
        areProjectsLoading={isFetchingProjects}
        areFilteringOptionsLoading={isFetchingFilteringOptions}
        onSearch={onSearch}
        onChangePagination={onChangePagination}
      />
    </>
  );
};
