import {
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  IconName,
  InputPhoneNumber,
  InputText,
  InputTextType,
  Knob,
  Select,
  Text,
  TypographyVariant,
  styled,
  toBorderColor,
  useSpacing,
} from '@aircarbon/ui';

import { FieldWrapper } from 'components/styled/Styled';

interface ProponentValue {
  id?: number;
  companyName: string;
  companyInvolvement: string;
  contactPersonName: string;
  contactPersonRole: string;
  email: string;
  phone: {
    value: string;
    phoneCode: string;
  };
}

const Proponent: React.FC<{
  id: number;
  label?: string;
  value: ProponentValue;
  companyInvolvements: { title: string; value: string }[];
  contactPersonRoles: { title: string; value: string }[];
  error?: Record<string, string>;
  onChange(value: ProponentValue): void;
  onRemove?(): void;
}> = (props) => {
  const { id, label, value, error, companyInvolvements, contactPersonRoles, onChange, onRemove } = props;

  const onChangeFormValue =
    <K extends keyof typeof value>(key: K) =>
    (fieldValue: (typeof value)[K]) => {
      const newValue = {
        ...value,
        [key]: fieldValue,
      };
      onChange(newValue);
    };

  return (
    <>
      {(!!label || !!onRemove) && (
        <ProponentHeader>
          {!!label && <Text variant={TypographyVariant.subtitle1}>{label}</Text>}
          {!!onRemove && (
            <Knob size={ButtonSize.s} onPress={onRemove} variant={ButtonVariant.outlined} icon={IconName.Trash} />
          )}
        </ProponentHeader>
      )}
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <InputText
              error={error?.[`proponents[${id}].companyName`]}
              label="Company Name*"
              value={value.companyName}
              onChange={(e) => onChangeFormValue('companyName')(e.target.value)}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Select
              label="Company Involvement*"
              placeholder="Select"
              items={companyInvolvements}
              value={value.companyInvolvement}
              error={error?.[`proponents[${id}].companyInvolvement`]}
              onChange={({ value }) => onChangeFormValue('companyInvolvement')(value)}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <InputText
              label="Contact Person's Name*"
              value={value.contactPersonName}
              error={error?.[`proponents[${id}].contactPersonName`]}
              onChange={(e) => onChangeFormValue('contactPersonName')(e.target.value)}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Select
              label="Contact Person's Role*"
              value={value.contactPersonRole}
              placeholder="Select"
              items={contactPersonRoles}
              error={error?.[`proponents[${id}].contactPersonRole`]}
              onChange={({ value }) => onChangeFormValue('contactPersonRole')(value)}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <InputText
              label="Email Address*"
              error={error?.[`proponents[${id}].email`]}
              type={InputTextType.Email}
              value={value.email}
              onChange={(e) => onChangeFormValue('email')(e.target.value)}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <InputPhoneNumber
              label="Phone Number*"
              value={value.phone.value}
              phoneCode={value.phone.phoneCode}
              onChange={onChangeFormValue('phone')}
              error={error?.[`proponents[${id}].phone.value`]}
            />
          </FieldWrapper>
        </div>
      </div>
    </>
  );
};

export interface ProponentsValue {
  proponents: Array<ProponentValue>;
}

export interface ProponentsMetadata {
  companyInvolvements: { title: string; value: string }[];
  contactPersonRoles: { title: string; value: string }[];
}

export const Proponents: React.FC<{
  value: ProponentsValue;
  metadata: ProponentsMetadata;
  error?: Record<string, string>;
  onChange(value: ProponentsValue): void;
}> = ({ value, error, metadata, onChange }) => {
  const { spacing } = useSpacing();
  const onChangeProponent = (index: number) => (newProponentValue: ProponentValue) => {
    const newValue = [...value.proponents];
    newValue[index] = newProponentValue;

    onChange({
      proponents: newValue,
    });
  };

  const onRemoveProponent = (index: number) => {
    const newValue = [...value.proponents];
    newValue.splice(index, 1);

    onChange({
      proponents: newValue,
    });
  };

  const onPressAddProponent = () => {
    const newValue = [
      ...value.proponents,
      {
        companyName: '',
        companyInvolvement: '',
        contactPersonName: '',
        contactPersonRole: '',
        email: '',
        phone: {
          phoneCode: '+971',
          value: '',
        },
      },
    ];
    onChange({
      proponents: newValue,
    });
  };

  return (
    <>
      {value.proponents.map((item, index) => (
        <Proponent
          id={index}
          key={index}
          value={item}
          error={error}
          companyInvolvements={metadata.companyInvolvements}
          contactPersonRoles={metadata.contactPersonRoles}
          onChange={onChangeProponent(index)}
          label={index !== 0 ? `Proponent ${index + 1}` : undefined}
          onRemove={index !== 0 ? () => onRemoveProponent(index) : undefined}
        />
      ))}
      <Button
        marginTop={spacing(8)}
        variant={ButtonVariant.outlined}
        startIcon={IconName.PlusCircle}
        onPress={onPressAddProponent}
      >
        Add Proponent
      </Button>
    </>
  );
};

const ProponentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  margin-top: 24px;
  margin-bottom: 24px;
`;
