import { type PropsWithChildren, useState } from 'react';
import { toast } from 'refreshed-component/molecules/toast';

import { Button, IconName } from '@aircarbon/ui';

export const downloadCSVFile = async (fileName: string, data: Array<Record<string, any>>) => {
  if (data.length > 0) {
    await fetch(`/api/csv?name=${fileName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.blob())
      .then((blob) => onDownload(blob, fileName))
      .catch((error) => toast.error(error.messages));
  } else {
    toast.info('No data for downloading.');
  }
};

interface Props {
  data: Array<Record<string, any>> | (() => Promise<Array<Record<string, any>>>);
  fileName: string;
}

const onDownload = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFile = (url: string, authToken: string, fileName: string) =>
  // TODO: Implement data-mutation
  fetch(url, {
    method: 'POST',
    headers: { authorization: `Bearer ${authToken}` },
  })
    .then((resp) => resp.blob())
    .then((blob) => onDownload(blob, fileName))
    .catch((error) => toast.error(error.messages));

export const DownloadCSV: React.FunctionComponent<PropsWithChildren<Props>> = ({ data, fileName, children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      onPress={async () => {
        if (isLoading) return;
        setIsLoading(true);
        await downloadCSVFile(fileName, typeof data === 'function' ? await data() : data);
        setIsLoading(false);
      }}
      endIcon={IconName.Download}
      isLoading={isLoading}
    >
      {children || 'Export CSV'}
    </Button>
  );
};
