import { useCallback, useEffect, useState } from 'react';
import { globalEventsEmitter } from 'refreshed-pages/market-board-v2/utils/globalEventsEmitter';

import { Layer, styled, toSpacing } from '@aircarbon/ui';

import { BottomSlideUp } from '../BottomSlideUp';
import { BottomTabsProvider } from '../BottomTabsProvider';
import { useBottomTabs } from '../BottomTabsProvider/hooks/useBottomTabs';
import { Filters } from '../Filters';
import { FiltersProvider } from '../FiltersProvider';
import { Listings, type ListingsProps } from '../Listings';
import { OnboardingAlert } from './components/OnboardingAlert';

export const MarketBoardV2ListingsPage: React.FunctionComponent<{}> = () => {
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const onPressFilterButton = useCallback(() => {
    setIsFiltersVisible((isPrevVisible) => !isPrevVisible);
  }, []);

  return (
    <FiltersProvider>
      <BottomTabsProvider>
        <PageContainer>
          <OnboardingAlert />
          <ContentContainer>
            <Layer>
              {!!isFiltersVisible && <Filters />}

              <ListingsContainer isFiltersVisible={isFiltersVisible} onPressFilter={onPressFilterButton} />
            </Layer>
          </ContentContainer>
          <Layer>
            <BottomSlideUp />
          </Layer>
        </PageContainer>
      </BottomTabsProvider>
    </FiltersProvider>
  );
};

const ListingsContainer: React.FunctionComponent<Omit<ListingsProps, 'onPressShowOpenRequests'>> = ({
  isFiltersVisible,
  onPressFilter,
}) => {
  const { updateState } = useBottomTabs();

  useEffect(() => {
    const unsubscribeFromGlobalEvents = globalEventsEmitter.on('userPressedOnMyListingsLinkInToast', () => {
      updateState(() => ({ activeTabId: 'my-orders', isOpened: true }));
    });

    return unsubscribeFromGlobalEvents;
  }, [updateState]);

  const onPressShowOpenRequests = useCallback(() => {
    updateState(() => ({ activeTabId: 'my-requests', isOpened: true }));
  }, [updateState]);

  return (
    <Listings
      isFiltersVisible={isFiltersVisible}
      onPressFilter={onPressFilter}
      onPressShowOpenRequests={onPressShowOpenRequests}
    />
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(6)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
  margin-bottom: ${({ theme }) => toSpacing(theme)(22)};
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 1;
  overflow-y: auto;

  @media (max-width: 1258px) {
    flex-direction: column;
  }
`;
