import { ProjectCardLayout } from 'refreshed-pages/project-exchange/components/ProjectCardLayout';
import { SDGGoals } from 'refreshed-pages/project-exchange/components/SDGGoals';
import { ProjectStatus } from 'refreshed-pages/project-exchange/utils/ProjectStatus';

import {
  Badge,
  BadgeVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  IconName,
  Text,
  TextColor,
  TypographyVariant,
  styled,
} from '@aircarbon/ui';

import { ProjectCardLoader } from './components/Loader';

type ProjectCardProps = {
  title: string;
  status: ProjectStatus;
  date: string;
  dateLabel: string;
  vintage?: string;
  country?: string;
  sectoralScope?: string;
  sdgGoals: Array<{ label: string; value: string }>;
  rejectionText?: string;
  onPressEdit?(): void;
  onPressView?(): void;
  onPressMarketplace?(): void;
};

export const ProjectCard: React.FC<ProjectCardProps> & {
  Loader: typeof ProjectCardLoader;
} = (props) => {
  const {
    title,
    country,
    vintage,
    sectoralScope,
    sdgGoals,
    status,
    dateLabel,
    date,
    onPressEdit,
    rejectionText = 'Our team will reach out to you soon...',
    onPressView,
    onPressMarketplace,
  } = props;

  const badgeColor = toBadgeVariant(status);

  return (
    <ProjectCardLayout
      title={title}
      information={[
        {
          label: 'Vintage',
          value: vintage,
        },
        {
          label: 'Country',
          value: country,
        },
        {
          label: 'Sectoral Scope',
          value: sectoralScope,
        },
      ]}
      footer={sdgGoals.length ? <SDGGoals goals={sdgGoals} /> : undefined}
      endSection={
        <StyledEndSection>
          <StatusAndDate>
            <Badge variant={badgeColor} icon={toBadgeIconType(status)} value={toStatusText(status)} />
            <Text color={TextColor.secondary}>
              {dateLabel}: <StyledDateText>{date}</StyledDateText>
            </Text>
          </StatusAndDate>
          {status === ProjectStatus.Rejected && (
            <Text variant={TypographyVariant.subtitle2} color={TextColor.error}>
              {rejectionText}
            </Text>
          )}
          {status === ProjectStatus.Draft && !!onPressEdit && (
            <Button
              onPress={onPressEdit}
              variant={ButtonVariant.secondary}
              endIcon={IconName.Pencil}
              size={ButtonSize.l}
            >
              Continue Editing
            </Button>
          )}

          {status === ProjectStatus.Submitted && !!onPressView && (
            <Button onPress={onPressView} variant={ButtonVariant.outlined} size={ButtonSize.l} endIcon={IconName.Eye}>
              View
            </Button>
          )}

          {status === ProjectStatus.Active && (
            <ActionsContainer>
              <Button onPress={onPressView} variant={ButtonVariant.outlined} size={ButtonSize.l} endIcon={IconName.Eye}>
                View
              </Button>
              <Button onPress={onPressMarketplace} variant={ButtonVariant.secondary} size={ButtonSize.l}>
                Marketplace
              </Button>
            </ActionsContainer>
          )}
        </StyledEndSection>
      }
    />
  );
};

ProjectCard.Loader = ProjectCardLoader;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const StyledEndSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex-grow: 1;
  justify-content: space-between;
`;

const StatusAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const StyledDateText = styled(Text)`
  display: inline-flex;
`;

const statusesTextMap = {
  [ProjectStatus.Draft]: 'Draft',
  [ProjectStatus.Rejected]: 'Rejected',
  [ProjectStatus.Submitted]: 'Submitted',
  [ProjectStatus.Active]: 'Active',
};

const statusesIconMap = {
  [ProjectStatus.Draft]: IconName.Document,
  [ProjectStatus.Rejected]: IconName.XCircle,
  [ProjectStatus.Submitted]: IconName.Clock,
  [ProjectStatus.Active]: IconName.CheckCircle,
};

const statusBadgeVariantMap = {
  [ProjectStatus.Draft]: BadgeVariant.Default,
  [ProjectStatus.Rejected]: BadgeVariant.Danger,
  [ProjectStatus.Submitted]: BadgeVariant.Alert,
  [ProjectStatus.Active]: BadgeVariant.Success,
};

const toStatusText = (status: ProjectStatus) => statusesTextMap[status];

const toBadgeIconType = (status: ProjectStatus) => statusesIconMap[status];

const toBadgeVariant = (status: ProjectStatus) => statusBadgeVariantMap[status];
